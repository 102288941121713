import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useEffect, useState } from "react";
import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';
import { Dashboard as DashboardType } from '../../types/types';
import { SignalList } from '../../components/SignalList';
import { SignalStream } from '../../components/SignalStream';
import { SignalTicker } from '../../components/SignalTicker';
import { InfoCarousel } from '../../components/InfoCarousel'
import { SignalSelectableBrowser } from '../../components/SignalSelectableBrowser'
import { Dashboard } from '../../components/Dashboard'
import { getDashboards } from '../../services/signalApi';


export function Home() {

  const account = useAppSelector(selectAccount);
  // const [loading, setLoading] = useState<boolean>(true);
  const [homeDashboard, setHomeDashboard] = useState<DashboardType | null>(null);

  useEffect(() => {document.title = 'FreqSignals | Home'}, [])

  // useEffect(() => {
  //   getDashboards({
  //       ordering: 'name',
  //       // is_public: 1,
  //       limit: 1
  //   }).then((res) => {
  //       console.log("DashboardList Dashboards", {res})
  //       if (res.status === 200 && res.data.count && res.data.results) {
  //         setHomeDashboard(res.data.results[0])
  //       }
  //   }).finally(() => {
  //     setLoading(false)
  //   })

  // }, [account?.id])

  // console.log("Home", {loading, homeDashboard})

  const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
  const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
  const blocked = <Tooltip title="Awaiting Data"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
  const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />
  const up = <UpCircleOutlined  style={{ color: '#00ff00' }} />
  const down = <DownCircleOutlined  style={{ color: '#ff0000' }} />
  console.log("account", {account})

  return (
    <div className="App">
      <div className="App-content">
        {/* <TradingViewTickerTape /> */}
        {/* {tickerCarousel()} */}

        {/* <DashboardComponent id={'1'} show_name={false} /> */}

        {/* <h2>Home</h2> */}
        <div className="App-page-content">
            {/* <SignalTicker showButtons={false} /> */}
            {/* {!account && ( */}
            <InfoCarousel />
            {/* )} */}
            {/* <InfoCarousel /> */}
            {/* <p>
                Organize your signals to make informed decisions. And send that to your inbox, your community or automated trading bot. <a>Learn more <ArrowRightOutlined /></a>
            </p> */}
            {/* <p>
                Signals sent to your inbox, your community or automated trading bot. <Link to={`/integration_offerings`}>Learn more <ArrowRightOutlined /></Link>
            </p> */}
            {/* <SignalList /> */}
            {/* {loading && (
              <>Loading...</>
            )}
            {!loading && homeDashboard === null && (
              <SignalSelectableBrowser />
            )}
            {!loading && homeDashboard !== null && (
              <Dashboard id={homeDashboard.id} can_edit={false} />
            )} */}
            <SignalSelectableBrowser />
            {/* <TradingViewEconomicCalendar /> */}
            {/* <TradingViewTicker /> */}
            {/* <TradingViewSingleTicker /> */}
            {/* <TradingViewForexHeatMap /> */}
            {/* <p>{waiting} Subscribe to a datasource.</p> */}
            {/* <DashboardComponent id={'1'} show_name={false} /> */}
            {/* <DataSetList/> */}
        </div>
      </div>
    </div>
  );
}
