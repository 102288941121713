import React, { useState } from 'react';
import { Form, Modal, Checkbox, Input, Typography, Button, Select, Alert } from 'antd';
import { createDataProvider, createDataSet, getDataSets, createSignal } from '../services/signalApi'
import { selectAccount } from '../features/login/loginSlice'
import { DataProvider, DataSet } from '../types/types';

import { DiscordLink } from './DiscordLink'
import { useAppSelector, useAppDispatch } from '../app/hooks';
const { Option, OptGroup } = Select;


interface CreateSignalModalParams {
    isOpen: boolean
    dataset: DataSet
    onCancel: () => void
    onCreate: () => void
}


export function CreateSignalModal({
    isOpen,
    dataset,
    onCancel,
    onCreate,
}: CreateSignalModalParams) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isCreating, setIsCreating] = useState(false);
    const [submissionError, setSubmissionError] = useState<string | null>(null);


    console.log('CreateSignalModal', {dataset, isCreating})

    const handleCancel = () => {
        form.resetFields()
        setIsCreating(false)
        onCancel()
    }

    const onCreateSignalFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateSignalSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateSignal(values)
    };

    const onCreateSignal = (values: any) => {
        if (account) {
            console.log("onCreateSignal values", {values})
            setIsCreating(true)
            setSubmissionError(null)
            createSignal(dataset.id, values.symbol, values.value, values.ttl_minutes).then((res) => {
                console.log("created", {res})
                if (res.status === 201 ) {
                    const response_data = res.data
                    setIsCreating(false)

                } else if (res.status === 400) {
                    const response_data = res.data
                    setSubmissionError(response_data)
                } else {
                    console.log("failed", {res})
                }
                // const data_set_id = response_data.id
                // const data_set_name = response_data.name
                // setNewDataSet({id: data_set_id, name: data_set_name})
            }
            ).catch((res) => {
                console.log("failed catch", {res})

            }).finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }


    const signalFormBody = (
        <Form
        name="basic"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={onCreateSignalSubmit}
        onFinishFailed={onCreateSignalFailed}
        autoComplete="off"
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>
                Creation of a Signal. In practice, this is probably done programatically, but it can be done here as well for manually uploaded signals.
            </Typography.Paragraph>

                    Data Set: {dataset.name} ({dataset.id})
                    <Form.Item
                        label="Symbol"
                        name="symbol"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Value"
                        name="value"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="TTL Minutes"
                        name="ttl_minutes"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Create Signal
                        </Button>
                    </Form.Item>

                    {submissionError && (
                        <Alert message={submissionError} type="error" />
                    )}

        </Form>
    )

    return (
            <Modal title="Create Signal" open={isOpen} footer={null} onCancel={handleCancel}>
                {signalFormBody}
            </Modal>
    )

    
}
