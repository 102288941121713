import { Spin, Alert } from 'antd';




interface LoadingParams {
    message?: string
    tip?: string
    description?: string
}

export function Loading({
    message = "Loading... ",
    tip = 'Loading...',
    description = "Loading Data",
}: LoadingParams){
    
    return  (
        <Spin tip={tip}>
            <Alert
              message={message}
              description={description}
              type="info"
            />
          </Spin>
    )
}
