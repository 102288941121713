import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Form, FormListFieldData, Input, InputNumber, message, Select, Space, Tabs, Tooltip, Typography } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';

import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import { editWorkflow, getIntegrations, getWorkflow } from '../../services/signalApi';
import { DataSet, Integration, Workflow as WorkflowType, WorkflowConfigActionTypes } from '../../types/types';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import { PageHeader } from '../../components/PageHeader';
import { SignalList } from '../../components/SignalList';
import { selectAccount, selectIsWorkflowDiscordUsernamePermitted } from '../../features/login/loginSlice';
import {
    clear, loadDataSetsAsync, selectDataSets,
    selectStatus
} from '../../features/dataSetList/dataSetSlice';
// import { WorkflowSubscribeModal } from '../../components/WorkflowSubscribeModal'
import { WorkflowExecutionsList } from '../../components/WorkflowExecutionsList';
// import { WorkflowEditModal } from '../../components/WorkflowEditModal'
import 'draft-js/dist/Draft.css';
// import Editor from "rich-markdown-editor";

// function MyEditor() {
//   const [editorState, setEditorState] = React.useState(
//     () => EditorState.createEmpty(),
//   );

//   return (
//     <>
//       Editor:
//       <Button onClick={() => {}}>Bold</Button>
//       <Editor editorState={editorState} onChange={setEditorState} />
//     </>
//   )
// }

const localUpdateWorkflowConfig = (workflow: WorkflowType, isWorkflowDiscordUsernamePermitted: boolean): WorkflowType => {
    if (!isWorkflowDiscordUsernamePermitted) {
        workflow.config.actions = workflow.config.actions.map((action) => {
            if (action.action_type === 'discord_webhook_send_message') {
                if (action.args.username) {
                    action.args.username = undefined
                }
            }
            return action
        })
    }
    return workflow
}


export function Workflow() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    const isWorkflowDiscordUsernamePermitted = useAppSelector(selectIsWorkflowDiscordUsernamePermitted)
    const [form] = Form.useForm();
    const formConditions = Form.useWatch('conditions', form);
    const formActions = Form.useWatch('actions', form);
    const formThrottle = Form.useWatch('throttle', form);

    // const [inFlight, setInFlight] = useState(false);
    const [attemptedLoad, setAttemptedLoad] = useState(false);
    const [showCreateSignalModal, setShowCreateSignalModal] = useState(false);
    const [showEditWorkflowModal, setShowEditWorkflowModal] = useState(false);
    const [workflow, setWorkflow] = useState<WorkflowType | null>(null);
    const [workflowDirtyState, setWorkflowDirtyState] = useState<WorkflowType | null>(null);
    const [reloads, setReloads] = useState(0);
    const [subscribingWorkflow, setSubscribingWorkflow] = useState<WorkflowType | null>(null);
    // const [dataSets, setDataSets] = useState<DataSet[] | null>(null);
    const dataSets = useAppSelector(selectDataSets);
    const dispatch = useAppDispatch();

    const [integrations, setIntegrations] = useState<Integration[] | null>(null);
    useEffect(() => {
        if (workflow === null) {
        document.title = 'FreqSignals | Workflow'

        } else {
        document.title = `FreqSignals | Workflow: ${workflow.name}`
        }
    }, [workflow?.id])

    const id = params.id;

    useEffect(() => {
        if (!id) {
            return
        }
        getWorkflow(id).then((res) => {
            // setInFlight(false)
            // setAttemptedLoad(true)
            setAttemptedLoad(true)
            console.log("workflow", {res})
            if (res.status === 200) {
                const response_data = res.data
                
                setWorkflow(localUpdateWorkflowConfig(response_data, isWorkflowDiscordUsernamePermitted))
            } else {
                setWorkflow(null)

            }
        }).catch(() => {
            setWorkflow(null)
        })
        
    }, [id, reloads, account?.id, isWorkflowDiscordUsernamePermitted])

    useEffect(() => {
        if (!id) {
            return
        }
        if (dataSets === null) {
            dispatch(loadDataSetsAsync())
        }
        getIntegrations().then((res) => {
            if (res.status === 200) {
                const response_data = res.data
                setIntegrations(response_data["results"])
            } else {
                setIntegrations(null)

            }
        }
        ).catch(() => {
            setIntegrations(null)
        })
        
    }, [id, account?.id])

    if (!id) {
        return <Typography.Paragraph>No ID</Typography.Paragraph>
    }

    if (id === undefined || workflow === undefined || workflow === null) {
        return (
        <div className="App">
            <div className="App-content">
            {attemptedLoad && (
                <Typography.Paragraph>Workflow Not Found. Maybe you need to log in.</Typography.Paragraph>
            )}
            {!attemptedLoad && (
                <Loading message='Workflow' description='Loading Workflow' />

            )}
            </div>
        </div>
        );
    }

    console.log("workflow", {workflow})

    const onWorkflowConfigSave = (values: any) => {
        console.log('Received values of form:', values);
        editWorkflow(workflow.id, {config: values}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Saved!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

    const onEnable = () => {
        editWorkflow(workflow.id, {enabled: 1}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Enabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error(`Failure: ${res.data}`)
            }
        })
    };

    const onDisable = () => {
        editWorkflow(workflow.id, {enabled: 0}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Disabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };


    // const handleChange = () => {
    //     form.setFieldsValue({ sights: [] });
    // };

    const logit = (foo: any) => {
        console.log(foo)
        console.log(formActions)
        return true
    }

    const workflowIntegrationField = (action: FormListFieldData, type_filter: string) => {
        return (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                }
            >
            {() => (
                <Form.Item
                {...action}
                label="Integration"
                name={[action.name, 'args', 'integration_id']}
                rules={[{ required: true, message: 'Missing integration' }]}
                extra={<Link to={`/integrations/`}>Manage Integrations</Link>}
                >
                <Select>
                    {integrations?.filter((integration) => integration.type === type_filter).map((integration) => (
                        <Select.Option key={integration.id} value={integration.id}>
                            {integration.name}
                        </Select.Option>
                    ))}
                </Select>
                </Form.Item>
            )}
            </Form.Item>
        )
    }

    const workflowActionFormDiscord = (action: FormListFieldData) => {
        return (
            <div>
                {workflowIntegrationField(action, 'discord_webhook')}
                <Form.Item
                    {...action}
                    key={`message-${action.key}}`}
                    label="Message"
                    name={[action.name, 'args', 'message']}
                    rules={[{ required: true, message: 'Missing message' }]}
                    // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.[field]}'}</>}
                    extra={<>The message to send. Special replacements are: {'{symbol}'} and {'{value}'}.</>}
                >
                <Input />
                </Form.Item>
                <Form.Item
                    {...action}
                    key={`username-${action.key}}`}
                    label="Username"
                    name={[action.name, 'args', 'username']}
                    // rules={[{ required: true, message: 'Missing message' }]}
                    extra={isWorkflowDiscordUsernamePermitted ? <>Username to send as.</> : <>Username to send as. You do not have this feature to specify a Discord Username in a Workflow.</>}
                >
                    <Input disabled={!isWorkflowDiscordUsernamePermitted} placeholder='FreqSignals' />
                    
                </Form.Item>
            </div>
        )
    }

    const workflowActionFormTelegram = (action: FormListFieldData) => {
        return (
            <div>
                {workflowIntegrationField(action, 'telegram_token')}
                <Form.Item
                    {...action}
                    key={`message-${action.key}}`}
                    label="Message"
                    name={[action.name, 'args', 'message']}
                    rules={[{ required: true, message: 'Missing message' }]}
                    // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
                    extra={<>The message to send. Special replacements are: {'{symbol}'} and {'{value}'}.</>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    {...action}
                    key={`chat_id-${action.key}}`}
                    label="Chat ID"
                    name={[action.name, 'args', 'chat_id']}
                    rules={[{ required: true, message: 'Missing Chat ID' }]}
                    extra={<>Telegram Chat ID of where to send this</>}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const getActionFormForActionType = (action: FormListFieldData, action_type: WorkflowConfigActionTypes) => {
        if (action_type === undefined || action_type === null) {
            return <></>
        }

        if (action_type === "discord_webhook_send_message"){
            return workflowActionFormDiscord(action)
        }

        if (action_type === "telegram_send_message"){
            return workflowActionFormTelegram(action)
        }

        return <></>
    }

    const clearActionArgs = (action: FormListFieldData) => {
        console.log("clear", {action})
        form.setFieldValue(["actions", action.name, "args"], {})

    }

    const showSelectedDataSet = (condition: FormListFieldData) => {
        const data_set_id = formConditions[condition.name].args.data_set_id
        if (data_set_id === undefined || data_set_id === null) {
            return <></>
        }
        return (
            <Collapse defaultActiveKey={['1']} ghost>
                <Collapse.Panel header="See Data Set" key="1">
                    <SignalList showButtons={true} data_set_id={data_set_id} addContextColumns={true} key={data_set_id}/>

                </Collapse.Panel>
            </Collapse>            
        )
    }

    const workflowConfiguration = () => {
        const config = workflowDirtyState?.config || workflow?.config

        return (
            <>
                <Typography.Paragraph>When the specified condition is matched, it will trigger the action you configure.</Typography.Paragraph>

                <Form
                    form={form}
                    name="workflow_configuration"
                    onFinish={onWorkflowConfigSave}
                    autoComplete="off"
                    // style={{width: "500px"}}
                    initialValues={workflow.config && workflow.config.conditions?.length > 0 ? workflow.config : {
                        throttle: 'starts_matching',
                        conditions: [
                            {condition_type: 'data_set_symbol', args: {data_set_id: null, thresholds: [{field: 'value', operator: "eq", value: 1}]}}
                        ],
                        actions: [{}]
                    }}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 8 }}
                    
                >

                    
                    <Form.Item style={{float: "right"}}>
                        <Button type="primary" htmlType="submit">
                        Save
                        </Button>
                    </Form.Item>

                    <Typography.Title level={2}>Conditions</Typography.Title>
                    <Typography.Paragraph>If a Signal matches the following condition:</Typography.Paragraph>
                    <Form.List name="conditions">
                        {(conditions, { add: addCondition, remove: removeCondition }) => (
                        <>
                            {conditions.map(condition => (
                                <div style={{border: "1px solid #333333", padding: '10px', margin: "5px 0px"}} key={`condition-${condition.key}`}>
                                {conditions.length > 1 && (
                                    <Tooltip placement='left' title="Remove Condition">
                                        <MinusCircleOutlined style={{float: "right"}} onClick={() => removeCondition(condition.name)} />
                                    </Tooltip>
                                )}
                                <Typography.Title level={3}>Source</Typography.Title>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                    }
                                >
                                {() => (
                                    <Form.Item
                                    {...condition}
                                    label="Data Set"
                                    name={[condition.name, 'args', 'data_set_id']}
                                    rules={[{ required: true, message: 'Missing Data Set' }]}
                                    extra={<>You must be subscribed to the Data Set.</>}
                                    // wrapperCol={{ span: 16, offset: 1 }}
                                    >
                                        <Select>
                                            {dataSets?.filter((dataSet) => dataSet.subscribed).map((dataSet) => (
                                                <Select.Option key={dataSet.id} value={dataSet.id}>
                                                    {dataSet.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                                </Form.Item>
                                <Form.Item
                                    {...condition}
                                    label="Symbol"
                                    name={[condition.name, 'args', 'symbol']}
                                    // wrapperCol={{ span: 6 }}
                                    extra={<>Leave blank to match any Symbol. Comma-separate multiple symbols to match multiple.</>}
                                    // wrapperCol={{ span: 16, offset: 1 }}
                                >
                                <Input />
                                </Form.Item>

                                <Typography.Title level={3}>Thresholds</Typography.Title>
                                <Form.List name={[condition.name, 'args', 'thresholds']}>
                                    {(thresholds, { add: addThreshold, remove: removeThreshold }) => (
                                    <>
                                        {thresholds.map((threshold, index) => (
                                            <div key={`threshold-${condition.key}-${threshold.key}`} id={`threshold-${condition.key}-${threshold.key}`}>
                                                {index > 0 && (
                                                    <Divider>and</Divider>
                                                )}
                                                {thresholds.length > 1 && (
                                                    <Tooltip placement='left' title="Remove Threshold">
                                                        <MinusCircleOutlined style={{float: "right"}} onClick={() => removeThreshold(threshold.name)} />
                                                    </Tooltip>
                                                )}
                                                <Form.Item
                                                    {...threshold}
                                                    label="Field"
                                                    name={[threshold.name, 'field']}
                                                    // wrapperCol={{ span: 6 }}
                                                    rules={[{ required: true, message: 'Missing field. Probably use: value' }]}
                                                    extra={<>Which field to look at. Either {'\'value\''} (default) or any of the context fields.</>}
                                                    // wrapperCol={{ span: 16, offset: 1 }}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) =>
                                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                    }
                                                >
                                                {() => (
                                                    <Form.Item
                                                        {...threshold}
                                                        label="Operator"
                                                        name={[threshold.name, 'operator']}
                                                        rules={[{ required: true, message: 'Missing operator' }]}
                                                        >
                                                            <Select>
                                                                <Select.Option key={'eq'} value={'eq'}>
                                                                    Equals
                                                                </Select.Option>
                                                                <Select.Option key={'gt'} value={'gt'}>
                                                                    Greater Than
                                                                </Select.Option>
                                                                <Select.Option key={'gte'} value={'gte'}>
                                                                    Greater Than or Equal
                                                                </Select.Option>
                                                                <Select.Option key={'lt'} value={'lt'}>
                                                                    Less Than
                                                                </Select.Option>
                                                                <Select.Option key={'lte'} value={'lte'}>
                                                                    Less Than or Equal
                                                                </Select.Option>

                                                            </Select>
                                                    </Form.Item>
                                                )}
                                                </Form.Item>
                                                <Form.Item
                                                    {...threshold}
                                                    key={`value-${threshold.key}}`}
                                                    label="Value"
                                                    name={[threshold.name, 'value']}
                                                    // wrapperCol={{ span: 4 }}
                                                    rules={[{ required: true, message: 'Missing value' }]}
                                                >
                                                    <InputNumber />
                                                </Form.Item>

                                                
                                                
                                            </div>
                                        ))}

                                        <Col span={8} offset={3}>
                                        <Button onClick={() => addThreshold({field: 'value', operator: "eq", value: 1})} block icon={<PlusOutlined />}>
                                                Add Threshold
                                            </Button>
                                        </Col>

                                        {/* <Space style={{justifyContent: "space-between", display: "flex"}}>
                                            <span></span>
                                            <Button onClick={() => addThreshold({field: 'value', operator: "eq", value: 1})} block icon={<PlusOutlined />}>
                                                Add Threshold
                                            </Button>
                                        </Space> */}


                                    </>
                                    )}

                                </Form.List>
                                {formConditions && formConditions[condition.name].args.data_set_id && showSelectedDataSet(condition)}

                            </div>
                            ))}

                                
                            {conditions.length < 1 && (
                                <Space style={{justifyContent: "space-between", display: "flex"}}>
                                    <span></span>
                                    <Button onClick={() => addCondition({condition_type: 'data_set_symbol', args: {data_set_id: null, thresholds: [{field: 'value', operator: "eq", value: 1}]}})} block icon={<PlusOutlined />}>
                                        Add Condition
                                    </Button>
                                </Space>


                            )}
                            
                        </>
                        )}
                    </Form.List>

                    <Typography.Title level={2}>Actions</Typography.Title>
                    <Typography.Paragraph>Then trigger the following action:</Typography.Paragraph>
                    <Form.List name="actions">
                        {(actions, { add: addAction, remove: removeAction }) => (
                        <>
                            {actions.map(action => (
                                <div style={{border: "1px solid #333333", padding: '20px', margin: "5px 0px"}} key={`action-${action.key}`}>
                                {/* <div key={action.key}> */}
                                {logit(action) && (<p></p>)}

                                {actions.length > 1 && (
                                    <Tooltip placement='left' title="Remove Action">
                                        <MinusCircleOutlined style={{float: "right"}} onClick={() => removeAction(action.name)} />
                                    </Tooltip>
                                )}
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                    }
                                >
                                {() => (
                                    <Form.Item
                                    {...action}
                                    label="Action"
                                    name={[action.name, 'action_type']}
                                    rules={[
                                        { required: true, message: 'Missing action' },
                                        { type: 'enum', enum: ['discord_webhook_send_message', 'telegram_send_message'], message: 'Not available to you. Contact us for access.'}
                                    ]}
                                    >
                                    <Select onChange={() => clearActionArgs(action)}>
                                        <Select.OptGroup label="Basic">
                                            <Select.Option key={'discord_webhook_send_message'} value={'discord_webhook_send_message'}>
                                                Send message to Discord
                                            </Select.Option>
                                            <Select.Option key={'telegram_send_message'} value={'telegram_send_message'}>
                                                Send message to Telegram
                                            </Select.Option>
                                        </Select.OptGroup>
                                        
                                        <Select.OptGroup label="Advanced">
                                            <Select.Option key={'add_to_dataset'} value={'add_to_dataset'}>
                                                Add to Data Set
                                            </Select.Option>
                                            {/* <Select.Option key={'add_to_clipboard'} value={'add_to_clipboard'}>
                                                Add to Clipboard for Review
                                            </Select.Option> */}
                                            {/* <Select.Option key={'notify_in_app'} value={'notify_in_app'}>
                                                Notify in-app
                                            </Select.Option> */}
                                            <Select.Option key={'daily_digest_email'} value={'daily_digest_email'}>
                                                Add to Daily Digest Email
                                            </Select.Option>
                                            {/* <Select.Option key={'immediate_email'} value={'immediate_email'}>
                                                Email me immediately
                                            </Select.Option> */}
                                            <Select.Option key={'webhook'} value={'webhook'}>
                                                Trigger Webhook
                                            </Select.Option>
                                            <Select.Option key={'websocket'} value={'websocket'}>
                                                Send to Websocket
                                            </Select.Option>
                                            <Select.Option key={'discord_webhook_send_advanced_message'} value={'discord_webhook_send_advanced_message'}>
                                                Send advanced message to Discord
                                            </Select.Option>
                                            <Select.Option key={'telegram_send_advanced_message'} value={'telegram_send_advanced_message'}>
                                                Send advanced message to Telegram
                                            </Select.Option>
                                        </Select.OptGroup>
                                        
                                    </Select>
                                    </Form.Item>
                                )}
                                </Form.Item>
                                
                                {formActions && formActions[action.name] && (
                                    getActionFormForActionType(action, formActions[action.name].action_type)
                                )}
                                
                            </div>
                            ))}

                            {actions.length < 1 && (
                                <Space style={{justifyContent: "space-between", display: "flex"}}>
                                    <span></span>
                                    <Button onClick={() => addAction({})} block icon={<PlusOutlined />}>
                                        Add Action
                                    </Button>
                                </Space>
                            )}
                            
                            
                        </>
                        )}
                    </Form.List>

                    <Typography.Title level={2}>Options</Typography.Title>
                    <Form.Item
                        label="Throttle to"
                        name={'throttle'}
                    >
                        <Select>
                            <Select.Option key={'starts_matching'} value={'starts_matching'}>
                                When condition starts matching, not subsequent matches
                            </Select.Option>
                            <Select.Option key={'all'} value={'all'}>
                                Every matching Signal
                            </Select.Option>
                            <Select.Option key={'once_a_day'} value={'once_a_day'}>
                                Once in a 24 hour period the first time a Signal matches
                            </Select.Option>

                            <Select.Option key={'once'} value={'once'}>
                                Only once ever, then disable
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    {/*expiration*/}


                    {/* <h2>Options</h2>
                    <Form.Item
                        label="When to trigger"
                        name={'action_redundancy'}
                    >
                        <Select style={{ width: 300 }}>
                            <Select.Option key={1} value={1}>
                                When condition starts matching
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                                Only Once
                            </Select.Option>
                            <Select.Option key={3} value={3}>
                                Once per matching signal
                            </Select.Option>
                            <Select.Option key={4} value={4}>
                                Once per day
                            </Select.Option>
                        </Select>
                    </Form.Item> */}

                    {/* <Form.Item name='only_certain_days' valuePropName="checked" wrapperCol={{ span: 16 }}>
                        <Checkbox>Only enable certain times and days.</Checkbox>
                    </Form.Item> */}

                    {/* <Collapse ghost>
                        <Collapse.Panel header="Show Config (for Debugging)" key="1">
                            <pre>{JSON.stringify({"throttle": formThrottle, "conditions": formConditions, "actions": formActions}, null, 2)}</pre>
                        </Collapse.Panel>
                    </Collapse> */}



                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                        Save
                        </Button>
                    </Form.Item>
                </Form>

                
            </>
        )
    }

    const workflowActivity = () => {
        return (
            <WorkflowExecutionsList workflow_id={workflow.id} />
        )
        // useEffect(() => {
        //     if (!id) {
        //         return
        //     }
        //     getWorkflow(id).then((res) => {
        //         // setInFlight(false)
        //         // setAttemptedLoad(true)
        //         setAttemptedLoad(true)
        //         console.log("workflow", {res})
        //         if (res.status === 200) {
        //             const response_data = res.data
        //             setWorkflow(response_data)
        //         } else {
        //             setWorkflow(null)
    
        //         }
    
                
        //     }
        //     ).catch(() => {
        //             setWorkflow(null)
        //     })
            
        // }, [id])

    }

    return (
        <div className="App">
        <div className="App-content">
            {/* Data Set: {workflow.name} */}
            <div className="App-page-content">

                <PageHeader
                    title={workflow.name}
                    right={
                        <>
                        {workflow.enabled === 1 && (
                            <Tooltip placement='left' title="Disable Workflow" key={"enable"}>
                                <Button key="1" onClick={onDisable}>
                                    Disable
                                </Button>
                            </Tooltip>
                        )}
                        {workflow.enabled !== 1 && (
                            <Tooltip placement='left' title="Enable Workflow" key={"disable"}>
                                <Button key="1" type="primary" onClick={onEnable}>
                                    Enable
                                </Button>
                            </Tooltip>
                        )}
                        </>
                    }
                />

                <Tabs
                    defaultActiveKey="config"
                    // onChange={onChange}
                    items={[
                    {
                        label: `Configuration`,
                        key: 'config',
                        children: workflowConfiguration(),
                    },
                    {
                        label: `Activity`,
                        key: 'activity',
                        children: workflowActivity(),
                    },
                    // {
                    //     label: `Dependencies`,
                    //     key: 'dependencies',
                    //     children: "Dependencies wip...",
                    // },
                    ]}
                />


            </div>
        </div>
        </div>
    );
}
