import React, { useState, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import type { MenuProps } from 'antd';
import { Col, Divider, Row, Space, Button, Checkbox, Form, Input, Modal, Select, message, Alert, Avatar, Dropdown, Menu, Typography, Tag  } from 'antd';

import {
  log_out,
  authenticateAsync,
  registerAsync,
  registerConfirmAsync,
  selectLoggedIn,
  selectUsername,
  selectStatus,
  selectAccount,
  attemptAuthFromTokens,
  selectLoginAttempted,
  selectLoginMessage,
  clear_message,
  resetPasswordAsync,
  submitResetPasswordAsync,
  selectMessage,
  selectMessageLevel,
} from './loginSlice';
import { useIsBigScreen } from '../../app/hooks'
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserOutlined, LoadingOutlined, LogoutOutlined, ApiOutlined, BulbOutlined, SettingOutlined } from '@ant-design/icons';
import type { SelectProps } from 'antd';
import { LightDarkSwitch } from '../../features/lightDarkSwitch/LightDarkSwitch'
import {selectLight} from '../../features/lightDarkSwitch/lightDarkSwitchSlice'

import styles from './Login.module.css';

interface RegisterConfirmFormParams {
    email: string
    code: string
}

export function Login() {
    const loggedIn = useAppSelector(selectLoggedIn);
    const username = useAppSelector(selectUsername);
    const status = useAppSelector(selectStatus);
    const account = useAppSelector(selectAccount);
    const loginAttempted = useAppSelector(selectLoginAttempted);
    const login_message = useAppSelector(selectLoginMessage);
    const asyncMessage = useAppSelector(selectMessage);
    const messageLevel = useAppSelector(selectMessageLevel);
    const dispatch = useAppDispatch();
    const [loginForm] = Form.useForm();
    const [registerForm] = Form.useForm();
    const [confirmForm] = Form.useForm();
    const [forgotPasswordForm] = Form.useForm();
    const [forgotPasswordSubmitForm] = Form.useForm();
    const registerTerms = Form.useWatch('terms', registerForm);

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const [isRegisterConfirmModalVisible, setIsRegisterConfirmModalVisible] = useState(false);
    const [isForgotPasswordSubmitModalVisible, setIsForgotPasswordSubmitModalVisible] = useState(false);
    const isBigScreen = useIsBigScreen()
    const light = useAppSelector(selectLight)
    let [searchParams, setSearchParams] = useSearchParams();
    let location = useLocation();


    useEffect(() => {
        const query = new URLSearchParams(searchParams);
        const signup = query.get('signup')
        if (signup === "1") {
            showSignUpModal()
            setTimeout(clearSearchParams, 1000)
        }
    }, [location.search]);


    console.log("Login", {loggedIn, username, account, status})

    const signUpOptions: SelectProps["options"] = [
        {
            label: 'Notifications',
            value: 'notifications',
        },
        {
            label: 'Automated Trading',
            value: 'trading',
        },
        {
            label: 'Discord Bot',
            value: 'discord',
        },
        {
            label: 'Selling Data',
            value: 'data_provider',
        },
        {
            label: 'Affiliate Program',
            value: 'affiliate',
        },
    ]

    if (login_message) {
        console.log(login_message)
        message.error(login_message)
        dispatch(clear_message())

    
    }

    const showLoginModal = () => {
        setIsLoginModalVisible(true);
        setIsSignUpModalVisible(false);
        setIsRegisterConfirmModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsForgotPasswordSubmitModalVisible(false);
        dispatch(clear_message())
    }

    const showSignUpModal = () => {
        setIsLoginModalVisible(false);
        setIsSignUpModalVisible(true);
        setIsRegisterConfirmModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsForgotPasswordSubmitModalVisible(false);
        dispatch(clear_message())
    }

    const showForgotPasswordModal = () => {
        setIsLoginModalVisible(false);
        setIsSignUpModalVisible(false);
        setIsRegisterConfirmModalVisible(false);
        setIsForgotPasswordModalVisible(true);
        setIsForgotPasswordSubmitModalVisible(false);
        dispatch(clear_message())
    }

    const showRegisterConfirmModal = () => {
        setIsLoginModalVisible(false);
        setIsSignUpModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsRegisterConfirmModalVisible(true);
        setIsForgotPasswordSubmitModalVisible(false);
        dispatch(clear_message())
    }

    const showForgotPasswordSubmitModal = () => {
        setIsLoginModalVisible(false);
        setIsSignUpModalVisible(false);
        setIsRegisterConfirmModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsForgotPasswordSubmitModalVisible(true);
        dispatch(clear_message())
    }

    const resetForms = () => {
        loginForm.resetFields()
        registerForm.resetFields()
        confirmForm.resetFields()
        forgotPasswordForm.resetFields()
        forgotPasswordSubmitForm.resetFields()
        dispatch(clear_message())

    }

    const handleCancel = () => {
        resetForms()
        setIsLoginModalVisible(false);
        setIsSignUpModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsRegisterConfirmModalVisible(false);
        setIsForgotPasswordSubmitModalVisible(false);
        dispatch(clear_message())
        clearSearchParams()

    }

    const clearSearchParams = () => {
        const query = new URLSearchParams(searchParams);
        const signup = query.get('signup')
        console.log("checking singup", {query, signup})
        if (signup === "1") {
            setSearchParams({})
        }
    }

    const log_out_click = () => {
        dispatch(log_out())
    }

    if (!loggedIn && status === 'idle' && !loginAttempted) {
        dispatch(attemptAuthFromTokens())
    }

    const onAuthenticateFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(authenticateAsync({username: values.email, password: values.password, remember_me: values.remember}))
    };

    const onAuthenticateFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    const onRegisterFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(registerAsync({email: values.email, password: values.password}))
        // dispatch(registerAsync({email: values.email, password: values.passwor, interests: values.interests}))
        setIsSignUpModalVisible(false);
        setIsRegisterConfirmModalVisible(true)
        resetForms()
        confirmForm.setFieldsValue({email: values.email})

    };

    const onRegisterFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onRegisterConfirmFinish = (values: RegisterConfirmFormParams) => {
        console.log('Success:', values);
        dispatch(registerConfirmAsync({email: values.email, code: values.code}))
        setIsRegisterConfirmModalVisible(false);
        setIsLoginModalVisible(true);
        resetForms()
        loginForm.setFieldsValue({email: values.email})

    };

    const onRegisterConfirmFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onForgotPasswordFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(resetPasswordAsync({email: values.email}))
        setIsForgotPasswordModalVisible(false);
        setIsForgotPasswordSubmitModalVisible(true);
        resetForms()
        forgotPasswordSubmitForm.setFieldsValue({email: values.email})
    };

    const onForgotPasswordFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onForgotPasswordSubmitFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(submitResetPasswordAsync({email: values.email, code: values.code, password: values.password}))
        setIsForgotPasswordSubmitModalVisible(false);
        setIsLoginModalVisible(true);
        resetForms()
        loginForm.setFieldsValue({email: values.email})
    };

    const onForgotPasswordSubmitFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if (loggedIn && account && isLoginModalVisible){
        resetForms()
        setIsLoginModalVisible(false);
    }

    const AsyncMessage = () => {
        if (asyncMessage) {
            if (messageLevel) {
                return (
                    <>
                        <Alert message={asyncMessage} type={messageLevel} />
                        <p></p>
                    </>
                )
            } else {
                return (
                    <>
                        <Alert message={asyncMessage} type="info" />
                        <p></p>
                    </>
                )
            }
        } else {
            return <></>
        }
    }

    const menu: MenuProps['items'] = [
        {
            label: <span>{username}</span>,
            icon: <UserOutlined />,
            disabled: true,
            key: '0',
        },
        // {
        //     label: <LightDarkSwitch />,
        //     icon: <BulbOutlined />,
        //     key: '1',
        // },
        {
            label: <Link to={`/integrations`}>Integrations</Link>,
            icon: <ApiOutlined />,
            key: '2',
        },
        {
            label: <Link to={`/account`}>Account & Billing</Link>,
            icon: <SettingOutlined />,
            key: '3',
        },
        // Settings
        // Billing
        {
            type: 'divider',
        },
        {
            label: <a onClick={log_out_click}>Log Out</a>,
            icon: <LogoutOutlined />,
            key: '4',
        },
    ]


    return (
        <>
            <Space>
            {/* <Row justify="end"> */}
                {(status === 'loading') && (
                    <Typography.Paragraph>Loading...</Typography.Paragraph>
                )}
                {/* {(status === 'failed') && (
                    <Typography.Paragraph>Failed. <a className='App-link' onClick={showLoginModal}>Log In</a></Typography.Paragraph>
                )} */}
                {(status === 'idle' && account && loggedIn ) && (
                    <>
                        {/* <Tag color="magenta">Upgrade</Tag> */}

                        {/* <Link to={`/upgrade`}><Button type={"text"} style={{color: 'rgba(231, 0, 212, 0.85)'}}>Upgrade to Pro</Button></Link> */}
                        
                        <Dropdown menu={{items: menu}} trigger={['hover']} placement="bottomRight">
                            <Avatar style={{ color: '#dddddd', backgroundColor: '#666666' }} icon={<UserOutlined />} />
                        </Dropdown>
                    </>
                    
                )}
                {((status === 'idle' || status === 'failed') && (account === null || account === undefined || !loggedIn)) && (
                    <>
                        {/* <LightDarkSwitch mode='icon' /> */}
                        {isBigScreen && (
                            <Button type="primary" onClick={showSignUpModal}>Sign Up</Button>
                        )}
                        {/* <Button type="primary" onClick={showSignUpModal}>Sign Up</Button> */}
                        <Button type={light && !isBigScreen ? "primary" : "default"} onClick={showLoginModal}>Log In</Button>
                        {/* {isBigScreen && (
                            <Button type="default" onClick={showLoginModal}>Log In</Button>
                        )} */}
                    </>
    
                )}
            </Space>
            {/* </Row> */}
            <Modal title="Login" open={isLoginModalVisible} footer={null} onCancel={handleCancel}>
            <Form
                name="basic"
                form={loginForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: false }}
                onFinish={onAuthenticateFinish}
                onFinishFailed={onAuthenticateFinishFailed}
                autoComplete="off"
                >
                <AsyncMessage />
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 6, span: 16 }}>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                    <Button type="primary" htmlType="submit" disabled={status === 'loading'}>
                    {status === 'loading' ? <LoadingOutlined /> : 'Submit' }
                    </Button>
                </Form.Item>
                </Form>
                {/* <Typography.Paragraph>Email</Typography.Paragraph>
                <Typography.Paragraph>Password</Typography.Paragraph> */}
                <Typography.Paragraph>Or: <a onClick={showSignUpModal}>Sign Up</a> or <a onClick={showForgotPasswordModal}>Forgot Password</a></Typography.Paragraph>
                {/* <Typography.Paragraph><Button onClick={authenticate_click}>Submit</Button></Typography.Paragraph> */}
            </Modal>
            <Modal title="Sign Up" open={isSignUpModalVisible} footer={null} onCancel={handleCancel}>
                <Form
                name="basic"
                form={registerForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ terms: false }}
                onFinish={onRegisterFinish}
                onFinishFailed={onRegisterFinishFailed}
                autoComplete="off"
                >
                    
                    <AsyncMessage />
                    <Typography.Paragraph>
                        Signing up is free and gives you access to personalization, notifications and subscriptions.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        After submitting, you'll receive an email with a verification code.
                    </Typography.Paragraph>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    {/* <Form.Item
                        label="Interested In"
                        name="interests"
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder=""
                            defaultValue={[]}
                            // onChange={handleChange}
                            options={signUpOptions}
                        />
                    </Form.Item> */}

                    <Form.Item name="terms" valuePropName="checked" wrapperCol={{ offset: 6, span: 16 }}>
                        <Checkbox>Agree to <a href='/terms_and_conditions' target='blank'>Terms</a></Checkbox>
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit" disabled={!registerTerms}>
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Typography.Paragraph>Or: <a onClick={showLoginModal}>Log In</a> or <a onClick={showRegisterConfirmModal}>Verify Email</a></Typography.Paragraph>
            </Modal>
            <Modal title="Confirm Sign Up" open={isRegisterConfirmModalVisible} footer={null} onCancel={handleCancel}>
                <Form
                name="basic"
                form={confirmForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{}}
                onFinish={onRegisterConfirmFinish}
                onFinishFailed={onRegisterConfirmFinishFailed}
                autoComplete="off"
                >
                    <AsyncMessage />
                    <Typography.Paragraph>
                        We sent you an email with a verification code. Enter that here.
                    </Typography.Paragraph>

                    {/* <Typography.Paragraph>
                        Didn't receive an email? <a>Click here.</a>
                    </Typography.Paragraph> */}
                    
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[{ required: true, message: 'Please input code sent to your email' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Typography.Paragraph>Or: <a onClick={showLoginModal}>Log In</a> or <a onClick={showSignUpModal}>Sign Up</a></Typography.Paragraph>
            </Modal>
            <Modal title="Forgot Password" open={isForgotPasswordModalVisible} footer={null} onCancel={handleCancel}>
                <Typography.Paragraph>If you forgot your password or need an email verification email sent again, enter your email below.</Typography.Paragraph>
                <Form
                name="basic"
                form={forgotPasswordForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{}}
                onFinish={onForgotPasswordFinish}
                onFinishFailed={onForgotPasswordFinishFailed}
                autoComplete="off"
                >
                    <AsyncMessage />
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please enter your email address' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Typography.Paragraph>Or: <a onClick={showLoginModal}>Back to Log In</a> or <a onClick={showForgotPasswordSubmitModal}>Submit Forgot Password Code</a></Typography.Paragraph>
            </Modal>
            <Modal title="Forgot Password Submission" open={isForgotPasswordSubmitModalVisible} footer={null} onCancel={handleCancel}>
                <Typography.Paragraph>We sent you an email with a verification code. Enter your email, that code and your new password.</Typography.Paragraph>
                <Form
                name="basic"
                form={forgotPasswordSubmitForm}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{}}
                onFinish={onForgotPasswordSubmitFinish}
                onFinishFailed={onForgotPasswordSubmitFinishFailed}
                autoComplete="off"
                >
                    <AsyncMessage />
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please enter your email address' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[{ required: true, message: 'Please input code sent to your email' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Typography.Paragraph>Or: <a onClick={showLoginModal}>Back to Log In</a> or <a onClick={showForgotPasswordModal}>Forgot Password</a></Typography.Paragraph>
            </Modal>
        </>

    )
}
