import { CopyToClipboard as CopyToClipboardOriginal } from 'react-copy-to-clipboard';
import { message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { DataProvider } from '../types/types'
import { ReactNode } from 'react'
import { TooltipPlacement } from 'antd/es/tooltip';


interface CopyToClipboardParams {
    text: string
    tooltip?: string
    content?: string | ReactNode
    placement?: TooltipPlacement
}


export function CopyToClipboard({
    text,
    tooltip = 'Copy',
    content = <CopyOutlined />,
    placement = 'top',
}: CopyToClipboardParams) {

    return (
        <Tooltip placement={placement} title={tooltip}>

            <CopyToClipboardOriginal text={text} onCopy={() => message.info("Copied!")}>
                <span style={{cursor: 'pointer'}}>{content}</span>
            </CopyToClipboardOriginal>
        </Tooltip>
    )
    // return <Link to={`/data_providers/${data_provider.id}/`}>{data_provider.name}</Link>
}
