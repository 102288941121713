import { Button, Divider, Radio, Carousel, Popconfirm, message } from 'antd';
import { useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import { DateTime } from 'luxon';

import { Table, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { WorkflowExecution } from '../types/types';
// import styles from './WorkflowList.module.css';
import { PaginatedTable } from './PaginatedTable';
import { SymbolLink } from './SymbolLink';
import { Loading } from './Loading';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getWorkflowExecutions } from '../services/signalApi';
import { CreateWorkflowModal } from '../components/CreateWorkflowModal'


interface WorkflowListParams {
    workflow_id?: string
}


export function WorkflowExecutionsList({
    workflow_id
}: WorkflowListParams) {
    console.log("WorkflowExecutionsList")
    const account = useAppSelector(selectAccount)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [reloads, setReloads] = useState(0);
    
    const content_table = () => {
        let columns: ColumnsType<WorkflowExecution> = [
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              render: (text: string, row: WorkflowExecution) => {
                return <>{row.id}</>
              },
            },
            // what symbol, the signal that triggered it? load the signal_id here? the historical datapoint, the value
            // what was sent - take it from the Action - notified Discord
            // info about success/failure?
            {
              title: 'Time',
              dataIndex: 'created_date',
              key: 'created_date',
              render: (value: string, row: WorkflowExecution) => {
                return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
              },
            },
            

        ];
        
        const getPagination = (data: any) => {
            const paginationParams = {
                limit: data.pageSize,
                ...(data.current > 1 ? {offset: (data.current - 1) * data.pageSize} : {}),
                // offset: (data.current - 1) * data.pageSize,
            }
            console.log('WorkflowList getPagination', {data, paginationParams})
            return paginationParams
            // return {}
        }

        const fetchData = (data: any) => {
            console.log("WorkflowExecutionsList fetchData", {data})
            return getWorkflowExecutions({
                workflow_id: workflow_id,
                ...(data.pagination ? getPagination(data.pagination) : {}),
                ordering: '-created_date'
            }).then((res) => {
                console.log("WorkflowExecutionsList Workflows", {res})
                if (res.status === 200) {
                    return {
                        results: res.data.results || [],
                        count: res.data.count || 0
                    }
                } else {
                    return {
                        results: [],
                        count: 0
                    }
                }
            }).catch(() => {
                return {
                    results: [],
                    count: 0
                }
            })
        }

        
        console.log("WorkflowList Returning...")

        const emptyDescription = () => {
            return "No Workflow Activity"
        }

        return <PaginatedTable<WorkflowExecution>
            columns={columns}
            rowKey={(Workflow) => Workflow.id}
            fetchData={fetchData}
            filterValues={{reloadTime: reloadedLast}}
            emptyDescription={emptyDescription()}
            key={reloads}
        />
            
    }

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    return (
        <>
            {content_table()}
        </>
    )
    
    
    
}
