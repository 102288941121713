import { themesArr, Theme } from './types/types';
import { green, yellow, red } from '@ant-design/colors';

const lightColorConfig = {
    "colorTextBase": "#111"
}

const darkColorConfig = {
    "colorTextBase": "#ffffff"
}

type LightDark = 'light' | 'dark'

interface RegisteredTheme {
    id: Theme
    name: string
    headerLight: LightDark
    sideNavLight?: LightDark
    bodyLight: LightDark
    config: {[key: string]: string}
    green?: string
    red?: string
    yellow?: string
    hidden?: boolean
}


// https://ant.design/docs/react/customize-theme#maptoken
export const themeList: RegisteredTheme[] = [
    {
        id: 'light',
        name: "Light",
        headerLight: 'light',
        sideNavLight: 'light',
        bodyLight: 'light',
        config: {
            ...lightColorConfig,
            boxShadow: '0',
        },
        green: '#00b300',
    },
    {
        id: 'pro',
        name: "Pro",
        headerLight: 'dark',
        sideNavLight: 'light',
        bodyLight: 'light',
        config: {
            ...lightColorConfig,
            // colorBgLayout: '#dce0e8',
            // colorBgMask: '#6c7086',
            // colorBgElevated: '#e6e9ef',
            // colorBgContainer: '#eff1f5',
            // colorText: '#4c4f69',
            // colorTextSecondary: '#5c5f77',
            // colorTextTertiary: '#6c6f85',
            // colorPrimary: '#1e66f5',
            // colorPrimaryText: '#1e66f5',
            // colorSuccessText: '#40a02b',
            // colorWarningText: '#df8e1d',
            // colorInfoText: '#04a5e5',
            // colorErrorText: '#e64553',
            // colorLink: '#5972fd',

            
        },
    },
    {
        id: 'purple',
        name: "Purple",
        hidden: true,
        headerLight: 'dark',
        sideNavLight: 'light',
        bodyLight: 'light',
        config: {
            ...lightColorConfig
        },
    },
    {
        id: 'green',
        name: "Green",
        hidden: true,
        headerLight: 'dark',
        sideNavLight: 'light',
        bodyLight: 'light',
        config: {
            ...lightColorConfig
        },
    },
    {
        id: 'catppuccin-latte',
        name: "Latte",
        headerLight: 'dark',
        sideNavLight: 'light',
        bodyLight: 'light',
        config: {
            ...lightColorConfig,
            // colorBgBase: '#11111b',
            colorBgLayout: '#dce0e8',
            // colorBgMask: '#6c7086',
            colorBgElevated: '#e6e9ef',
            colorBgContainer: '#eff1f5',
            colorText: '#4c4f69',
            colorTextSecondary: '#5c5f77',
            colorTextTertiary: '#6c6f85',
            colorPrimary: '#1e66f5',
            colorPrimaryText: '#1e66f5',
            colorSuccessText: '#40a02b',
            colorWarningText: '#df8e1d',
            colorInfoText: '#04a5e5',
            colorErrorText: '#e64553',
            colorLink: '#5972fd',
            // colorTextLightSolid: '#fff'
            // colorTextHeading: '#cdd6f4'
            // colorBorder: '#7f849c',
        },
        green: '#40a02b',
        red: '#d20f39',
    },
    {
        id: 'dark',
        name: "Black",
        headerLight: 'dark',
        bodyLight: 'dark',
        config: {
            ...darkColorConfig
        },
    },
    {
        id: 'alt-dark',
        name: "Alt Dark",
        headerLight: 'dark',
        bodyLight: 'dark',
        config: {
            ...darkColorConfig,
            "colorBgLayout": "#202124",
            "colorBgElevated": "#2D2F31",
            "colorText": "#E8EAED",
            "colorTextSecondary": "#BDC1C6",
            "colorPrimary": "#1A73E8",
            "colorSecondary": "#F4B400",
            "colorPrimaryText": "#E8EAED",
            "colorSuccessText": "#34A853",
            "colorWarningText": "#FBBC05",
            "colorInfoText": "#4285F4",
            "colorErrorText": "#EA4335",
            "colorLink": "#1A73E8"
        }
        ,
        green: '#34a853',
        red: '#db4437',
    },
    {
        id: 'catppuccin-mocha',
        name: "Mocha",
        hidden: true,
        headerLight: 'dark',
        bodyLight: 'dark',
        config: {
            ...darkColorConfig,
            // colorBgBase: '#11111b',
            colorBgLayout: '#1e1e2e',
            // colorBgMask: '#6c7086',
            colorBgElevated: '#45475a',
            colorBgContainer: '#313244',
            colorText: '#e2e2e2',
            colorTextSecondary: '#bac2de',
            colorTextTertiary: '#a6adc8',
            colorPrimary: '#89b4fa',
            colorPrimaryText: '#89b4fa',
            colorSuccessText: '#a6e3a1',
            colorWarningText: '#f9e2af',
            colorInfoText: '#89b4fa',
            colorErrorText: '#f38ba8',
            colorLink: '#74c7ec',
            // colorTextLightSolid: '#fff'
            // colorTextHeading: '#cdd6f4'
            // colorBorder: '#7f849c',
        },
        green: '#a6e3a1',
        red: '#f38ba8',
    },
    {
        id: 'dracula',
        name: "Dracula",
        headerLight: 'dark',
        bodyLight: 'dark',
        config: {
            ...darkColorConfig,
            // colorBgBase: '#11111b',
            colorBgLayout: '#282a36',
            // colorBgMask: '#6c7086',
            colorBgElevated: '#383a59',

            // #383a59, 44475a
            colorBgContainer: '#36374a',
            colorText: '#f8f8f2',
            colorTextSecondary: '#6272a4',
            // colorTextTertiary: '#6c6f85',
            colorPrimary: '#8bb0fd',
            colorPrimaryText: '#8bb0fd',
            colorSuccessText: '#50fa7b',
            colorWarningText: '#ffb86c',
            colorInfoText: '#8be9fd',
            colorErrorText: '#ff5555',
            colorLink: '#8bb0fd',
            // colorTextLightSolid: '#fff'
            // colorTextHeading: '#cdd6f4'
            // colorBorder: '#7f849c',
        },
        green: '#50fa7b',
        red: '#ff5555',
    },
    {
        id: 'alt-light',
        name: "Alt Light",
        headerLight: 'dark',
        bodyLight: 'light',
        sideNavLight: 'light',
        config: {
            "colorBgLayout": "#F5F5F5",
            "colorBgElevated": "#FFFFFF",
            "colorText": "#212121",
            "colorTextSecondary": "#757575",
            "colorPrimary": "#3F51B5",
            "colorSecondary": "#FF4081",
            "colorPrimaryText": "#FFFFFF",
            "colorSuccessText": "#4CAF50",
            "colorWarningText": "#FF9800",
            "colorInfoText": "#2196F3",
            "colorErrorText": "#F44336",
            "colorLink": "#3F51B5"
        }
        ,
        green: '#4CAF50',
        red: '#F44336',
    },
    // https://www.nordtheme.com/docs/colors-and-palettes
    {
        id: 'nord-light',
        name: "Nord Light",
        headerLight: 'light',
        bodyLight: 'light',
        sideNavLight: 'light',
        // config: {
        //     ...lightColorConfig,
        //     boxShadow: '0',
        // },
        config: {
            ...lightColorConfig,
            "colorBgLayout": "#eceff4",
            "colorBgElevated": "#e5e9f0",
            colorBgContainer: '#f8f9fb',
            "colorText": "#2e3440",
            "colorTextSecondary": "#3b4252",
            "colorPrimary": "#5e81ac",
            "colorSecondary": "#8fbcbb",
            "colorPrimaryText": "#81a1c1",
            "colorSuccessText": "#8fbcbb",
            "colorWarningText": "#ebcb8b",
            "colorInfoText": "#2196F3",
            "colorErrorText": "#bf616a",
            "colorLink": "#5e81ac"
        },
        red: '#bf616a',
        green: '#a3be8c',
    },
    {
        id: 'nord-dark',
        name: "Nord Dark",
        headerLight: 'dark',
        bodyLight: 'dark',
        // sideNavLight: 'dark',
        // config: {
        //     ...lightColorConfig,
        //     boxShadow: '0',
        // },
        config: {
            ...darkColorConfig,
            "colorBgLayout": "#2e3440",
            "colorBgElevated": "#3b4252",
            colorBgContainer: '#232831',
            "colorText": "#eceff4",
            "colorTextSecondary": "#e5e9f0",
            "colorPrimary": "#5e81ac",
            "colorSecondary": "#88c0d0",
            "colorPrimaryText": "#eceff4",
            "colorSuccessText": "#a3be8c",
            "colorWarningText": "#ebcb8b",
            "colorInfoText": "#2196F3",
            "colorErrorText": "#bf616a",
            "colorLink": "#5e81ac"
        },
        red: '#bf616a',
        green: '#a3be8c',
    },

]

export const themes = Object.assign({}, ...themeList.map(theme => ({[theme.id]: theme})));

export const getTheme = (theme: Theme) => themes[theme]
export const getThemeName = (theme: Theme) => themes[theme].name
export const getThemeHeaderLight = (theme: Theme): LightDark => themes[theme].headerLight
export const getThemeSideNavLight = (theme: Theme): LightDark => themes[theme].sideNavLight || themes[theme].headerLight
export const getThemeBodyLight = (theme: Theme): LightDark => themes[theme].bodyLight
export const getThemeConfig = (theme: Theme) => themes[theme].config || {}
export const getThemeGreen = (theme: Theme) => themes[theme].green || green[6]
export const getThemeYellow = (theme: Theme) => themes[theme].yellow || yellow[6]
export const getThemeRed = (theme: Theme) => themes[theme].red || red[6]
