// import './theme.less'
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import './App.css';
import { useIsBigScreen, useIsSmallScreen, useIsXLargeScreen } from './app/hooks';
// import ReactGA from "react-ga";
import { ConfigProvider, Drawer, Layout, Menu, Space, Typography, theme } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { FooterContent } from './components/FooterContent';
import { AiChat } from './features/aiChat/AiChat';
import { Cart } from './features/cart/Cart';
import { Login } from './features/login/Login';
import { selectAccount, selectLoggedIn, selectLoginAttempted, selectStatus } from './features/login/loginSlice';
import { About } from './views/About/About';
import { Account } from './views/Account/Account';
import { Billing } from './views/Billing/Billing';
import { Bots } from './views/Bots/Bots';
import { Browse } from './views/Browse/Browse';
import { Contact } from './views/Contact/Contact';
import { Dashboard } from './views/Dashboard/Dashboard';
import { Dashboards } from './views/Dashboards/Dashboards';
import { DataProvider } from './views/DataProvider/DataProvider';
import { DataProviding } from './views/DataProviding/DataProviding';
import { DataSet } from './views/DataSet/DataSet';
import { Forecast } from './views/Forecast/Forecast';
import { Home } from './views/Home/Home';
import { Integrations } from './views/Integrations/Integrations';
import { Request } from './views/Request/Request';
import { Signals } from './views/Signals/Signals';
import { Store } from './views/Store/Store';
import { Subscriptions } from './views/Subscriptions/Subscriptions';
import { Symbol } from './views/Symbol/Symbol';
import { Terms } from './views/Terms/Terms';
import { Workflow } from './views/Workflow/Workflow';
import { Workflows } from './views/Workflows/Workflows';
// import { LightDarkSwitch } from './features/lightDarkSwitch/LightDarkSwitch'
import { ApiStatus } from './components/ApiStatus';
import { GlobalSearch } from './components/GlobalSearch';
import { LightDarkSwitch } from './features/lightDarkSwitch/LightDarkSwitch';
import { selectLight, selectTheme } from './features/lightDarkSwitch/lightDarkSwitchSlice';

import { useAppSelector } from './app/hooks';
import { getThemeConfig, getThemeBodyLight, getThemeHeaderLight, getThemeSideNavLight } from './themeRegistry';
import { AdvertisingContent, AffiliateContent, AnalysisTrackingContent, CareersContent, CommunityContent, ContactUsContent, CustomStrategyContent, DataProviderTermsConditionsContent, DataProvidingContent, DiscordBotContent, DocumentationContent, EmailBotContent, EnterpriseContent, FreqtradeCryptoBotContent, IntegrationOfferings, InvestorsContent, OnDemandAnalysisContent, PrivacyPolicyContent, SubscriberTermsConditionsContent, TeamContent, TelegramBotContent, TermsConditionsContent, TradingViewWebhookContent, TwitterBotContent } from './views/content/pages';
// import { ReactComponent as FreqSignalsLogo } from './images/freqsignals_logo.svg'
import Icon, {
  ApiOutlined,
  BranchesOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  EllipsisOutlined,
  FileTextOutlined, FolderOpenOutlined,
  HomeOutlined,
  LineChartOutlined,
  SendOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SubnodeOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { default as FreqSignalsLogo } from './images/freqsignals';

const { Header, Sider, Content, Footer } = Layout;

interface MenuSelection {
  key: string
}

export type LayoutType = 'Sidebar' | 'Topnav'

interface Layout {
  type: LayoutType
}

const layout_type: LayoutType = 'Topnav'
const layout: Layout = {type: layout_type}
const layout_type_class_names = {
  Sidebar: 'sidebar',
  Topnav: 'topnav'
}

function SideNav() {
  let navigate = useNavigate();
  const light = useAppSelector(selectLight)

  let location = useLocation();

  const items = [
    { label: 'Home', key: '/', icon: <HomeOutlined /> },
    { label: 'Dashboards', key: '/dashboards', icon: <DashboardOutlined /> },
    { label: 'Browse', key: '/browse', icon: <FolderOpenOutlined /> },
    { label: 'Forecast', key: '/forecast', icon: <LineChartOutlined /> },
    { label: 'Subscriptions', key: '/subscriptions', icon: <SubnodeOutlined /> },
    // { label: 'Request', key: '/request', icon: <PullRequestOutlined /> },
    { label: 'Data Providing', key: '/data_providing', icon: <DatabaseOutlined /> },
    { label: 'Billing', key: '/billing', icon: <CreditCardOutlined /> },
    { label: 'About', key: '/about', icon: <TeamOutlined /> },
    { label: 'Contact', key: '/contact', icon: <SendOutlined /> },
    { label: 'Terms / Privacy', key: '/terms', icon: <FileTextOutlined /> },
  ];

  const onSelect = ({key}: MenuSelection) => {
    navigate(key)
  }
  // console.log("location", {location})

  return <Menu items={items} theme={light ? 'light' : 'dark'} onSelect={onSelect} selectedKeys={[location.pathname]} />

}

function TopNav() {
  let navigate = useNavigate();
  let location = useLocation();
  const account = useAppSelector(selectAccount);
  const light = useAppSelector(selectLight);
  const selectedTheme = useAppSelector(selectTheme);
  const isBigScreen = useIsBigScreen()
  const isXLargeScreen = useIsXLargeScreen()
  const [navOpen, setNavOpen] = useState<boolean>(false)
  const isTopNav = isXLargeScreen

  const filtersByLight = {
    'light': 'invert(20%) sepia(0%) saturate(1000%) hue-rotate(205deg) brightness(97%) contrast(94%)',
    'dark': 'invert(93%) sepia(0%) saturate(0%) hue-rotate(205deg) brightness(97%) contrast(94%)',
  }

  const topNavFilterColor = filtersByLight[getThemeHeaderLight(selectedTheme)]
  const sideNavFilterColor = filtersByLight[getThemeSideNavLight(selectedTheme)]

  const onNavSelect = ({key}: MenuSelection) => {
    if (key.startsWith('drawer')) {
      setNavOpen(true)
    }else {
      navigate(key)
    }
  }

  const topNavMenuItems = [
    {
      label: (
        <FreqSignalsLogo style={{
          // filter here https://codepen.io/sosuke/pen/Pjoqqp
          // filter: 'invert(37%) sepia(97%) saturate(670%) hue-rotate(355deg) brightness(86%) contrast(86%)',
          filter: topNavFilterColor,
          marginTop: isTopNav ? '2px' : '6px',
          height: '50px',
          width: isTopNav ? '200px' : '140px',
        }}/>
      ),
      key: isTopNav ? '/' : 'drawerlogo',
    },
    ...(isTopNav ? [
      { key: '/signals', icon: <LineChartOutlined />, label: 'Signals' },
      // { label: 'Signal Providers', key: '/data_providing', icon: <DatabaseOutlined /> },
      // { label: 'Signals', key: '/forecast', icon: <LineChartOutlined /> },
      { key: '/workflows', icon: <BranchesOutlined />, label: 'Workflows' },
      { key: '/dashboards', icon: <DashboardOutlined />, label: 'Dashboards' },
    ]: [
      { key: 'drawer', icon: <EllipsisOutlined /> },
    ])

    // { label: 'FreqSignals', key: '/', icon: <HomeOutlined /> },


    // { key: '/pricing', label: 'Subscriptions' },

    // { label: 'Integrations', key: '/integrations', icon: <ApiOutlined /> },
    // { label: 'Bots', key: '/bots', icon: <RobotOutlined /> },
    // { label: 'Pricing', key: '/pricing', icon: <DollarOutlined /> },
    // { label: 'Pro', key: '/about', icon: <TeamOutlined /> },
    // { label: 'About', key: '/about', icon: <TeamOutlined /> },
  ];

  const drawerMenuItems = [
    { key: '/', icon: <HomeOutlined />, label: 'Home' },
    // { label: 'FreqSignals', key: '/', icon: <HomeOutlined /> },
    { key: '/signals', icon: <LineChartOutlined />, label: 'Signals' },
    // { label: 'Signal Providers', key: '/data_providing', icon: <DatabaseOutlined /> },
    // { label: 'Signals', key: '/forecast', icon: <LineChartOutlined /> },
    { key: '/workflows', icon: <BranchesOutlined />, label: 'Workflows' },
    { key: '/dashboards', icon: <DashboardOutlined />, label: 'Dashboards' },
    { key: '/store', icon: <ShoppingCartOutlined />, label: 'Store' },
    { key: '/integrations', icon: <ApiOutlined />, label: 'Integrations' },
    { key: '/account', icon: <SettingOutlined />, label: 'Account & Billing', disabled: !account },
    // { key: '/pricing', label: 'Subscriptions' },

  ];

  const onSelect = ({key}: MenuSelection) => {
    setNavOpen(false)
    navigate(key)
  }
  console.log("location", {location})

  return (
    <ConfigProvider
      theme={{
        token: {
          "borderRadius": 0
        }
      }}
    >
      <Menu items={topNavMenuItems} theme={getThemeHeaderLight(selectedTheme)} mode="horizontal" onSelect={onNavSelect} selectedKeys={[location.pathname]} className={'topnav'} />

      {/* <Menu items={items} theme={light ? 'light' : 'dark'} mode="horizontal" onSelect={onSelect} selectedKeys={[location.pathname]} /> */}
      {!isTopNav && (
        <>
        <Drawer
            title={
              <FreqSignalsLogo style={{
                filter: sideNavFilterColor,
                marginTop: '6px',
                height: '50px',
                width:'140px',
                }}
              />
            }
            placement={'left'}
            closable={false}
            className={`base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme} drawer-nav`}
            width={'80%'}
            onClose={() => setNavOpen(false)}
            open={navOpen}
            extra={
              <Space>
                  {/* <ApiStatus /> */}
                  <GlobalSearch includeTitle={false} onSelect={() => setNavOpen(false)}/>
              </Space>
            }
            footer={
              <Space>
                <AiChat />
                <Cart />
                <LightDarkSwitch mode='text-button' />
              </Space>
            }
          >

            <Menu items={drawerMenuItems} theme={getThemeSideNavLight(selectedTheme)} mode="vertical" onSelect={onSelect} selectedKeys={[location.pathname]} className={'drawer-nav-menu'} />
          </Drawer>
        </>

      )}
    </ConfigProvider>
  )
}

function RightNav() {
  const isBigScreen = useIsBigScreen()

  return <div style={{float: 'right'}}>
    <Space>
      {/* <ApiStatus /> */}
      {isBigScreen && (
        <ApiStatus />
      )}
      {isBigScreen && (
        <GlobalSearch />
      )}
      {isBigScreen && (
        <AiChat />
      )}
      {isBigScreen && (
        <Cart />
      )}
      <Login />
    </Space>

  </div>
}

function HeroLogo() {
  let navigate = useNavigate();

  return (
    <div className="logo" style={{margin: '12px', cursor: 'pointer'}} onClick={() => navigate('/')}>
      <h2>FreqSignals</h2>
      Subscribable Data
    </div>
  )
}

function TopNavHeroLogo() {
  let navigate = useNavigate();

  return (
    <div className="logo" style={{margin: '12px', cursor: 'pointer'}} onClick={() => navigate('/')}>
      <Icon
          component={FreqSignalsLogo}
          // className={classnames('mr-1', className)}
          style={{ fontSize: '8rem', filter: 'invert(37%) sepia(97%) saturate(670%) hue-rotate(355deg) brightness(86%) contrast(86%)', height: '24px', width: '200px' }}
      />
    </div>
  )
}

function AppHeader() {
  return (
    <>
      <RightNav/>
      <TopNav/>
    </>
  )
}

function PageTracker() {
  const location = useLocation();
  const account = useAppSelector(selectAccount);

  useEffect(() => {
    // console.log("location", {location})
    // ReactGA.pageview(location.pathname + location.search);
    ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  useEffect(() => {
    // console.log("location", {location})
    // ReactGA.pageview(location.pathname + location.search);
    if (account) {
      ReactGA.set({account_name: account.name, account_id: account.id});
    }
  }, [account?.id]);
  return <></>
}

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <></>
};

const getRoutes = () => {
  console.log("getRoutes")
  const routes = (
    <Routes>
      <Route path="" element={<Home />}/>
      <Route path="/" element={<Home />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/browse" element={<Browse />}/>
      <Route path="/subscriptions" element={<Subscriptions />}/>
      <Route path="/signals" element={<Signals />}/>
      <Route path="/data_providing" element={<DataProviding />}/>
      <Route path="/forecast" element={<Forecast />}/>
      <Route path="/request" element={<Request />}/>
      <Route path="/billing" element={<Billing />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/terms" element={<Terms />}/>
      <Route path="/workflows" element={<Workflows />}/>
      <Route path="/workflows">
        <Route path=":id" element={<Workflow />} />
      </Route>
      <Route path="/integrations" element={<Integrations />}/>
      <Route path="/pricing" element={<Store />}/>
      <Route path="/upgrade" element={<Store />}/>
      <Route path="/store" element={<Store />}/>
      <Route path="/bots" element={<Bots />}/>
      <Route path="/dashboards" element={<Dashboards />}/>
      <Route path="/dashboards">
        <Route path=":id" element={<Dashboard />} />
      </Route>
      <Route path="/data_sets">
        <Route path=":id" element={<DataSet />} />
      </Route>
      <Route path="/data_providers">
        <Route path=":id" element={<DataProvider />} />
      </Route>
      <Route path="/symbols">
        <Route path=":id" element={<Symbol />} />
      </Route>
      <Route path="/profile" element={<Profile />}/>
      <Route path="/account" element={<Account />}/>
      <Route path="/discord_bot" element={<DiscordBotContent />}/>
      <Route path="/twitter_bot" element={<TwitterBotContent />}/>
      <Route path="/telegram_bot" element={<TelegramBotContent />}/>
      <Route path="/email_bot" element={<EmailBotContent />}/>
      <Route path="/freqtrade_crypto_bot" element={<FreqtradeCryptoBotContent />}/>
      <Route path="/trading_view_webhook" element={<TradingViewWebhookContent />}/>
      <Route path="/on_demand_analysis" element={<OnDemandAnalysisContent />}/>
      <Route path="/analysis_tracking" element={<AnalysisTrackingContent />}/>
      <Route path="/custom_strategy" element={<CustomStrategyContent />}/>
      <Route path="/affiliate" element={<AffiliateContent />}/>
      <Route path="/advertising" element={<AdvertisingContent />}/>
      <Route path="/data_providing_1p_3p" element={<DataProvidingContent />}/>
      <Route path="/team" element={<TeamContent />}/>
      <Route path="/careers" element={<CareersContent />}/>
      <Route path="/investors" element={<InvestorsContent />}/>
      <Route path="/terms_and_conditions" element={<TermsConditionsContent />}/>
      <Route path="/privacy_policy" element={<PrivacyPolicyContent />}/>
      <Route path="/integration_offerings" element={<IntegrationOfferings />}/>
      <Route path="/data_provider_terms_and_conditions" element={<DataProviderTermsConditionsContent />}/>
      <Route path="/subscriber_terms_and_conditions" element={<SubscriberTermsConditionsContent />}/>
      <Route path="/community" element={<CommunityContent />}/>
      <Route path="/contact_us" element={<ContactUsContent />}/>
      <Route path="/enterprise" element={<EnterpriseContent />}/>
      <Route path="/documentation" element={<DocumentationContent />}/>
      
      
    </Routes>
  )
  return routes
}


function App() {
  const light = useAppSelector(selectLight)
  const selectedTheme = useAppSelector(selectTheme)
  const status = useAppSelector(selectStatus);
  const isBigScreen = useIsBigScreen()
  const isSmallScreen = useIsSmallScreen()

  const loggedIn = useAppSelector(selectLoggedIn);
  const loginAttempted = useAppSelector(selectLoginAttempted);

  const fontConfig = {
    "fontFamily": "'Roboto', proxima-nova, 'Noto Sans', 'Noto Sans JP', sans-serif"
  }

  const sizeSpaceConfig = {
    "borderRadius": 2
  }

  const commonColorConfig = {
    "colorPrimary": "#1765ad",
  }


  const configProviderConfig = {
    algorithm: light ? theme.defaultAlgorithm : theme.darkAlgorithm,
    token: {
      ...sizeSpaceConfig,
      ...commonColorConfig,
      ...fontConfig,
      ...getThemeConfig(selectedTheme),
    }
  }

  const content = () => {

    if (status === 'loading' || !loginAttempted) {
      return (
        <div className="App">
          <div className="App-content">
            <div className="App-page-content" style={{textAlign: 'center'}}>
                Loading...
            </div>
          </div>
        </div>
      ) 
    } else {
      return getRoutes()
    }

  }

  if (layout.type === 'Topnav') {
    return (

        <Router>
          <PageTracker />
          <ScrollToTop />
          <ConfigProvider
            theme={configProviderConfig}
          >
            <Layout className={`layout-${layout_type_class_names[layout.type]} base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme} screen-${isSmallScreen ? 'small' : 'big'}`}>
              <Header>
                <AppHeader />
              </Header>
              <Content>
                {content()}
              </Content>
              <Footer>
                <FooterContent />
              </Footer>
            </Layout>
          </ConfigProvider>
        </Router>
    );
  }

  return (
    <Router>
      <PageTracker />
      <ScrollToTop />
      <ConfigProvider
        theme={configProviderConfig}
      >
      <Layout className={`layout-${layout_type_class_names[layout.type]} base-theme-${light ? 'light' : 'dark'} theme-${selectedTheme}`}>
        <Sider>
          <HeroLogo />
          <SideNav/>
        </Sider>
        <Layout>
          <Header>
            <RightNav/>
          </Header>
          <Content>
            {content()}
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
      </ConfigProvider>
    </Router>
  
  );

}

// function Home() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         <p>
//           Home <code>src/App.tsx</code> and save to reload.
//           cool: {process.env.REACT_APP_NOT_SECRET_CODE}
//         </p>
//         <Counter/>
//       </header>
//     </div>
//   );
// }

function Profile() {
  return (
    <div className="App">
      <header className="App-header">
        <Typography.Paragraph>
          profile <code>src/App.tsx</code> and save to reload.
          cool: {process.env.REACT_APP_NOT_SECRET_CODE}
        </Typography.Paragraph>
      </header>
    </div>
  );
}

export default App;
