export interface MyAccount {
    id: string
    name: string
    data_provider_cap?: number
    data_set_cap?: number
    dashboard_cap?: number
    workflow_cap?: number
    daily_ai_chat_cap?: number
}

export interface Account {
    id: string
}

export interface DataProvider {
    id: string
    name: string
    account: Account
    account_id: string
    data_set_cap?: number
    data_set_count?: number
}

export interface DataSet {
    id: string
    name: string
    data_provider_id: string
    data_provider: DataProvider
    subscribed: boolean
    description?: string
    is_published: number
    is_public: number
    created_date: string
    forecast_cap?: number
    signal_cap?: number
}

export interface DataSetSubscptionMethod {
    data_set: DataSet
    data_set_id: string
    id: string
    enabled: number
    expired: number
    use_cap?: number
    expiration_date?: string
    subscription_expiration_days?: number
    subscription_expiration_date?: string
}

export interface ForecastContext {
    [key: string]: string | number | undefined
}


export interface Forecast {
    id: string
    symbol: string
    move: number
    minutes: number
    created_date: string
    updated_date: string
    data_set_id: string
    data_set: DataSet
    context: ForecastContext
}


export interface SignalContext {
    [key: string]: string | number | undefined
}


export interface Signal {
    id: string
    symbol: string
    value: number
    ttl_minutes: number
    created_date: string
    updated_date: string
    data_set_id: string
    data_set: DataSet
    context: SignalContext
}

export interface SignalHistory {
    v: number
    t: string
    c: SignalContext
}

export type IntegrationType = 'oauth2_api_token' | 'simple_api_token' | 'trading_view_webhook' | 'discord_webhook' | 'telegram_token'

export interface Integration {
    id: string
    name: string
    account_id: string
    created_date: string
    type: IntegrationType

}

// Workflow Types
export type WorkflowConfigDataSetSymbolConditionType = 'data_set_symbol'
export type WorkflowConfigDataSetSymbolArgsThresholdOperator = "eq" | "lt" | "lte" | "gt" | "gte"

export interface WorkflowConfigDataSetSymbolArgsThreshold {
    operator: WorkflowConfigDataSetSymbolArgsThresholdOperator
    value: number
}

export interface WorkflowConfigDataSetSymbolArgs {
    symbol?: string
    data_set_id?: string
    thresholds: WorkflowConfigDataSetSymbolArgsThreshold[]
}

export interface WorkflowConfigCondition {
    condition_type: WorkflowConfigDataSetSymbolConditionType
    args: WorkflowConfigDataSetSymbolArgs
}

export type WorkflowConfigDiscordActionType = 'discord_webhook_send_message'
export type WorkflowConfigTelegramActionType = 'telegram_send_message'
export type WorkflowConfigActionTypes = WorkflowConfigDiscordActionType | WorkflowConfigTelegramActionType

export interface WorkflowConfigDiscordActionArgs {
    integration_id?: string
    message: string
    username?: string
}

export interface WorkflowConfigTelegramActionArgs {
    integration_id: string
    message: string
    chat_id: string
}

export interface WorkflowConfigDiscordAction {
    action_type: WorkflowConfigDiscordActionType
    args: WorkflowConfigDiscordActionArgs
}

export interface WorkflowConfigTelegramAction {
    action_type: WorkflowConfigTelegramActionType
    args: WorkflowConfigTelegramActionArgs
}
// discord_webhook_send_message

export type WorkflowConfigAction  = WorkflowConfigDiscordAction | WorkflowConfigTelegramAction

export interface WorkflowConfig {
    conditions: WorkflowConfigCondition[]
    actions: WorkflowConfigAction[]
}

export interface Workflow {
    id: string
    account_id: string
    name: string
    created_date: string
    config: WorkflowConfig
    enabled: number
}

export interface WorkflowExecution {
    id: string
    workflow_id: string
    created_date: string
}

export interface WidgetLayout {
    // width?: string
    x: number
    y: number
    w: number
    h: number
}

// export type WidgetType = "signal_stream"

export interface SignalQueryParams {
    context_filter?: string
}
export type DateFilter = '30_days' | '7_days' | 'today'




export interface BlankWidgetArgs {
    data_set_id?: string
    show_field_as_value?: string
    content?: string
    default_date_filter?: DateFilter
    query_params?: SignalQueryParams
    ordering?: string
    add_buttons?: boolean
    add_context_columns?: boolean
    inverse_bull_bear?: boolean
    limit?: number
}


export interface BlankWidgetConfig {
    widget_type: string
    widget_args: BlankWidgetArgs
}

export interface SignalStreamWidgetArgs {
    data_set_id?: string
    ordering?: string
    show_field_as_value?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    add_buttons?: boolean
    inverse_bull_bear?: boolean
    limit?: number
}

export interface SignalStreamWidgetConfig {
    widget_type: "signal_stream"
    widget_args: SignalStreamWidgetArgs
}

export interface SignalTableWidgetArgs {
    data_set_id?: string
    ordering?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    add_buttons?: boolean
    add_context_columns?: boolean
    limit?: number
}

export interface SignalTableWidgetConfig {
    widget_type: "signal_table"
    widget_args: SignalTableWidgetArgs
}

export interface TextWidgetArgs {
    content?: string
}

export interface TextWidgetConfig {
    widget_type: "text"
    widget_args: TextWidgetArgs
}

export interface DynamicTextWidgetArgs {
    data_set_id?: string
    ordering?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    content?: string
    limit?: number
}

export interface DynamicTextWidgetConfig {
    widget_type: "dynamic_text"
    widget_args: DynamicTextWidgetArgs
}


export interface Widget {
    id: string
    name: string
    layout: WidgetLayout
    widget_config: SignalStreamWidgetConfig | SignalTableWidgetConfig | TextWidgetConfig | DynamicTextWidgetConfig | BlankWidgetConfig
    // TweetWidget SocialMediaPost? AI to generate content? Premium for that
}


export interface DashboardConfig {
    widgets: Widget[]
}


export interface Dashboard {
    id: string
    name: string
    config: DashboardConfig
}

// export type SubscriptionPlan = 'basic' | 'pro' | 'elite'
export type SubscriptionRecurring = 'month' | 'year' | 'lifetime'
// export type SubscriptionId = 'basic' | 'pro_month' | 'pro_year' | 'elite_month' | 'elite_year' | 'pro_one_time' | 'elite_one_time'

export interface AccountBillingMethod {
    account_id: string
    billing_method: string
    // subscription_id: SubscriptionId
    status: string
    // expiration_date: string
}


export interface StoreItem {
    id: string
    name: string
    description: string
    lifetime_price_cents: number | null
    month_price_cents: number | null
    year_price_cents: number | null
    multiple_disallowed: number
    available: number
}

export interface StoreCart {
    id: string
    status: string
    created_date: string
}

export interface StoreCartItem {
    id: string
    item_id: string
    item: StoreItem
    cart_id: string
    quantity: number
    recurring: SubscriptionRecurring
    created_date: string
}

export interface AccountItemPurchase {
    id: string
    item_id: string
    item: StoreItem
    status: string
    quantity: number
    expiration_date: string | null
    created_date: string
}

export interface FeatureFlag {
    id: string
    code_name: string
    description: string
}


export const themesArr = ['light', 'dark', 'pro', 'purple' , 'green', 'catppuccin-mocha', 'catppuccin-latte', 'alt-light', 'alt-dark', 'dracula', 'nord-light', 'nord-dark'] as const;
export type Theme = typeof themesArr[number];
