import React, { useState } from 'react';
import { DataSet as DataSetType, Dashboard as DashboardType } from '../../types/types'
import { getDataSet, getDataSetDashboards } from '../../services/signalApi'
import ReactMarkdown from 'react-markdown'
import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { Descriptions, Typography, Empty, Spin, Alert, Button, message, Space, Row, Statistic, Tag, Col, Tabs } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';
import ReactQuill from 'react-quill';
import { useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
import { DataProviderLink } from '../../components/DataProviderLink'
import { PageHeader } from '../../components/PageHeader'
import { SignalList } from '../../components/SignalList'
import { Loading } from '../../components/Loading'
import { CreateSignalModal } from '../../components/CreateSignalModal'
import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';
import { DataSetSubscribeModal } from '../../components/DataSetSubscribeModal'
import { CopyToClipboard } from '../../components/CopyToClipboard'
import { DataSetEditModal } from '../../components/DataSetEditModal'
import { SignalSelectableBrowser } from '../../components/SignalSelectableBrowser'
import { Dashboard as DashboardComponent } from '../../components/Dashboard';

import { SpaceContext } from 'antd/lib/space';
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
// import Editor from "rich-markdown-editor";

// function MyEditor() {
//   const [editorState, setEditorState] = React.useState(
//     () => EditorState.createEmpty(),
//   );

//   return (
//     <>
//       Editor:
//       <Button onClick={() => {}}>Bold</Button>
//       <Editor editorState={editorState} onChange={setEditorState} />
//     </>
//   )
// }


export function DataSet() {
  const params = useParams()
  const account = useAppSelector(selectAccount)

  const [inFlight, setInFlight] = useState(false);
  const [attemptedLoad, setAttemptedLoad] = useState(false);
  const [showCreateSignalModal, setShowCreateSignalModal] = useState(false);
  const [showEditDataSetModal, setShowEditDataSetModal] = useState(false);
  const [dataset, setDataset] = useState<DataSetType | null>(null);
  const [reloads, setReloads] = useState(0);
  const [subscribingDataSet, setSubscribingDataSet] = useState<DataSetType | null>(null);
  const [dashboards, setDashboards] = useState<DashboardType[] | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (dataset === null) {
      document.title = 'FreqSignals | Data Set'
      document.getElementsByName("twitter:title")[0].setAttribute('content', `FreqSignals Data Set`);
      document.getElementsByName("twitter:description")[0].setAttribute('content', `FreqSignals Data Set`);
    } else {
      document.title = `FreqSignals | Data Set: ${dataset.name}`
      document.getElementsByName("twitter:title")[0].setAttribute('content', `FreqSignals Data Set: ${dataset.name}`);
      document.getElementsByName("twitter:description")[0].setAttribute('content', dataset.description ? dataset.description : `FreqSignals Data Set`);
    }
  }, [dataset])

  const setTab = () => {
    const query = new URLSearchParams(window.location.search);
    const urlTab = query.get('tab')
    if (urlTab) {
      setActiveTab(urlTab)
    }
  }

  useEffect(() => {
      setTab()
  }, [window.location.search]);

  useEffect(() => {
    if (activeTab) {
      const query = new URLSearchParams(window.location.search);
      const urlTab = query.get('tab')
      if (urlTab !== activeTab) {
        setSearchParams({tab: activeTab})
      }
    }
  }, [searchParams, activeTab])

  const id = params.id;

  if (!id) {
    return <Typography.Paragraph>No ID</Typography.Paragraph>
  }

  const loadDataSet = () => {
      setInFlight(true)
      getDataSet(id).then((res) => {
          setInFlight(false)
          setAttemptedLoad(true)
          console.log("dataset", {res})
          if (res.status === 200) {
            const response_data = res.data
            setDataset(response_data)
          } else {
            setDataset(null)

          }
      }
      ).catch(() => {
            setDataset(null)
      }).finally(() => {
            setInFlight(false)
      }).then(() => getDataSetDashboards({data_set_id: id, include_public: 1})).then((res) => {
        console.log(res)
        if (res.status === 200) {
          const results = res.data.results
          if (results) {
            setDashboards(results)
            setTab()
          }
        }
      })
  }

  if (dataset === null && !inFlight && !attemptedLoad) {
    console.log("triggering")
    loadDataSet()
  }

  const handleCreateSignal = () => {
    setShowCreateSignalModal(false)
    setReloads(reloads + 1)
    // forceUpdate()

  }
  const handleCreateSignalCancel = () => {
    setShowCreateSignalModal(false)
  }

  const showSubscribeModal = (subscribeDataSet: DataSetType) => {
    // setIsSubscribeModalVisible(true);
    console.log("showSubscribeModal dataset", {dataset})
    setSubscribingDataSet(dataset);
  }

  const handleCancel = () => {
    setSubscribingDataSet(null);
  }

  const handleSubscribeComplete = () => {
    setSubscribingDataSet(null);
    loadDataSet()
  }

  
  if (id === undefined || dataset === undefined || dataset === null) {
    return (
      <div className="App">
        <div className="App-content">
          {attemptedLoad && (
            <Typography.Paragraph>Data Set Not Found. Maybe you need to log in.</Typography.Paragraph>
          )}
          {!attemptedLoad && (
            <Loading message='Data Set' description='Loading Data Set' />

          )}
        </div>
      </div>
    );
  }

  console.log("dataset", {dataset})

  const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
  const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
  const blocked = <Tooltip title="Awaiting Data"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
  const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

  const tradingViewDetail = (
    <>
      <Typography.Title level={2}>Trading View Config</Typography.Title>
      Webhook URL: <pre style={{border: '1px solid #555', padding: '10px'}}>https://api.freqsignals.com/api/async/signals/?token=INTERGRATION_TOKEN</pre>
      Example Message:
      <pre style={{border: '1px solid #555', padding: '10px'}}>{JSON.stringify({"symbol": "{{exchange}}:{{ticker}}", "value": 0.2, "ttl_minutes": 40, "data_set_id": id, "price": "{{close}}", "volume": "{{volume}}"}, null, 2)}</pre>
    </>
  )

  const ownedDataSet = (account && dataset.data_provider.account_id === account.id)

  const right = () => {
    return (
      <>
        {ownedDataSet && (
          <>
            <Button type="default" onClick={() => setShowEditDataSetModal(true)}>Edit Data Set</Button>
            <Button type="default" onClick={() => setShowCreateSignalModal(true)}>Post Signal</Button>
          </>
        )}

        {account && dataset.subscribed && (
          <Button key="1" type="default" onClick={(e) => showSubscribeModal(dataset)}>
            Unubscribe
          </Button>
        )}

        {account && !dataset.subscribed && (
          <Button key="1" type="primary" onClick={(e) => showSubscribeModal(dataset)}>
          Subscribe
        </Button>
        )}

        {!account && (
          <Tooltip placement='left' title="Log in to subscribe">
            <Button key="1" type="primary" disabled={true}>
              Subscribe
            </Button>
          </Tooltip>
        )}
      </>
    )
  }

  const sub = () => {
    return (
      <>
        {dataset.is_public === 1 && (<Tag color="blue">Public</Tag>)}
        {dataset.is_public !== 1 && (<Tag color="purple">Private</Tag>)}
        {dataset.is_published !== 1 && (<Tag color="yellow">Unpublished</Tag>)}
        {ownedDataSet && (<Tag color="black">Mine</Tag>)}
      </>
    )
  }

  const dataSetSignals = () => {
    return (
      <>
        <div className={showCreateSignalModal ? 'has-new' : 'no-new'}>
          <SignalSelectableBrowser showButtons={true} data_set_id={dataset.id} queryParams={{...(dataset.is_public ? {include_public: true} : {})}} addContextColumns={true} key={reloads}/>
        {/* <SignalList showButtons={true} data_set_id={dataset.id} queryParams={{...(dataset.is_public ? {include_public: true} : {})}} addContextColumns={true} key={reloads}/> */}

        </div>
      </>
    )

  }

  const dataSetDescription = () => {
    return (
      <>
        {(dataset.description && (
          <Typography.Paragraph>
            <ReactMarkdown>{dataset.description}</ReactMarkdown>
          </Typography.Paragraph>
        ))}
        {(!dataset.description && (
          <Typography.Paragraph>No Description</Typography.Paragraph>
        ))}
      </>
    )

  }

  const dataSetDashboard = (dashboard: DashboardType) => {
    return <DashboardComponent id={dashboard.id} set_dashboard={dashboard} can_edit={false} />
  }

  return (
    <div className="App">
      <div className="App-content">
        {/* Data Set: {dataset.name} */}
        <div className="App-page-content">

            <PageHeader
              title={dataset.name}
              right={right()}
              sub={sub()}
            />

            <Descriptions>
                <Descriptions.Item label="Id">
                  <Typography.Text code>{id}</Typography.Text>
                  <CopyToClipboard text={id} />
                </Descriptions.Item>
                {/* <Descriptions.Item label="Name">{dataset.name}</Descriptions.Item> */}
                {dataset.data_provider && (
                  <Descriptions.Item label="Provider"><DataProviderLink data_provider={dataset.data_provider} /></Descriptions.Item>
                )}
                {/* <Descriptions.Item label="Published">{dataset.is_published ? 'Yes' : 'No'}</Descriptions.Item> */}
                {/* <Descriptions.Item label="Public">{dataset.is_public ? 'Yes' : 'No'}</Descriptions.Item> */}
                {/* {account && (
                  <>
                    <Descriptions.Item label="Owned By Me">{ownedDataSet ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label="Subscribed">
                      <a onClick={(e) => showSubscribeModal(dataset)}>{dataset.subscribed ? 'Yes' : 'No'}</a>
                    </Descriptions.Item>
                  </>

                )} */}

                {/* <Descriptions.Item label="Number of signals">300k</Descriptions.Item> */}
                {/* <Descriptions.Item label="Subscribers">78</Descriptions.Item> */}
                {/* <Descriptions.Item label="Quality">A+</Descriptions.Item> */}
            </Descriptions>

            <Tabs
                activeKey={activeTab ? activeTab : "signals"}
                onTabClick={(key) => setActiveTab(key)}
                defaultActiveKey={"signals"}
                // onChange={onChange}
                items={[
                {
                    label: `Signals`,
                    key: 'signals',
                    children: dataSetSignals(),
                },
                {
                    label: `Description`,
                    key: 'description',
                    children: dataSetDescription(),
                },
                ...(dashboards ? dashboards.map((dashboard) => ({
                  label: `Dashboard - ${dashboard.name}`,
                  key: dashboard.id,
                  children: dataSetDashboard(dashboard),
                })) : [])
                // {
                //     label: `Dashboard`,
                //     key: 'dashboard',
                //     children: dataSetDescription(),
                // },

                ]}
            />




            <CreateSignalModal isOpen={showCreateSignalModal} onCancel={handleCreateSignalCancel} onCreate={handleCreateSignal} dataset={dataset} />
            <DataSetEditModal isOpen={showEditDataSetModal} onCancel={() => {setShowEditDataSetModal(false)}} onSuccess={() => {setShowEditDataSetModal(false); loadDataSet()}} dataset={dataset} />
            {/* <h2>Editor:</h2>
            <MyEditor /> */}
            {dataset && (
                <DataSetSubscribeModal subscribingDataSet={dataset} isOpen={subscribingDataSet !== null} onCancel={handleCancel} onCreate={handleSubscribeComplete} />
            )}
            {/* {tradingViewDetail} */}
        </div>
      </div>
    </div>
  );
}
