import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
// import { authenticate_username } from './loginAPI';
import { getStoreCarts, getStoreCartItems, addToStoreCart, createStoreCartWithItem, updateStoreCartItem, deleteStoreCartItem } from '../../services/signalApi';
import { Account, MyAccount, AccountBillingMethod, StoreItem, StoreCartItem, StoreCart, SubscriptionRecurring } from '../../types/types';
import ReactGA from "react-ga4";
import { message  } from 'antd';
import { objIsRootState } from '../../utils'


// type Level = 'success' | 'info' | 'error' | 'warning'


export interface CartState {
    open: boolean
    cart_id?: string
    cart_items?: StoreCartItem[]
    local_cart_items?: StoreCartItem[]
}

export interface AddToCartAction {
    item: StoreItem,
    recurring?: SubscriptionRecurring
    quantity?: number
}


const initialState: CartState = {open: false};

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const addToCart = createAsyncThunk(
  'cart/add',
  async (userData: AddToCartAction, thunkApi) => {
    const state = thunkApi.getState()
    const local_cart_items: StoreCartItem[] = []
    let cart_id: string | undefined = undefined
    if (objIsRootState(state)) {
        console.log("addToCart state", {state, userData})
        trackGaAddToCart(userData)
        if (!state.login.account?.id) {
            cart_id = '1'
            local_cart_items.push({
                id: userData.item.id,
                item_id: userData.item.id,
                item: userData.item,
                cart_id: cart_id,
                quantity: userData.quantity || 1,
                recurring: userData.recurring || 'month',
                created_date: '2023-03-20'
            })
            return {
                cart_id, local_cart_items
            }
        } else {
            let createFn = null
            // Check if cart exists

            // If no cart, see if cart exists, if not, create cart
            let use_cart_id = state.cart.cart_id
            if (!use_cart_id){
                console.log("cart", {state})
                const looked_up_cart_id = await getStoreCarts({'status!': 'paid'}).then((res) => {
                    console.log("addToCart getStoreCarts", {res})
                    if (res.status === 200) {
                        if (res.data.results && res.data.results.length > 0) {
                            return res.data.results[0].id
                        }
                    } else {
                        message.error(res.data.toString())
                    }
                    return null
                })
                console.log("looked_up_cart_id", {looked_up_cart_id})

                if (looked_up_cart_id) {
                    use_cart_id = looked_up_cart_id
                }
            }
            console.log("use_cart_id", {use_cart_id})
            if (use_cart_id){
                cart_id = use_cart_id

                createFn = addToStoreCart(use_cart_id, userData.item.id, userData.recurring || 'month', userData.quantity || 1).then((res) => {
                    console.log("addToStoreCart", {res})
                    if (res.status === 400) {
                        message.error(res.data)
                    }
                }).catch((e) => {
                    console.log("err", {e})
                })
                // Add to cart
            } else {
                console.log("no cart", {state})
                // create cart
                createFn = createStoreCartWithItem(state.login.account.id, userData.item.id, userData.recurring || 'month', userData.quantity || 1).then((res) => {
                    console.log("createStoreCartWithItem", {res})
                    cart_id = res.data.id
                })
    
            }
            await createFn
            let cart_items = cart_id ? await getStoreCartItems(cart_id).then((res) => {
                return res.data.results
            }) : []
            // const cart_items = await getStoreCartItems(cart_id).then((res) => {
            //     return res.data.results
            // })
            return {
                cart_id, cart_items: (cart_items || [])
            }
        }

        


        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart_item
        // ReactGA.event(
        //     'add_to_cart',
        //     {
        //         currency: 'USD',
        //         value: (userData.item.month_price_cents || 0 / 100).toFixed(2),
        //         items: [{
        //             item_id: userData.item.id,
        //             item_name: userData.item.name,
        //             price: (userData.item.month_price_cents || 0 / 100).toFixed(2),
        //             quantity: 1,
        //         }]
        //     }
        // );

    }
    return {
        cart_id, local_cart_items
    }
    

    
  }
);



export const initCart = createAsyncThunk(
    'cart/init',
    async (_, thunkApi) => {
        const state = thunkApi.getState()
        const local_cart_items: StoreCartItem[] = []
        let cart_id: string | undefined = undefined
        if (objIsRootState(state)) {
            if (!state.cart.cart_id) {
                if (!state.login.account?.id) {
                    // Attempt Load of Cart from Local Storage
                } else {
                    const looked_up_cart_id = await getStoreCarts({'status!': 'paid'}).then((res) => {
                        console.log("initCart getStoreCarts", {res})
                        if (res.status === 200 && res.data.results) {
                            return res.data.results[0].id
                        }
                        return null
                    })
                    let cart_items: StoreCartItem[] | undefined = []
                    if (looked_up_cart_id) {
                        cart_items = await getStoreCartItems(looked_up_cart_id).then((res) => {
                            return res.data.results
                        })
                    }
                    return {cart_id: looked_up_cart_id, cart_items: cart_items || []}

                }
            }
            return {cart_id: null, cart_items: null}
        }
    }
);
  
export const refreshCart = createAsyncThunk(
    'cart/refresh',
    async (_, thunkApi) => {
        const state = thunkApi.getState()
        if (objIsRootState(state)) {
            if (state.cart.cart_id) {
                const cart_items = await getStoreCartItems(state.cart.cart_id).then((res) => {
                    return res.data.results
                })
                return {cart_items: cart_items || []}
            }
        }
        return {}
    }
);



const trackGaAddToCart = (userData: AddToCartAction) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart_item
    console.log("trackGaAddToCart", {userData})
    ReactGA.event(
        'add_to_cart',
        {
            currency: 'USD',
            value: (userData.item.month_price_cents || 0 / 100).toFixed(2),
            items: [{
                item_id: userData.item.id,
                item_name: userData.item.name,
                price: (userData.item.month_price_cents || 0 / 100).toFixed(2),
                quantity: 1,
            }]
        }
    )
}

const trackGaViewCart = (state: CartState) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_cart
    console.log("trackGaViewCart", {state})
    ReactGA.event(
        'view_cart',
        {
            currency: 'USD',
            value: ((state.local_cart_items || []).reduce((agg, cartItem) => (agg + (cartItem.item.month_price_cents || 0)), 0) / 100).toFixed(2),
            items: (state.local_cart_items || []).map((cartItem) => ({
                item_id: cartItem.item.id,
                item_name: cartItem.item.name,
                price: (cartItem.item.month_price_cents || 0 / 100).toFixed(2),
                quantity: cartItem.quantity,
            }))
        }
    );
}


export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    openCart: (state) => {
        
        state.open = true
        trackGaViewCart(state)
        // ReactGA.event(
        //     'view_cart',
        //     {
        //         currency: 'USD',
        //         value: ((state.local_cart_items || []).reduce((agg, cartItem) => (agg + (cartItem.item.month_price_cents || 0)), 0) / 100).toFixed(2),
        //         items: (state.local_cart_items || []).map((cartItem) => ({
        //             item_id: cartItem.item.id,
        //             item_name: cartItem.item.name,
        //             price: (cartItem.item.month_price_cents || 0 / 100).toFixed(2),
        //             quantity: cartItem.quantity,
        //         }))
        //     }
        // );

    },
    closeCart: (state) => {
        state.open = false
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        console.log("addToCart")
        state.cart_id = action.payload.cart_id
        state.local_cart_items = action.payload.cart_items ? action.payload.cart_items : [...state.local_cart_items || [], ...action.payload.local_cart_items]
        state.open = true
      })
      .addCase(initCart.fulfilled, (state, action) => {
        console.log("initCart")
        if (action.payload?.cart_id) {
            state.cart_id = action.payload.cart_id
        }
        if (action.payload?.cart_items) {
            state.local_cart_items = action.payload.cart_items
        }
      })
      .addCase(refreshCart.fulfilled, (state, action) => {
        console.log("refreshCart")
        if (action.payload?.cart_items) {
            state.local_cart_items = action.payload.cart_items
        }
      })
      
      

  },
});

export const { openCart, closeCart } = cartSlice.actions;

export const selectCartId = (state: RootState) => state.cart.cart_id;
export const selectCartItems = (state: RootState) => state.cart.local_cart_items;
export const selectCartOpen = (state: RootState) => state.cart.open;


export default cartSlice.reducer;
