import { useEffect } from "react";
import { Tooltip, Typography } from 'antd';

import { WorkflowList } from '../../components/WorkflowList';
import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'

export function Workflows() {
    useEffect(() => {document.title = 'FreqSignals | Workflow'}, [])

    const account = useAppSelector(selectAccount);
    console.log("account", {account})


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Workflows</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>Notify yourself or your community when certain Signals are seen. Like Zapier or IFTTT, but for Signals.</Typography.Paragraph>
                    {!account && (
                        <>
                            <Typography.Paragraph>Log In to manage Workflows. Workflows allow you notify your integrations, like Discord or Telegram, when you see certain patterns.</Typography.Paragraph>
                        </>
                    )}
                    {account && (
                        <WorkflowList />
                    )}
                </div>
            </div>
        </div>
    );
}
