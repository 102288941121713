import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector, useIsBigScreen } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

import { CheckOutlined, DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, List, Space, Tooltip, Typography, Badge, Alert } from 'antd';
import { Link } from "react-router-dom";

import { AccountBillingMethod, StoreCartItem } from '../../types/types';
import { deleteStoreCartItem, updateStoreCartItem, getAccountBillingMethod, getStripeCheckout } from '../../services/signalApi';
import { effectiveMonthlyUnitPrice, effectiveYearUnitPrice, cartItemUnitPrice, cartItemTotalPrice, cartTotalPrice } from '../../utils'

import {
    closeCart, initCart, openCart, selectCartId,
    selectCartItems,
    selectCartOpen,
    refreshCart,
} from './cartSlice';

interface QuantityUpdate {
    cart_item_id: string
    new_quantity: number
}

export function Cart() {

    const dispatch = useAppDispatch();
    const account = useAppSelector(selectAccount);
    const open = useAppSelector(selectCartOpen);
    const cartId = useAppSelector(selectCartId);
    const cartItems = useAppSelector(selectCartItems);
    const [quantityUpdates, setQuantityUpdates] = useState<QuantityUpdate[]>([])
    const [showBillingMethod, setShowBillingMethod] = useState<boolean>(false)
    const [billingMethod, setBillingMethod] = useState<AccountBillingMethod | null>(null)
    const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null)
    const isBigScreen = useIsBigScreen()


    const showDrawer = () => {
        dispatch(openCart());
    };
    
    const closeDrawer = () => {
        setShowBillingMethod(false)
        dispatch(closeCart());
    };

    useEffect(() => {
        dispatch(initCart())
    }, [account?.id])

    useEffect(() => {
        if (account && checkoutUrl && billingMethod === null) {
            getAccountBillingMethod(account.id).then((res) => {
                console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results) {
                        const accountBilling = response_data.results[0]
                        setBillingMethod(accountBilling)
                    }
                }
            })
        }
    }, [checkoutUrl, account?.id])

    useEffect(() => {
        if (showBillingMethod) {
            if (account && cartId) {

                getAccountBillingMethod(account.id).then((res) => {
                    console.log("getAccountBillingMethod", {res})
                    if (res.status === 200) {
                        const response_data = res.data
                        if (response_data.results) {
                            const accountBilling = response_data.results[0]
                            setBillingMethod(accountBilling)
                        }
                    }
                }).then(() => getStripeCheckout(cartId)).then((res) => {
                    console.log("getStripeCheckout", {res})
                    if (res.status === 200) {
                        const response_data = res.data
                        if (response_data.url) {
                            setCheckoutUrl(response_data.url)
                        }
                    }
                })
            }
        } else {
            setCheckoutUrl(null)
        }
    }, [showBillingMethod])

    const deleteCartItem = (cartItem: StoreCartItem) => {
        deleteStoreCartItem(cartItem.id).then(() => {
            dispatch(refreshCart())
            setCheckoutUrl(null)
            setShowBillingMethod(false)
        })
    }

    const updateCartItemQuantity = (cartItem: StoreCartItem) => {
        const existingUpdates = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (existingUpdates.length > 0) {
            updateStoreCartItem(cartItem.id, {quantity: existingUpdates[0].new_quantity}).then(() => {
                const newQuantityUpdates = [...quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id !== cartItem.id)]
                setQuantityUpdates(newQuantityUpdates)
                dispatch(refreshCart())
                setCheckoutUrl(null)
                setShowBillingMethod(false)
            })
        }
        
    }

    const handleQuantitySelectionChange = (cartItem: StoreCartItem, quantity: number | undefined | null) => {
        // const existingUpdate = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (quantity) {
            const newQuantityUpdates = [...quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id !== cartItem.id), {cart_item_id: cartItem.id, new_quantity: quantity}]
            setQuantityUpdates(newQuantityUpdates)
        }

    }

    const quantitySelectionChanged = (cartItem: StoreCartItem) => {
        const existingUpdates = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (existingUpdates.length === 0){
            return false
        }
        if (existingUpdates[0].new_quantity !== cartItem.quantity) {
            return true
        }
        return false

    }

    const itemList = (cartItems: StoreCartItem[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="store-cart-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={cartItems === null ? [] : cartItems}
            renderItem={(cartItem: StoreCartItem) => (
                <List.Item
                    key={cartItem.id}
                    // onClick={() => {showSignalDetails(signal)}}
                    actions={[
                        ...(cartItem.item.multiple_disallowed === 1 ? [] : [
                            <Space>
                                <Input key={cartItem.quantity} type='number' defaultValue={cartItem.quantity} style={{width: '80px'}} onChange={(e) => {handleQuantitySelectionChange(cartItem, parseInt(e.target.value, 10))}} />
                                <Tooltip placement='right' title="Update Quantity">
                                    <Button disabled={!quantitySelectionChanged(cartItem)} icon={<CheckOutlined />} onClick={() => {updateCartItemQuantity(cartItem)}}/>

                                </Tooltip>
                            </Space>
                        ])
                        
                    ]}
                    extra={
                        <Tooltip placement='bottom' title="Remove from Cart">
                            <Button icon={<DeleteOutlined />} onClick={() => {deleteCartItem(cartItem)}}/>

                        </Tooltip>
                        // <span>${(cartItem.quantity * (cartItem.item.month_price_cents || 0) / 100).toFixed(2)}</span>
                    }
                >
                    <List.Item.Meta
                        key={cartItem.id}
                        title={<span>{cartItem.item.name}</span>}
                        description={
                            <>
                                <Typography.Paragraph>{cartItem.quantity} x ${cartItemUnitPrice(cartItem)} = ${cartItemTotalPrice(cartItem)} / {cartItem.recurring}</Typography.Paragraph>
                                <Typography.Paragraph>{cartItem.item.description}</Typography.Paragraph>
                            </>
                        }
                    />

                </List.Item>
            )}
        />
    }

    const cartTotal = (cartItems: StoreCartItem[]) => {
        return <Typography.Paragraph style={{textAlign: 'right'}}>
            Total: ${cartTotalPrice(cartItems)} / {cartItems[0]?.recurring}
        </Typography.Paragraph>
    }

    const onCheckoutClick = () => {
        setShowBillingMethod(true)
    }

    const cantCheckoutReason = () => {
        const allYearRecurring = (cartItems || []).filter((cartItem) => cartItem.recurring === 'year')
        const allMonthRecurring = (cartItems || []).filter((cartItem) => cartItem.recurring === 'month')
        if (allYearRecurring.length > 0 && allMonthRecurring.length > 0) {
            return "Can not mix subscription durations in a single checkout. Please remove either the month or year subscriptions, you may add them in a separate checkout."
        }
        return null
    }

    return <>
        {/* <span onClick={showDrawer}>Cart</span> */}
        {/* {!open && ( */}
        <Button type="text" icon={<ShoppingCartOutlined />} onClick={showDrawer}>
            Cart{cartItems && cartItems.length > 0 ? ` (${cartItems.length})` : ''}
        </Button>
        {/* {cartItems && cartItems.length > 0 ? (
            <Badge count={cartItems.length} offset={[-22, 4]} size='small'>
                <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)', marginRight: '12px'}} icon={<ShoppingCartOutlined />} onClick={showDrawer}>
                    Cart ({cartItems.length})
                </Button>
            </Badge>
        ) : (
            
        )} */}

            {/* <Badge count={cartItems && cartItems.length > 0 ? cartItems.length : }>
                <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)'}} icon={<ShoppingCartOutlined />} onClick={showDrawer}>
                    Cart
                </Button>
            </Badge> */}

        {/* )} */}
        
        <Drawer title="Cart" placement="right" onClose={closeDrawer} open={open} mask={false}>
            {(!cartId || (cartItems || []).length === 0) && (
                <Typography.Paragraph>{'There is nothing in your cart.'}</Typography.Paragraph>
            )}
            <Typography.Paragraph style={{textAlign: 'right'}}>
            {/* <Link to={`/store`}><Button type={"text"} onClick={closeDrawer}>Go to the Store</Button></Link> */}
            <Link to={`/store`} onClick={closeDrawer}>Go to the Store</Link>
            </Typography.Paragraph>
            {(cartItems || []).length > 0 && (
                <Space direction='vertical' style={{width: '100%'}}>
                    {cartTotal(cartItems || [])}
                    {itemList(cartItems || [])}
                    {cartTotal(cartItems || [])}
                    {cantCheckoutReason() && (
                        <Alert message={cantCheckoutReason()} type="error" />
                    )}
                    {!showBillingMethod && (
                        <Button style={{width: '100%'}} size='large' disabled={cantCheckoutReason() !== null} onClick={() => {onCheckoutClick()}}>Proceed to Checkout</Button>
                    )}
                    {(checkoutUrl || (showBillingMethod && billingMethod?.billing_method === 'stripe')) && (
                        <>
                            <Typography.Paragraph style={{fontSize: '0.75em'}}>I authorize FreqSignals to charge me automatically until I cancel my subscription. I acknowledge that refunds are made according to the <a href='/terms_and_conditions' target='blank'>Terms of Use</a>. The subscription can be canceled at any time from the Billing section in the Account settings. I have read, and agree to, FreqSignals's <a href='/terms_and_conditions' target='blank'>Terms of Use</a> and <a href='/privacy_policy' target='blank'>Privacy Policy</a>.</Typography.Paragraph>
                            <Button style={{width: '100%'}} size='large' {...(checkoutUrl === null ? {disabled: true} : {href: checkoutUrl})} target='blank'>Pay with Stripe</Button>
                        </>

                    )}

                </Space>
            )}
        </Drawer>
    </>
    // return <>Cart</>
}
