import type { InputRef } from 'antd';
import { Button, Empty, Form, Input, Modal, Space, Typography } from 'antd';
import { Link } from "react-router-dom";

import { SearchOutlined } from '@ant-design/icons';
import { getDataSets, getSignals } from '../services/signalApi';
import { DataSet as DataSetType, Signal as SignalType } from '../types/types';
import { Loading } from './Loading';

import { useRef, useState } from 'react';
const { Search } = Input;
interface GlobalSearchParams {
    includeTitle? : boolean
    onSelect? : () => void
}

export function GlobalSearch({
    includeTitle=false,
    onSelect
}: GlobalSearchParams) {

    // const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);
    const inputRef = useRef<InputRef>(null);
    const [form] = Form.useForm();
    const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSetResults, setDataSetResults] = useState<DataSetType[] | null>(null);
    const [signalResults, setSignalResults] = useState<SignalType[] | null>(null);

    const showSearch = () => {
        setIsSearchModalOpen(true)
        setTimeout(() => {
            inputRef.current!.focus({
                cursor: 'all',
              });
        }, 200)

    }

    const closeSearch = () => {
        setIsSearchModalOpen(false)
    }

    const onSelection = () => {
        setIsSearchModalOpen(false)
        if (onSelect) {
            onSelect()
        }
    }

    const onSearchSubmitFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSearchSubmit = (searchValue: string) => {
        console.log('Success:', {searchValue});
        if (searchValue === "") {
            console.log("clearing results")
            setDataSetResults(null)
            setSignalResults(null)
        } else {
            console.log("searching results...")
            setLoading(true)
            getDataSets({name__icontains: searchValue}).then((response) => {
                // handle success
                console.log("datasets", {response});
                setDataSetResults(response.data.results)
    
              }).catch(function (error) {
                // handle error
                console.log(error);
                setDataSetResults([])
              })
              .then(function () {
                return getSignals({symbol__icontains: searchValue, include_nested_data_set: true, include_public: true})
              }).then((response) => {
                // handle success
                console.log("datasets", {response});
                if (response.status === 200) {
                    const response_data = response.data
                    if (response_data.results) {
                        setSignalResults(response_data.results)
                    }

                }
    
              }).catch(function (error) {
                // handle error
                console.log(error);
                setSignalResults([])
              })
              .then(function () {
                // resolve({ data: {results: []} })
                setLoading(false)
              });
        }
        // onCreateDashboard(values)
    };

    return <>
        <div>
        {/* <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)'}} icon={<SearchOutlined />} onClick={showSearch}> */}
        <Button type="text" icon={<SearchOutlined />} onClick={showSearch}>
            {includeTitle ? 'Search' : ''}
        </Button>
        </div>
        <Modal title={ "Search" } open={isSearchModalOpen} footer={null} onCancel={closeSearch}>
            <Space direction='vertical' style={{width: '100%'}}>
                <Search
                    placeholder="Stock or Crypto Symbol or Data Set Name..."
                    allowClear
                    enterButton
                    size="large"
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
                {loading && (
                    <Loading message='Searching...' />

                )}
                {(signalResults && signalResults.length === 0 && dataSetResults && dataSetResults.length === 0) && (
                    <Empty description={"No Results Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {dataSetResults && dataSetResults.length > 0 && (
                    <div>
                        <Typography.Title level={4}>Data Sets</Typography.Title>
                        <ul>
                            {dataSetResults.map((dataSet) => {
                                return <li><Link to={`/data_sets/${dataSet.id}/`} onClick={onSelection}>{dataSet.name}</Link></li>
                            })}
                        </ul>
                    </div>
                )}
                {signalResults && signalResults.length > 0 && (
                    <div>
                        <Typography.Title level={4}>Signals</Typography.Title>
                        <ul>
                            {signalResults.map((signal) => {
                                return <li><Link to={`/symbols/${signal.symbol}/`} onClick={onSelection}>{signal.symbol}</Link> in <Link to={`/data_sets/${signal.data_set_id}/`} onClick={onSelection}>{signal.data_set.name}</Link></li>
                            })}
                        </ul>
                    </div>
                )}

            </Space>
            
            
        </Modal>
    </>

}
