import React, { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    selectTheme, chooseTheme
} from './lightDarkSwitchSlice'
import {
    Theme
} from '../../types/types'
import {
    themeList
} from '../../themeRegistry'
import { Modal, Button, Space, Typography  } from 'antd';
import { FormatPainterOutlined } from '@ant-design/icons'
interface LightDarkSwitchParams {
    mode?: 'text' | 'icon' | 'button' | 'text-button'
}


export function LightDarkSwitch({
    mode='text',
}: LightDarkSwitchParams) {

    const selectedTheme = useAppSelector(selectTheme)
    const [isThemeSelectModalOpen, setIsThemeSelectModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const openThemeSelect = () => {
        setIsThemeSelectModalOpen(true)

    }

    const closeThemeSelect = () => {
        setIsThemeSelectModalOpen(false)
    }

    const onChooseTheme = (val: Theme) => {
        dispatch(chooseTheme({theme: val}))
        setIsThemeSelectModalOpen(false)
    }

    return (
        <>
            {/* <span onClick={() => dispatch(toggle())}>Change Theme: {theme}</span> */}
            {mode === 'text' && (
                <span onClick={openThemeSelect}>Change Theme</span>

            )}
            {mode === 'text-button' && (
                <Button type='text' icon={<FormatPainterOutlined />}>
                <span onClick={openThemeSelect}>Theme</span>

                </Button>

            )}

            <Modal title={ "Theme" } open={isThemeSelectModalOpen} footer={null} onCancel={closeThemeSelect}>
                
                <Space direction='vertical'>
                    <Typography.Title level={4}>Light</Typography.Title>
                    <Space wrap>
                        {themeList.filter((theme) => (theme.bodyLight === 'light' && theme.hidden !== true)).map((theme) => (
                            <Button onClick={() => onChooseTheme(theme.id)} disabled={selectedTheme === theme.id}>{theme.name}</Button>

                        ))}

                    </Space>
                    <Typography.Title level={4}>Dark</Typography.Title>
                    <Space wrap>
                        {themeList.filter((theme) => (theme.bodyLight === 'dark' && theme.hidden !== true)).map((theme) => (
                            <Button onClick={() => onChooseTheme(theme.id)} disabled={selectedTheme === theme.id}>{theme.name}</Button>

                        ))}
                    </Space>
                </Space>
                <Typography.Paragraph style={{marginTop: '24px'}}>Themes can improve your viewing experience, but also allow you to share screenshots in the style of your choice. If there are additional themes you want to see added, please contact us.</Typography.Paragraph>
            </Modal>
        </>
    )

}
