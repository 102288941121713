import { AccountBillingMethod, SubscriptionRecurring, StoreItem, StoreCartItem } from './types/types';
import { RootState } from './app/store';


export const effectiveMonthlyUnitPrice = (storeItem: StoreItem, subscriptionTimeframe: SubscriptionRecurring) => {
    if (subscriptionTimeframe === 'year') {
        return <>
            ${((storeItem.year_price_cents || 0) / (100 * 12)).toFixed(2)}
        </>
    }
    return <>
        ${((storeItem.month_price_cents || 0) / 100).toFixed(2)}
    </>
}

export const effectiveYearUnitPrice = (storeItem: StoreItem, subscriptionTimeframe: SubscriptionRecurring) => {
    if (subscriptionTimeframe === 'year') {
        return <>
            ${((storeItem.year_price_cents || 0) / 100).toFixed(2)}
        </>
    }
    return <>
        ${(((storeItem.month_price_cents || 0) / 100) * 12).toFixed(2)}
    </>
}


export const cartItemUnitPrice = (cartItem: StoreCartItem) => {
    if (cartItem.recurring === 'month') {
        return ((cartItem.item.month_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'year') {
        return ((cartItem.item.year_price_cents || 0) / 100).toFixed(2)
    }
}

export const cartItemTotalPrice = (cartItem: StoreCartItem) => {
    if (cartItem.recurring === 'month') {
        return (cartItem.quantity * (cartItem.item.month_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'year') {
        return (cartItem.quantity * (cartItem.item.year_price_cents || 0) / 100).toFixed(2)
    }

}

export const cartTotalPrice = (cartItems: StoreCartItem[]) => {
    return (cartItems.reduce((agg, cartItem) => {
        if (cartItem.recurring === 'month') {
            return agg + (cartItem.quantity * (cartItem.item.month_price_cents || 0))
        } else if (cartItem.recurring === 'year') {
            return agg + (cartItem.quantity * (cartItem.item.year_price_cents || 0))
        }
        return agg
    }, 0) / 100).toFixed(2)
}


export const toTitleCase = (str: string) => {
    return str.toLowerCase().replace(/_/g, ' ').split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export const objIsRootState = (maybeState: unknown): maybeState is RootState => {
    if (typeof maybeState === 'object' && maybeState !== null) {
        return 'cart' in maybeState
    }
    return false
} 

export const isNullish = (o: any): boolean => o === undefined || o === null
