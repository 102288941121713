// A mock function to mimic making an async request for data
import {getDataSets} from '../../services/signalApi'
import { DataProvider, DataSet } from '../../types/types'

export interface DataSetListResponse {
    results: DataSet[]
}

export function datasetList() {
    return new Promise<{ data: DataSetListResponse }>((resolve) => {
        getDataSets().then((response) => {
            // handle success
            console.log("datasets", {response});
            resolve({ data: {results: response.data.results} })

          }).catch(function (error) {
            // handle error
            console.log(error);
            resolve({ data: {results: []} })
          })
          .then(function () {
            // resolve({ data: {results: []} })
          });
    }
    );
}
