import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { useEffect } from "react";

import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'
import { Link } from "react-router-dom";

export function Integrations() {
    useEffect(() => {document.title = 'FreqSignals | Integrations'}, [])

    const account = useAppSelector(selectAccount);
    console.log("account", {account})


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Integrations</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>Create Data Consumer or API Tokens. Connect to consume or provide data. <Link to={`/integration_offerings`}>Learn more.</Link></Typography.Paragraph>
                    {!account && (
                        <>
                            <Typography.Paragraph>Log In or Sign Up to manage integrations. Integrations allow you to pull signals programatically or send data directly to your platform of choice.</Typography.Paragraph>
                        </>
                    )}
                    {account && (
                        <ApiIntegrationsList />
                    )}
                </div>
            </div>
        </div>
    );
}
