import React, { useState } from 'react';
import { Form, Modal, Checkbox, Input, Typography, Button, Select, Alert, Col } from 'antd';
import { editDataSet } from '../services/signalApi'
import { selectAccount } from '../features/login/loginSlice'
import { DataProvider, DataSet } from '../types/types';
import { Link } from "react-router-dom";

import { DiscordLink } from './DiscordLink'
import { useAppSelector, useAppDispatch } from '../app/hooks';
const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface DataSetEditModalParams {
    isOpen: boolean
    dataset: DataSet
    onCancel: () => void
    onSuccess: () => void
}


export function DataSetEditModal({
    isOpen,
    dataset,
    onCancel,
    onSuccess,
}: DataSetEditModalParams) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isEditing, setIsEditing] = useState(false);
    const [submissionError, setSubmissionError] = useState<string | null>(null);


    console.log('DataSetEditModal', {dataset, isEditing})

    const handleCancel = () => {
        form.resetFields()
        setIsEditing(false)
        onCancel()
    }

    const onEditDataSetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onEditDataSetSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onEditDataSet(values)
    };

    const onEditDataSet = (values: any) => {
        if (account) {
            console.log("onEditDataSet values", {values})
            setIsEditing(true)
            setSubmissionError(null)
            editDataSet(
                dataset.id,
                {
                    name: values.name,
                    // is_public: values.is_public ? 1 : 0,
                    is_published: values.is_published ? 1 : 0,
                    description: values.description
                }
            ).then((res) => {
                console.log("created", {res})
                if (res.status === 201 ) {
                    const response_data = res.data
                    setIsEditing(false)

                } else if (res.status === 400) {
                    const response_data = res.data
                    setSubmissionError(response_data)
                } else {
                    console.log("failed", {res})
                }
                // const data_set_id = response_data.id
                // const data_set_name = response_data.name
                // setNewDataSet({id: data_set_id, name: data_set_name})
            }
            ).catch((res) => {
                console.log("failed catch", {res})

            }).finally(() => {
                setIsEditing(false)
                onSuccess()
            })
        }
    }


    const signalFormBody = (
        <Form
        name="basic"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{name: dataset.name, is_public: dataset.is_public, is_published: dataset.is_published, description: dataset.description}}
        onFinish={onEditDataSetSubmit}
        onFinishFailed={onEditDataSetFailed}
        autoComplete="off"
        >
            
            {(isEditing && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
                    <Typography.Paragraph>
                    Data Set: {dataset.name} ({dataset.id})
                    </Typography.Paragraph>

                    <Form.Item
                        label="Name"
                        name="name"
                    >
                        <Input />
                    </Form.Item>

                    {/* <Form.Item name="is_public" valuePropName="checked" wrapperCol={{ span: 16 }}
                    >
                        <Checkbox> Public</Checkbox>
                    </Form.Item> */}

                    <Form.Item name="is_published" valuePropName="checked" wrapperCol={{ span: 16, offset: 6 }}
                    >
                        <Checkbox> Published - allow other people to subscribe to this dataset</Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <TextArea
                            showCount
                            maxLength={1600}
                            style={{ height: 120 }}
                            // onChange={onChange}
                            placeholder="Some optional description for your dataset..."
                            />
                    </Form.Item>

                    <Col span={16} offset={6}>
                        <Typography.Paragraph>To make this dataset available publicly, <Link to='/contact_us'>contact us</Link>. We need to see a track record of consistent quality.</Typography.Paragraph>
                    </Col>

                    <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                        <Button type="primary" htmlType="submit">
                            Save Dataset
                        </Button>
                    </Form.Item>

                    {submissionError && (
                        <Alert message={submissionError} type="error" />
                    )}

        </Form>
    )

    return (
            <Modal title="Create Signal" open={isOpen} footer={null} onCancel={handleCancel}>
                {signalFormBody}
            </Modal>
    )

    
}
