import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { themesArr, Theme } from '../../types/types'
// import { isTheme, defaultTheme } from '../../themeRegistry'
import { RootState } from '../../app/store';
export const defaultTheme: Theme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dracula' : 'pro'
export const isTheme = (x: any): x is Theme => themesArr.includes(x)

// export type Theme = 'light' | 'dark' | 'pro' | 'purple' | 'green' | 'catppuccin-mocha' | 'catppuccin-latte' | 'dracula'
const lightThemesArr: Theme[] = ['light', 'pro', 'purple' , 'green', 'catppuccin-latte', 'alt-light', 'nord-light']
// const darkThemesArr: Theme[] = ['dark', 'catppuccin-mocha']


export interface ThemeState {
  theme: Theme
}
const storedTheme = localStorage.getItem("THEME")
const initialState: ThemeState = {
    theme: isTheme(storedTheme) ? storedTheme : defaultTheme
};

export interface SelectThemeAction {
  theme: Theme,
}

export const chooseTheme = createAsyncThunk(
  'themeSelect/chooseTheme',
  async (userData: SelectThemeAction, thunkApi) => {
    return { theme: userData.theme }
  }
);

export const lightDarkSwitchSlice = createSlice({
  name: 'themeSelect',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggle: (state) => {

      state.theme = state.theme === 'light' ? 'dark' : 'light'
      localStorage.setItem("THEME", state.theme)

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chooseTheme.fulfilled, (state, action) => {
        state.theme = action.payload.theme
        localStorage.setItem("THEME", state.theme)
      })
  },
});

export const { toggle } = lightDarkSwitchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectLight = (state: RootState) => lightThemesArr.includes(state.theme_select.theme);
export const selectTheme = (state: RootState) => state.theme_select.theme;

export default lightDarkSwitchSlice.reducer;
