import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

import { CommentOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, List, Space, Tooltip, Typography, Badge, Alert, Form } from 'antd';
import { Link } from "react-router-dom";
import { aiPrompt } from '../../services/signalApi';
import { TweetThis } from '../../components/TweetThis';
import { CopyToClipboard } from '../../components/CopyToClipboard';
import { Loading } from '../../components/Loading';
import {
    openAiChat, closeAiChat, selectAiChatOpen, selectAiChatWipPrompt
} from './aiChatSlice';

interface FormSubmitValues {
    prompt: string
}


export function AiChat() {

    const dispatch = useAppDispatch();
    const account = useAppSelector(selectAccount);
    const open = useAppSelector(selectAiChatOpen);
    const wipPrompt = useAppSelector(selectAiChatWipPrompt);

    const [form] = Form.useForm();
    const [inFlight, setInFlight] = useState(false);
    const [response, setResponse] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        form.setFieldsValue({ prompt: wipPrompt})
    }, [wipPrompt])


    const showDrawer = () => {
        dispatch(openAiChat());
    };
    
    const closeDrawer = () => {
        dispatch(closeAiChat());
    };

    const onChatSubmit = (values: FormSubmitValues) => {
        console.log('onChatSubmit:', {values});
        if (account) {
            setResponse(null)
            setInFlight(true)
            aiPrompt(account.id, values.prompt).then((res) => {
                setInFlight(false)
                if (res.status === 200) {
                    const response_data = res.data
                    setResponse(response_data.prompt_response)
                } else {
                    console.log("err", {res})
                    setError("Something went wrong. We are looking into this.")
                }
            })
        }

    };

    const onChatFailed = () => {

    }

    return <>

        <Button type="text" icon={<CommentOutlined />} onClick={showDrawer}>
            AI
        </Button>
        
        <Drawer
            title="AI Prompt Chat"
            placement="right"
            onClose={closeDrawer}
            open={open}
            mask={false}
            footer={<Typography.Paragraph style={{fontSize: '0.75em'}}>Powered by OpenAI's ChatGPT</Typography.Paragraph>}
        >
            <Form
                name="basic"
                form={form}
                layout={'vertical'}
                // labelCol={{ span: 6 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{
                    ...(wipPrompt ? { prompt: wipPrompt } : {})
                }}
                onFinish={onChatSubmit}
                onFinishFailed={onChatFailed}
                autoComplete="off"
                >
                    <Typography.Paragraph>Send your prompt directly to ChatGPT. Each prompt is executed in isolation. Use the prompts to create summaries or tweets or whatever your heart desires.</Typography.Paragraph>
                    {!account && (
                        <Typography.Paragraph>
                            You must be logged in to use the AI Chat.
                        </Typography.Paragraph>
                    )}
                    {account?.daily_ai_chat_cap && (
                        <Typography.Paragraph>
                            Your plan is limited to {account?.daily_ai_chat_cap} prompts in a 24 hour period. <Link to={`/store`} onClick={closeDrawer}>Purchase more at the Store</Link>.
                        </Typography.Paragraph>
                    )}
                    <>
                        <Form.Item
                            label="Prompt"
                            name="prompt"
                            rules={[{ required: true, message: 'Enter a prompt to send to the AI Chat' }]}
                        >
                            <Input.TextArea
                                showCount
                                maxLength={1600}
                                style={{ height: 120 }}
                                // onChange={onChange}
                                placeholder="Craft a tweet that says how bullish AMZN is..."
                            />
                        </Form.Item>

                        <Form.Item
                            // wrapperCol={{ span: 16, offset: 6 }}
                            style={{textAlign: 'right'}}
                        >
                            <Button type="primary" htmlType="submit" disabled={
                                inFlight || !account
                            }>
                                Send
                            </Button>
                        </Form.Item>
                    </>

                    {inFlight && (
                        <Loading
                            message="Generating Response"
                            tip=""
                            description = ""
                        />
                    )}

                    {(response && (
                        <>
                            <Typography.Title level={4}>
                                ChatGPT Response
                            </Typography.Title>
                            <Typography.Paragraph>{response}</Typography.Paragraph>
                            <Space>
                                <CopyToClipboard text={response} content={<Button>Copy</Button>} />
                                <TweetThis text={response} url='https://freqsignals.com' related='freqsignals' />
                            </Space>
                        </>

                    ))}

                    {(error && (
                        <>
                            <Typography.Title level={4}>
                                Error
                            </Typography.Title>
                            <Typography.Paragraph>{error}</Typography.Paragraph>
                        </>

                    ))}

            </Form>

        </Drawer>
    </>
    // return <>Cart</>
}
