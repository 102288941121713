import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { useEffect } from "react";
import { DataSetList } from '../../features/dataSetList/DataSetList';
import { SignalList } from '../../components/SignalList';
import { SignalSelectableBrowser } from '../../components/SignalSelectableBrowser'

import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';

export function Signals() {
    useEffect(() => {document.title = 'FreqSignals | Signals'}, [])

    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
    const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
    const blocked = <Tooltip title="Awaiting Configuration"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
    const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

  return (
    <div className="App">
      <div className="App-content">
        {/* <Typography.Title level={1}>Signals</Typography.Title> */}
        <div className="App-page-content">
            {/* <Typography.Paragraph>
                Subscribe to custom and private data sets <a>Learn more <ArrowRightOutlined /></a>
            </Typography.Paragraph> */}
            {/* <Typography.Paragraph>{optional} Select Data Sets</Typography.Paragraph>
            <Typography.Paragraph>{optional} Create Data Consumer or API Tokens</Typography.Paragraph>
            <Typography.Paragraph>{optional} Configure Billing</Typography.Paragraph>
            <Typography.Paragraph>{blocked} Go Live</Typography.Paragraph> */}
            <DataSetList subscribed={true} include_buttons={true} />
            <p></p>
            <SignalSelectableBrowser name="Signals"/>
            <p></p>
            {/* <ApiIntegrationsList /> */}
        </div>
      </div>
    </div>
  );
}
