
import { Space, Row, Col, Typography } from 'antd';
import { ReactNode } from 'react'


interface PageHeaderParams {
    title: string
    sub?: string | ReactNode
    right?: string | ReactNode
    style?: React.CSSProperties
    className?: string
}

export function PageHeader({
    title,
    sub,
    right,
    style,
    className,
}: PageHeaderParams) {
    const addedStyle = style ? style : {marginTop: '18px'}
    return (
        <>
        
        <Row style={addedStyle} className={`App-page-header ${className}` }>

            <Col span={12} offset={0} className={'App-page-header-title'}>
            <Typography.Title level={2} style={{ margin: 0 }}>
                {title}
            </Typography.Title>
              {/* <h2 style={{marginTop: "0"}}>
                {title}
              </h2> */}
              
            </Col>
            <Col span={12} offset={0} xs={24} md={12}>
              {/* <Space style={{float: 'right', display: 'inline-bock'}}> */}
              <Space className={'App-page-header-right'}>
                {right}
              </Space>
              
                
            </Col>

            
          </Row>
          {sub && (
              <Row className={'App-page-header-sub'}>
                    {sub}
                </Row>
          )}
        </>
          
    )
    
}
