import { useEffect } from "react";
import { Tooltip, Typography } from 'antd';

import { DashboardList } from '../../components/DashboardList';
import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'

export function Dashboards() {
    useEffect(() => {document.title = 'FreqSignals | Dashboards'}, [])

    const account = useAppSelector(selectAccount);
    console.log("account", {account})


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Dashboards</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>Configure dashboards for quickly viewing and sharing Signals.</Typography.Paragraph>
                    {!account && (
                        <>
                            <Typography.Paragraph>Log In to manage Dashboards. Dashboards allow you configure widgets to display the Signals that are important to you.</Typography.Paragraph>
                        </>
                    )}
                    {account && (
                        <DashboardList />
                    )}
                </div>
            </div>
        </div>
    );
}
