// import { green, yellow } from '@ant-design/colors';
import { Progress, Tooltip, Space } from 'antd';
import { getApiStatus } from '../services/signalApi';
import { useIsBigScreen, useAppSelector } from '../app/hooks'
import { selectTheme } from '../features/lightDarkSwitch/lightDarkSwitchSlice';
import { getThemeGreen, getThemeYellow } from '../themeRegistry';

import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';

interface ApiStatus {
    message: string
    response_time: number
    star_status: number
}

export function ApiStatus() {

    const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);
    const selectedTheme = useAppSelector(selectTheme);
    const isBigScreen = useIsBigScreen()

    const green = getThemeGreen(selectedTheme)
    const yellow = getThemeYellow(selectedTheme)

    useEffect(() => {
        new Promise(res => setTimeout(() => {
            return res(true)
        }, Math.floor(Math.random() * 1000)))
        .then(getApiStatus).then((res) => {
            console.log("api_status", {res})
            const response_data = res.data
            setApiStatus(response_data)
        }
        ).catch(() => {
            setApiStatus({message: "Offline", response_time: -1, star_status: 0})
        })
    }, [])

    console.log("apiStatus", {apiStatus})
    if (apiStatus) {
        const pct = apiStatus.star_status / 5 * 100
        const msg = `API is ${apiStatus.message}: ${pct}% | ${apiStatus.response_time}s`
        return (
            <Tooltip placement="bottom" title={msg}>
                {isBigScreen && (
                    <Progress percent={pct} steps={5} showInfo={false} size="small" strokeColor={green} />
                )}
                {!isBigScreen && (
                    <CheckCircleOutlined style={{ color: green }}/>
                )}
            </Tooltip>
            
        )
    } else {
        const pct = 50
        const msg = `We are having a problem establishing your connection with FreqSignals right now.`
        return (
            <Tooltip placement="bottom" title={msg}>
                {isBigScreen && (
                    <Progress percent={pct} steps={5} size="small" strokeColor={yellow} />
                )}
                {!isBigScreen && (
                    <WarningOutlined style={{ color: yellow }}/>
                )}
            </Tooltip>
            
        )
    }



}
