import { Button, Divider, Radio, Carousel, Popconfirm, message, Typography } from 'antd';
import { useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import { DateTime } from 'luxon';

import { Table, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { Workflow } from '../types/types';
// import styles from './WorkflowList.module.css';
import { PaginatedTable } from './PaginatedTable';
import { SymbolLink } from './SymbolLink';
import { Loading } from './Loading';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getWorkflows, deleteWorkflow, editWorkflow } from '../services/signalApi';
import { CreateWorkflowModal } from '../components/CreateWorkflowModal'


interface WorkflowListParams {
}


export function WorkflowList({}: WorkflowListParams) {
    console.log("WorkflowList")
    const account = useAppSelector(selectAccount)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [showCreateWorkflow, setShowCreateWorkflow] = useState(false);
    const [workflowCopySource, setWorkflowCopySource] = useState<Workflow | null>(null);
    const [workflowCount, setWorkflowCount] = useState<number | null>(null);
    const [reloads, setReloads] = useState(0);
    const createWorkflowClick = () => {
        setWorkflowCopySource(null)
        setShowCreateWorkflow(true)
    }

    const handleCreateCancel = () => {
        setShowCreateWorkflow(false)
        setWorkflowCopySource(null)
    }

    const handleCreateWorkflow = () => {
        // setShowCreateWorkflow(false)
        setReloads(reloads + 1)
        // forceUpdate()
    
    }

    const delete_workflow = (workflow: Workflow) => {
        console.log("delete_integration", {workflow})


        deleteWorkflow(workflow.id).then((res) => {
            if (res.status === 204 ) {
                const response_data = res.data

            } else if (res.status === 400) {
                const response_data = res.data
            } else {
                console.log("failed", {res})
            }

        }).catch((res) => {

        }).finally(() => {
            setReloads(reloads + 1)

            // dispatch(loadIntegrationsAsync())
        })

    }

    const copy_workflow = (workflow: Workflow) => {
        console.log("copy_workflow", {workflow})
        setWorkflowCopySource(workflow)
        setShowCreateWorkflow(true)
    }

    const onEnable = (workflow: Workflow) => {
        editWorkflow(workflow.id, {enabled: 1}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                // message.info("Enabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error(`Failure: ${res.data}`)
            }
        })
    };

    const onDisable = (workflow: Workflow) => {
        editWorkflow(workflow.id, {enabled: 0}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                // message.info("Disabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

   

    const content_table = () => {
        let columns: ColumnsType<Workflow> = [
            {
              title: 'Workflow',
              dataIndex: 'name',
              key: 'name',
              render: (text: string, row: Workflow) => {
                return <Link to={`/workflows/${row.id}/`}>{row.name}</Link>
              },
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text: string, workflow: Workflow) => {
                    if (workflow.config.actions && workflow.config.actions.length) {
                        return workflow.config.actions.map((action) => {
                            if (action.action_type === 'discord_webhook_send_message') {
                                return `Discord: ${action.args.message}`
                            }
                            if (action.action_type === 'telegram_send_message') {
                                return `Telegram: ${action.args.message}`
                            }
                            return <>action.action_type</>
                        }).join(", ")
                    }
                    return <>No Action Configured</>
                },
            },
            {
                title: '',
                key: 'id',
                dataIndex: 'id',
                width: 150,
                // fixed: 'right',
                render: (value: string, row: Workflow) => (
                    <Space>
                        {row.enabled === 1 && (
                            <Tooltip placement="top" title="Enabled. Click to pause.">
                                <a onClick={() => onDisable(row)}>
                                    <PauseCircleOutlined />
                                    {/* Disable */}
                                </a>
                            </Tooltip>
                        )}

                        {row.enabled === 0 && (
                            <Tooltip placement="top" title="Disabled. Click to enable.">
                                <a onClick={() => onEnable(row)}>
                                    {/* Enable */}
                                    <PlayCircleOutlined />
                                </a>
                            </Tooltip>
                        )}

                        <Tooltip placement="top" title="Copy">
                                <a onClick={() => copy_workflow(row)}>
                                    <CopyOutlined />
                                </a>
                        </Tooltip>

                        <Tooltip placement="top" title="Delete">
                            <Popconfirm title={<Typography.Paragraph>Are you sure you want to remove this workflow?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => delete_workflow(row)}>
                                <a>
                                    <DeleteOutlined />
                                </a>
                            </Popconfirm>
                        </Tooltip>


                        {/* TODO - copy/clone */}
                        {/* TODO - edit name */}

                        {/* <Tooltip placement="top" title="Paused. Click to enable.">
                            <PauseCircleOutlined />
                        </Tooltip> */}

                        {/* clone, delete */}
                    </Space>
  
                )
                // render: (value: string, row: Workflow) => <a href={`https://www.tradingview.com/chart/?symbol=${row.symbol}`} target='blank'>See on Trading View</a>
                // render: (value: string, row: DataSet) => <a>Subscribe</a>,
            }

        ];
        
        const getPagination = (data: any) => {
            const paginationParams = {
                limit: data.pageSize,
                ...(data.current > 1 ? {offset: (data.current - 1) * data.pageSize} : {}),
                // offset: (data.current - 1) * data.pageSize,
            }
            console.log('WorkflowList getPagination', {data, paginationParams})
            return paginationParams
            // return {}
        }

        const fetchData = (data: any) => {
            console.log("WorkflowList fetchData", {data})
            const getWorkflowsFn = getWorkflows;
            return getWorkflowsFn({
                ...(data.pagination ? getPagination(data.pagination) : {}),
                ordering: 'name'
            }).then((res) => {
                console.log("WorkflowList Workflows", {res})
                if (res.status === 200) {
                    setWorkflowCount(res.data.count || 0)
                    return {
                        results: res.data.results || [],
                        count: res.data.count || 0
                    }
                } else {
                    return {
                        results: [],
                        count: 0
                    }
                }
            }).catch(() => {
                return {
                    results: [],
                    count: 0
                }
            })
        }

        
        console.log("WorkflowList Returning...")

        const emptyDescription = () => {
            return "No Workflows Found"
        }

        return <PaginatedTable<Workflow>
            columns={columns}
            rowKey={(Workflow) => Workflow.id}
            fetchData={fetchData}
            filterValues={{reloadTime: reloadedLast}}
            emptyDescription={emptyDescription()}
            key={reloads}
            size='large'
        />
            
    }

    const head = (
        <Typography.Title level={1}>Workflows</Typography.Title>
    )

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }


    const newButtonComponent = <Button type="primary" onClick={createWorkflowClick} disabled={!!account?.workflow_cap && !!workflowCount && account?.workflow_cap <= workflowCount}>+ New</Button>

    const newButton = !!account?.workflow_cap && !!workflowCount && account?.workflow_cap <= workflowCount ?
        <Tooltip title={`At Workflow Cap of ${account.workflow_cap}`}>{newButtonComponent}</Tooltip> : newButtonComponent


    const buttons = (
        <Space style={{marginBottom: '6px'}}>
            {/* <Button type="default" onClick={loadWorkflows}><ReloadOutlined /></Button> */}
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
            {newButton}
            {/* <Button type="default">Filter</Button> */}
            {/* <Divider type="vertical" />
            <Radio.Group>
                <Radio.Button value="positive">Positive</Radio.Button>
                <Radio.Button value="neutral">Neutral</Radio.Button>
                <Radio.Button value="negative">Negative</Radio.Button>
            </Radio.Group> */}
            
            {/* <Button type="default">Stocks</Button>
            <Button type="default">Futures</Button>
            <Button type="default">Forex</Button>
            <Button type="default">Crypto</Button>
            <Button type="default">Indices</Button>
            <Button type="default">Bonds</Button>
            <Button type="default">Economy</Button>
            <Button type="default">Other</Button> */}
            {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
        </Space>
    )

    return (
        <>
            {/* {head} */}
            {buttons}
            {content_table()}
            <CreateWorkflowModal
                isOpen={showCreateWorkflow}
                onCancel={handleCreateCancel}
                onCreate={handleCreateWorkflow}
                workflowCopySource={workflowCopySource ? workflowCopySource : undefined}
            />

        </>
    )
    
    
    
}
