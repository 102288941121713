import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { HomeOutlined, ReloadOutlined, ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Breadcrumb, Tooltip } from 'antd';
import {
    loadIntegrationsAsync,
  clear,
  selectApiIntegrations,
  selectStatus,
} from './apiIntegrationsSlice';
import { CreateIntegrationModal } from '../../components/CreateIntegrationModal'
import { Col, Divider, Row, Space, Table, Popconfirm } from 'antd';

import styles from './ApiIntegrationsList.module.css';
import { deleteApiApplicationIntegration, deleteApiSimpleIntegration, deleteDiscordWebhookIntegration, deleteTelegramTokenIntegration } from '../../services/signalApi'
import { IntegrationType, Integration } from '../../types/types'


// TODO - this shouldn't be a feature? should just manage state locally?


interface ApiIntegrationsListParams {
    show_name?: boolean
}


export function ApiIntegrationsList({
    show_name = false,
}: ApiIntegrationsListParams) {
    const api_integrations = useAppSelector(selectApiIntegrations);
    const status = useAppSelector(selectStatus);
    const [showCreateIntegration, setShowCreateIntegration] = useState(false);

    const dispatch = useAppDispatch();

    console.log("ApiIntegrationsList start", {status, api_integrations})

    const clear_click = () => {
        dispatch(clear())
    }

    const fetch_click = () => {
        dispatch(loadIntegrationsAsync())
    }

    const create_integration_click = () => {
        setShowCreateIntegration(true)
    }

    const handleCreateCancel = () => {
        setShowCreateIntegration(false)

    }

    const handleCreate = () => {
        dispatch(loadIntegrationsAsync())

    }

    const delete_integration = (integration: Integration) => {
        console.log("delete_integration", {integration})
        let delete_fn = null
        if (integration.type === "oauth2_api_token") {
            delete_fn = deleteApiApplicationIntegration
        } else if (["simple_api_token"].includes(integration.type)) {
            delete_fn = deleteApiSimpleIntegration
        } else if (integration.type === "discord_webhook") {
            delete_fn = deleteDiscordWebhookIntegration
        } else if (integration.type === "telegram_token") {
            delete_fn = deleteTelegramTokenIntegration
        }

        if (delete_fn !== null) {
            delete_fn(integration.id).then((res) => {
                if (res.status === 204 ) {
                    const response_data = res.data

                } else if (res.status === 400) {
                    const response_data = res.data
                } else {
                    console.log("failed", {res})
                }

            }).catch((res) => {

            }).finally(() => {
                dispatch(loadIntegrationsAsync())
            })
        }

    }

    if (status === "idle" && api_integrations === null){
        console.log("dispatching")
        dispatch(loadIntegrationsAsync())
        // setHasLoadedIntegrations(true)
    }

    const head = (
        <Typography.Title level={1}>Integrations</Typography.Title>
    )



    const integration_type_to_str = (integration_type: IntegrationType) => {
        if (integration_type === 'oauth2_api_token') {
            return 'OAuth2 API Token'
        }

        if (integration_type === 'simple_api_token') {
            return 'Simple API Token'
        }

        if (integration_type === 'trading_view_webhook') {
            return 'Trading View Webhook'
        }

        if (integration_type === 'discord_webhook') {
            return 'Discord Webhook'
        }

        if (integration_type === 'telegram_token') {
            return 'Telegram Token'
        }
        return integration_type
    }

    const content = () => {

        if (api_integrations === null || Array.isArray(api_integrations) === false || api_integrations.length === 0){
            return (
                <div>
                    <Typography.Paragraph>Nothing Loaded.</Typography.Paragraph>
                </div>
            )
        }

        const columns = [
            {
                title: 'Integration',
                dataIndex: 'name',
                key: 'name',
                render: (value: string, row: Integration) => <>{row.name}</>

            },
            // {
            //     title: 'Client ID',
            //     dataIndex: 'client_id',
            //     key: 'client_id',
            //     render: (value: string, row: ApiIntegration) => <>{row.application.client_id}</>
            // },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (value: string, row: Integration) => <>{integration_type_to_str(row.type)}</>
            },
            // {
            //     title: 'Last Used',
            //     dataIndex: 'last_used',
            //     key: 'last_used',
            //     render: (value: string, row: Integration) => <><ClockCircleOutlined /> Coming Soon</>
            // },
            {
                title: 'Created At',
                dataIndex: 'created_date',
                key: 'created_date',
                // render: (value: string, row: ApiIntegration) => <>Unknown</>
            },
            {
                title: 'Action',
                key: 'operation',
                dataIndex: 'id',
                render: (value: string, row: Integration) => <>
                        <Tooltip placement="left" title="Delete">
                            <Popconfirm title={<Typography.Paragraph>Are you sure you want to remove this integration?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => delete_integration(row)}>
                                <a>
                                    <DeleteOutlined />
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    </>
            }
          ];


        return (            
            <Table
                dataSource={api_integrations}
                rowKey='id'
                columns={columns}
                loading={status==="loading"}
                pagination={{ hideOnSinglePage: true }}
                size="middle"
                bordered={true}
            />
        )
    }



    const buttons = (
        <Space style={{marginBottom: '6px'}}>
            <Button type="primary" className={styles.button} onClick={create_integration_click}>+ New</Button>
            <Button type="default" className={styles.button} onClick={fetch_click}><ReloadOutlined /></Button>
            {/* <Button type="default">Filter</Button>
            <Button type="default">Disable All</Button>
            <Button type="default">Delete All</Button> */}
            {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
        </Space>
    )

    return (
        <>
            {(show_name) && head}
            {buttons}
            {content()}
            {/* {showCreateIntegration && (<CreateIntegrationModal onCancel={handleCreateCancel}/>)} */}
            <CreateIntegrationModal isOpen={showCreateIntegration} onCancel={handleCreateCancel} onCreate={handleCreate} />
        </>
    )

    
}
