import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { message  } from 'antd';

import {
    Forecast,
    Signal,
    SignalHistory,
    Workflow,
    WorkflowConfig,
    WorkflowExecution,
    Dashboard,
    DashboardConfig,
    AccountBillingMethod,
    StoreItem,
    StoreCart,
    StoreCartItem,
    AccountItemPurchase,
    SubscriptionRecurring,
    FeatureFlag,
} from '../types/types'


export const createSignalApiAxiosInstance = (): AxiosInstance =>
    axios.create({
        // baseURL: 'http://localhost:8000',
        baseURL: API_DOMAIN,
        validateStatus: (status) => status >= 200 && status < 500,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
    })
export const axiosLoggedOutInstance = createSignalApiAxiosInstance()
axiosLoggedOutInstance.interceptors.request.use((config) => {
    console.log("request", {config})
    return config
})
axiosLoggedOutInstance.interceptors.response.use((response) => {
    console.log("response", {response})
    return response
})
export const axiosLoggedInInstance = createSignalApiAxiosInstance()
axiosLoggedInInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN")
    if (!config.headers) {
        config.headers = {}
    }

    // const state = storeRegistry.getState()


    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    } else {
        console.log("no accessToken, likely error")
    }


    return config
})

axiosLoggedInInstance.interceptors.response.use((response) => {
    // console.log("axiosLoggedInInstance response", {response})
    return response
}, async (error) => {
    console.log("axiosLoggedInInstance error response")
    const config = error.config;
    // if(error.response && error.response.status === 400) {
    //     message.error(error.response.data)
    // }
    if(error.response && error.response.status === 401 && !config._retry) {
        console.log("attempting reauthentication")
        config._retry = true;
        try {
            const refresh_token = localStorage.getItem("REFRESH_TOKEN")
            if (refresh_token) {
                let res = await refreshToken(refresh_token);
                // TODO - reload page here?
                console.log("reautnenticated with refresh token", {res})
                if(res.data.access_token) {
                    localStorage.setItem("ACCESS_TOKEN", res.data.access_token)
                    if(res.data.refresh_token) {
                        localStorage.setItem("REFRESH_TOKEN", res.data.refresh_token)
                    }
                    console.log("returning new axios logged in instance", {config})
                    return axiosLoggedInInstance(config);
                }
    
            }

        } catch (err) {
            return Promise.reject(err)
        }
        
        
    }
    return Promise.reject(error)
})

const handleAuthResponse = (response: AxiosResponse) => {
    if (response.status !== 200) {
        console.log(response)
        throw new Error("error")
    }
}

export const getAuthToken  = async (username: string, password: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/oa2/token/`,
        {
            grant_type: "password",
            username: username,
            password: password
        },
        {
            auth: {
                username: APPLICATION_CLIENT_ID,
                password: APPLICATION_CLIENT_SECRET,
            }
        }
    )
    handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const refreshToken  = async (refresh_token: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/oa2/token/`,
        {
            grant_type: "refresh_token",
            refresh_token: refresh_token,
        },
        {
            auth: {
                username: APPLICATION_CLIENT_ID,
                password: APPLICATION_CLIENT_SECRET,
            }
        }
    )
    handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const registerUser  = async (email: string, password: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/register/`,
        {
            email: email,
            password: password
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const confirmRegisterUser  = async (email: string, code: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/confirm_register/`,
        {
            email: email,
            code: code
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const requestResetPassword  = async (email: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/request_reset_password/`,
        {
            email: email,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const submitResetPassword  = async (email: string, code: string, password: string) => {
    const response = await axiosLoggedOutInstance.post(
        `/api/auth/submit_reset_password/`,
        {
            email: email,
            code: code,
            password: password,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const subscribeDataSet  = async (account_id: string, data_set_id: string, data_set_subscription_method_id?: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_set_subscriptions/`,
        {
            account_id,
            data_set_id,
            ...(data_set_subscription_method_id ? { data_set_subscription_method_id }: {}),
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const unsubscribeDataSet = async (account_id: string, data_set_id: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_set_subscriptions/`,
        {
            account_id,
            data_set_id,
            unsubscribe: true,
        }
    )
    // handleAuthResponse(response)
    console.log(response)
    return response
    // # curl -X POST -d "grant_type=password&username=admin&password=foobar" -u"appauth:auth" http://localhost:8000/oa2/token/

}

export const getAccounts  = () => axiosLoggedInInstance.get(`/api/crud/accounts/`)
export const getAccountsLoggedOut = (accessToken: string) => axiosLoggedOutInstance.get(
    `/api/crud/accounts/`,
    {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }
)

export const getFeatureFlagsLoggedOut = (accessToken: string) => axiosLoggedOutInstance.get(
    `/api/crud/feature_flags/`,
    {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }
)

export const createApiIntegration = async (account_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/api_integrations/`,
        {
            account_id,
            name,
        }
    )
    console.log("createApiIntegration", response)
    return response

}

export const createApiSimpleIntegration = async (account_id: string, name: string, type: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/api_simple_integrations/`,
        {
            account_id,
            name,
            type,
        }
    )
    console.log("createApiSimpleIntegration", response)
    return response

}

export const createDiscordWebhookIntegration = async (account_id: string, name: string, webhook_url: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/discord_webhook_integrations/`,
        {
            account_id,
            name,
            webhook_url,
        }
    )
    console.log("createDiscordWebhookIntegration", response)
    return response

}

export const createTelegramTokenIntegration = async (account_id: string, name: string, token: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/telegram_token_integrations/`,
        {
            account_id,
            name,
            token,
        }
    )
    console.log("createTelegramTokenIntegration", response)
    return response

}

export const createDataSet = async (data_provider_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_sets/`,
        {
            data_provider_id,
            name,
        }
    )
    console.log("createDataSet", response)
    return response

}

export const createDataProvider = async (account_id: string, name: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/data_providers/`,
        {
            account_id,
            name,
        }
    )
    console.log("createDataProvider", response)
    return response

}

export const createForecast = async (data_set_id: string, symbol: string, move: number, minutes: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/forecasts/`,
        {
            data_set_id,
            symbol,
            move,
            minutes,
        }
    )
    console.log("createForecast", response)
    return response

}

export const createSignal = async (data_set_id: string, symbol: string, value: number, ttl_minutes: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/signals/`,
        // `/api/async/signals/`,
        {
            data_set_id,
            symbol,
            value,
            ttl_minutes,
            // Force a context...
            // foo: 2, nice: 1, yeah: -5, okay: 4000, direction: 1,
            // some_really_long_description: 1, some_other_long_one: 5.00, another_longish_one_that_I_keep_adding_to: -27.07372611
        }
    )
    console.log("createSignal", response)
    return response

}

export const createWorkflow = async (account_id: string, name: string, config: WorkflowConfig) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/workflows/`,
        {
            account_id,
            name,
            config,
        }
    )
    console.log("createWorkflow", response)
    return response

}

export const createDashboard = async (account_id: string, name: string, config: DashboardConfig) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/dashboards/`,
        {
            account_id,
            name,
            config,
        }
    )
    console.log("createDashboard", response)
    return response

}

export const createStoreCartWithItem  = async (account_id: string, item_id: string, recurring?: SubscriptionRecurring, quantity?: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/store_carts/`,
        {
            account_id,
            item_id,
            ...(recurring ? { recurring } : {}),
            ...(quantity ? { quantity } : {}),
        }
    )
    console.log(response)
    return response

}

export const addToStoreCart  = async (cart_id: string, item_id: string, recurring?: SubscriptionRecurring, quantity?: number) => {
    const response = await axiosLoggedInInstance.post(
        `/api/crud/store_cart_items/`,
        {
            cart_id,
            item_id,
            ...(recurring ? { recurring } : {}),
            ...(quantity ? { quantity } : {}),
        }
    )
    console.log(response)
    return response

}

interface CartItemUpdateValues {
    quantity?: number
    recurring?: SubscriptionRecurring
}

export const updateStoreCartItem  = async (cart_item_id: string, update_values: CartItemUpdateValues) => {
    const response = await axiosLoggedInInstance.patch(
        `/api/crud/store_cart_items/${cart_item_id}/`,
        update_values
    )
    console.log(response)
    return response

}


// post_data = {"account_id": str(account.id), "prompt": prompt_text}

// response = api_client.post("/api/ai/prompt/", post_data, format="json")
// assert response.status_code == 200, response.data
// assert response.data["message"] == "OK", response.data
// assert response.data["prompt_response"] == "heyoo", response.data

export const aiPrompt  = async (account_id: string, prompt: string) => {
    const response = await axiosLoggedInInstance.post(
        `/api/ai/prompt/`,
        {
            account_id,
            prompt,
        }
    )
    console.log(response)
    return response

}


interface getForecastsRequest {
    data_set?: string
    symbol?: string
    symbol__icontains?: string
    updated_date_gt?: string
    include_public?: boolean
    include_nested_data_set?: boolean
    limit?: number
    ordering?: string
}

interface getWorkflowsRequest {
    id?: string
    name?: string
    limit?: number
    ordering?: string
}

interface getDashboardsRequest {
    id?: string
    name?: string
    limit?: number
    ordering?: string
    is_public?: number
}

interface getDataSetDashboardsRequest extends getDashboardsRequest {
    data_set_id?: string
    include_public?: number
}

interface getStoreCartsRequestParams {
    'status!'?: string
    status?: string
}

interface ListResponse<RecordType extends object> {
    count?: number
    results?: RecordType[]
}

interface AxiosListResponse<RecordType extends object> {
    data: ListResponse<RecordType>
    status: number
}

interface AxiosResourceResponse<RecordType extends object> {
    data: RecordType
    status: number
}

interface dataSetPatchRequestParams {
    name?: string
    is_public?: number
    is_published?: number
    description?: string
}

interface workflowPatchRequestParams {
    name?: string
    enabled?: number
    config?: WorkflowConfig
}

interface dashboardPatchRequestParams {
    name?: string

    config?: DashboardConfig
}

interface getWorkflowExecutionsRequestParams {
    workflow_id?: string
    limit?: number
    ordering?: string
}

interface getDataSetsRequestParams {
    name__icontains?: string
}



export const getForecasts  = (params: getForecastsRequest): Promise<AxiosListResponse<Forecast>> => axiosLoggedInInstance.get(`/api/crud/forecasts/`, { params })
export const getForecastsLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<Forecast>> => axiosLoggedOutInstance.get(`/api/crud/forecasts/`, { params })
export const getSignals  = (params: getForecastsRequest): Promise<AxiosListResponse<Signal>> => axiosLoggedInInstance.get(`/api/crud/signals/`, { params })
export const getSignalsLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<Signal>> => axiosLoggedOutInstance.get(`/api/crud/signals/`, { params })
export const getSignalHistory  = (params: getForecastsRequest): Promise<AxiosListResponse<SignalHistory>> => axiosLoggedInInstance.get(`/api/crud/signal_history/`, { params })
export const getSignalHistoryLoggedOut  = (params: getForecastsRequest): Promise<AxiosListResponse<SignalHistory>> => axiosLoggedOutInstance.get(`/api/crud/signal_history/`, { params })
export const getSubscriptions = () => axiosLoggedInInstance.get(`/api/crud/data_set_subscriptions/`)
export const getDeliveryMechanisms = () => axiosLoggedInInstance.get(`/api/crud/delivery_mechanisms/`)
export const getDataSets = (params?: getDataSetsRequestParams) => axiosLoggedInInstance.get(`/api/crud/data_sets/`, { params: params ? params : {} })
export const getDataSet = (id: string) => axiosLoggedInInstance.get(`/api/crud/data_sets/${id}/`)
export const getDataProviders = () => axiosLoggedInInstance.get(`/api/crud/data_providers/`, { params: {my_provider: 1} })
export const getApiIntegrations = () => axiosLoggedInInstance.get(`/api/crud/api_integrations/`)
export const getIntegrations = () => axiosLoggedInInstance.get(`/api/crud/integrations/`, { params: {ordering: '-created_date' }})
export const getApiStatus  = () => axiosLoggedOutInstance.get(`/api/api_status/`)
export const deleteApiApplicationIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/api_integrations/${id}/`)
export const deleteApiSimpleIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/api_simple_integrations/${id}/`)
export const deleteDiscordWebhookIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/discord_webhook_integrations/${id}/`)
export const deleteTelegramTokenIntegration = (id: string) => axiosLoggedInInstance.delete(`/api/crud/telegram_token_integrations/${id}/`)
export const editDataSet = (id: string, params: dataSetPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/data_sets/${id}/`, params )
export const findDataSetSubscriptionMethod = (id: string) => axiosLoggedInInstance.get(`/api/crud/data_set_subscription_methods/`, {params: {id}})
export const getWorkflows  = (params: getWorkflowsRequest): Promise<AxiosListResponse<Workflow>> => axiosLoggedInInstance.get(`/api/crud/workflows/`, { params })
export const editWorkflow = (id: string, params: workflowPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/workflows/${id}/`, params )
export const deleteWorkflow = (id: string) => axiosLoggedInInstance.delete(`/api/crud/workflows/${id}/`)
export const getWorkflow = (id: string): Promise<AxiosResourceResponse<Workflow>> => axiosLoggedInInstance.get(`/api/crud/workflows/${id}/`)
export const getWorkflowExecutions = (params: getWorkflowExecutionsRequestParams): Promise<AxiosListResponse<WorkflowExecution>> => axiosLoggedInInstance.get(`/api/crud/workflow_executions/`, { params })
export const getDashboards  = (params: getDashboardsRequest): Promise<AxiosListResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/dashboards/`, { params })
export const editDashboard = (id: string, params: dashboardPatchRequestParams) => axiosLoggedInInstance.patch(`/api/crud/dashboards/${id}/`, params )
export const deleteDashboard = (id: string) => axiosLoggedInInstance.delete(`/api/crud/dashboards/${id}/`)
export const getDashboard = (id: string): Promise<AxiosResourceResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/dashboards/${id}/`)
export const getStripePortal  = (account_id: string) => axiosLoggedInInstance.get(`/api/stripe/portal/`)
export const getStripeCheckout  = (cart_id: string) => axiosLoggedInInstance.get(`/api/stripe/checkout/`,  { params: {cart_id} })
export const getAccountBillingMethod = (account_id: string): Promise<AxiosListResponse<AccountBillingMethod>>  => axiosLoggedInInstance.get(`/api/crud/account_billing_methods/`)
export const getStoreItems = (): Promise<AxiosListResponse<StoreItem>>  => axiosLoggedInInstance.get(`/api/crud/store_items/`)
export const getStoreCarts = (params: getStoreCartsRequestParams): Promise<AxiosListResponse<StoreCart>>  => axiosLoggedInInstance.get(`/api/crud/store_carts/`, { params})
export const getStoreCartItems = (cart_id: string): Promise<AxiosListResponse<StoreCartItem>>  => axiosLoggedInInstance.get(`/api/crud/store_cart_items/`, { params: { cart: cart_id }})
export const getAccountItemPurchases = (): Promise<AxiosListResponse<AccountItemPurchase>>  => axiosLoggedInInstance.get(`/api/crud/account_item_purchases/`)
export const deleteStoreCartItem = (id: string) => axiosLoggedInInstance.delete(`/api/crud/store_cart_items/${id}/`)
export const getFeatureFlags = (): Promise<AxiosListResponse<FeatureFlag>>  => axiosLoggedInInstance.get(`/api/crud/feature_flags/`)
export const getDataSetDashboards  = (params: getDataSetDashboardsRequest): Promise<AxiosListResponse<Dashboard>> => axiosLoggedInInstance.get(`/api/crud/data_set_dashboards/`, { params })

// export const signUp = async (userData) => {
//     const response = await axiosInstance.post(`/api/auth/sign_up/`, {
//         email: userData.username,
//         password: userData.password,
//         name: userData.attributes.name ?? '',
//     })
//     handleAuthResponse(response)
//     return response
// }
