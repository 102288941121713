import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { DataProviderList } from '../../features/dataProviderList/DataProviderList'
import { DataSetList } from '../../features/dataSetList/DataSetList';
import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';
import { DiscordLink } from '../../components/DiscordLink'


export function DataProviding() {

  const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
  const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
  const blocked = <Tooltip title="Awaiting Data"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
  const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

  return (
    <div className="App">
      <div className="App-content">
        <Typography.Title level={1}>Data and Signal Providing</Typography.Title>
        <div className="App-page-content">
            <Typography.Paragraph>
                Provide data and signals and make money. <a>Learn more <ArrowRightOutlined /></a>
            </Typography.Paragraph>
            {/* <h2>Steps:</h2> */}
            <Typography.Paragraph>{check} Register as a Data Provider</Typography.Paragraph>
            <DataProviderList />
            <Typography.Paragraph>{check} Create a Data Set and define Terms of Use and Fees Structure</Typography.Paragraph>
            <DataSetList include_buttons={true} />

            <ul>
                <li>Crypto Sigs Marketing (public use allowed, attributed-revenue-share model)</li>
                <li>Crypto Sigs (private use only, 0.01 BTC per month)</li>
                <li>Stonk Cigs (private use only, $25 per month)</li>
            </ul>
            <Typography.Paragraph>{check} Create API Keys or Data Loader</Typography.Paragraph>
            <ApiIntegrationsList />
            <Typography.Paragraph>{waiting} Send Data Sample</Typography.Paragraph>
            <Typography.Paragraph>{waiting} Await Data Validation (manually performed by our teams, come talk to us in <DiscordLink />)</Typography.Paragraph>
            <Typography.Paragraph>{blocked} Go Live</Typography.Paragraph>
            
        </div>
      </div>
    </div>
  );
}
