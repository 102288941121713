import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
// import ReactGA from "react-ga";
import './theme.less'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'antd/dist/antd.less'
// import './theme.less'
import { store } from './app/store'
import { Provider } from 'react-redux'


if (GOOGLE_ANALYTICS_ID !== 'none') {
  console.log("initializing GA", {GOOGLE_ANALYTICS_ID})
  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    // debug: true,
    // titleCase: false,
  });
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // Removed StrictMode https://stackoverflow.com/questions/72369908/why-is-useeffect-running-twice
  // https://reactjs.org/docs/strict-mode.html
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
