import { CheckCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, RightCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Button, Col, Radio, Row, Tooltip, Typography, Card, Input, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Link, useLocation } from "react-router-dom";
import { selectAccount } from '../../features/login/loginSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import ReactMarkdown from 'react-markdown'

import { selectLight } from '../../features/lightDarkSwitch/lightDarkSwitchSlice';
import {
    openCart,
    closeCart,
    addToCart,
    selectCartId,
    selectCartItems,
    selectCartOpen,
  } from '../../features/cart/cartSlice';
import { StoreCartItem, StoreItem, SubscriptionRecurring, AccountItemPurchase } from '../../types/types';
import { effectiveMonthlyUnitPrice, effectiveYearUnitPrice } from '../../utils'
import { getStoreItems, getAccountItemPurchases } from '../../services/signalApi';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}

interface StoreItemParams {
    storeItem: StoreItem
    existingCartSubscriptionTimeframe: SubscriptionRecurring | null
    existingAccountItemPurchases: AccountItemPurchase[]
}

export function StoreItemElement({
    storeItem,
    existingCartSubscriptionTimeframe,
    existingAccountItemPurchases,
}: StoreItemParams) {
    const dispatch = useAppDispatch();
    // const subscriptionTimeframe = 'year'
    const account = useAppSelector(selectAccount);
    const [subscriptionTimeframe, setSubscriptionTimeframe] = useState<SubscriptionRecurring>('year');
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        if (!!existingCartSubscriptionTimeframe) {
            setSubscriptionTimeframe(existingCartSubscriptionTimeframe)
        }
    }, [existingCartSubscriptionTimeframe])

    const itemButton = () => {
        // const link = account ? `/account?select_plan=${plan}_${subscriptionTimeframe}` : `?signup=1`
        let buttonText = 'Add to Cart'
        let disabled = false

        if (!account) {
            disabled = true
            buttonText = 'Log in to Purchase'
        }

        if (storeItem.multiple_disallowed === 1 && existingAccountItemPurchases.length > 0) {
            disabled = true
            buttonText = 'Already Purchased'
        }

        if (storeItem.available === 0) {
            disabled = true
            buttonText = 'Not Available'
        }

        const button = <Button
            icon={<ShoppingCartOutlined />}
            onClick={() => {dispatch(addToCart({item: storeItem, recurring: subscriptionTimeframe, quantity}))}}
            disabled={disabled}
            size="large"
            type={'default'}
            style={{width: '100%', borderRadius: '8px', height: '60px'}}
        >
            {buttonText}
        </Button>

        return button

    }

    const onChangeSubscriptionTimeframe = (e: RadioChangeEvent) => {
        // setLocalDateFilter(e.target.value)
        console.log("onChangeSubscriptionTimeframe", e)
        setSubscriptionTimeframe(e.target.value)
    }

    const subscriptionTimeframeOptions = [
        ...(storeItem.lifetime_price_cents ? [{ label: 'One Time', value: 'lifetime', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'lifetime'}] : []),
        ...(storeItem.month_price_cents ? [{ label: 'Monthly', value: 'month', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'month'}] : []),
        ...(storeItem.year_price_cents ? [{ label: 'Annual', value: 'year', disabled: !!existingCartSubscriptionTimeframe && existingCartSubscriptionTimeframe !== 'year'}] : []),
    ]



    return <Card title={storeItem.name} bordered={false} style={{
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        // boxShadow: '0 4px 8px 0 rgba(180, 180, 180, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '6px'
    }}>

        <Typography.Title level={1} style={centeredTextStyling}>{effectiveMonthlyUnitPrice(storeItem, subscriptionTimeframe)}<span style={{fontSize: '0.6em', color: '#888'}}>{subscriptionTimeframe !== 'lifetime' ? '/month' : ''}</span></Typography.Title>
        {/* <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>Basic offering subject to change.</Typography.Paragraph> */}
        {subscriptionTimeframe !== 'lifetime' && (
            <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>{effectiveYearUnitPrice(storeItem, subscriptionTimeframe)} / year{subscriptionTimeframe === 'year' ? ' billed annually' : ' billed monthly'}</Typography.Paragraph>
        )}
        {subscriptionTimeframe === 'lifetime' && (
            <Typography.Paragraph style={{fontSize: '0.75em', color: '#888', ...centeredTextStyling}}>One Time</Typography.Paragraph>
        )}
        
        <Row>
            <Col span={24} style={{justifyContent: 'center', display: 'flex'}}>
            {/* <Radio.Group onChange={onChange} defaultValue="annual"> */}
                <Space>
                    {storeItem.multiple_disallowed !== 1 && (
                        <Tooltip placement='top' title='Desired Quantity'>
                            <Input type='number' defaultValue={1} style={{width: '80px'}} onChange={(e) => {setQuantity(parseInt(e.target.value, 10))}} />
                        </Tooltip>
                    )}
                    <Radio.Group options={subscriptionTimeframeOptions} defaultValue={subscriptionTimeframe} value={subscriptionTimeframe} optionType="button" onChange={onChangeSubscriptionTimeframe} />

                </Space>

            </Col>
        </Row>
        <div style={{padding: '12px 20px 30px'}}>
            {itemButton()}

            {/* <Button disabled={false} size="large" style={{width: '100%', borderRadius: '8px', height: '60px'}}>Downgrade</Button> */}
        </div>
        <div style={{minHeight: "80px"}}>

            <Typography.Paragraph style={{}}>
                <ReactMarkdown>{storeItem.description}</ReactMarkdown>
            </Typography.Paragraph>

            {existingAccountItemPurchases.length > 0 && (
                <div style={{border: '1px solid #888', padding: '8px'}}>
                    <Typography.Paragraph>
                        Note: You already own this.
                    </Typography.Paragraph>
                    <ul>
                        {existingAccountItemPurchases.map((existingAccountItemPurchase) => (
                        <li>
                            {existingAccountItemPurchase.quantity} purchased on {DateTime.fromISO(existingAccountItemPurchase.created_date).toLocaleString(DateTime.DATETIME_MED)}
                        </li>

                        ))}
                    </ul>
                </div>
                
            )}
        </div>
        {/* {item.detail ? <Typography.Paragraph>{item.detail}</Typography.Paragraph> : <></>} */}

    </Card>
}

export function Store() {
    let location = useLocation();
    const account = useAppSelector(selectAccount);
    // const dispatch = useAppDispatch();
    const cartItems = useAppSelector(selectCartItems);

    // const [subscriptionTimeframe, setSubscriptionTimeframe] = useState<SubscriptionRecurring>('year');

    // const light = useAppSelector(selectLight)
    // const [subscriptionTimeframe, setSubscriptionTimeframe] = useState<'year' | 'month'>('year');
    // const [accountBillingMethod, setAccountBillingMethod] = useState<AccountBillingMethod | null>(null);

    const [storeItems, setStoreItems] = useState<StoreItem[] | null>(null);
    const [isLoadingStore, setIsLoadingStore] = useState<boolean>(false);
    const [accountItemPurchases, setAccountItemPurchases] = useState<AccountItemPurchase[] | null>(null);

    useEffect(() => {document.title = 'FreqSignals | Plans and Pricing'}, [])

    useEffect(() => {
        setIsLoadingStore(true)
        getStoreItems().then((res) => {
            console.log("getStoreItems", {res})
            setIsLoadingStore(false)
            if (res.status === 200) {
                const response_data = res.data
                if (response_data.results) {
                    setStoreItems(response_data.results)
                }
            }
        })
        getAccountItemPurchases().then((res) => {
            console.log("getAccountItemPurchases", {res})
            if (res.status === 200) {
                const response_data = res.data
                if (response_data.results) {
                    setAccountItemPurchases(response_data.results)
                }
            }
        })
    }, [account?.id]);

    const existingCartSubscriptionTimeframe = (cartItems || [])[0]?.recurring

    const biggestSavingPct = Math.floor((storeItems || []).reduce((agg, storeItem) => {
        if (storeItem.month_price_cents && storeItem.year_price_cents) {
            const yearOnMonthly = storeItem.month_price_cents * 12
            const diff = yearOnMonthly - storeItem.year_price_cents
            const pctDiff = diff * 100 / yearOnMonthly
            if (pctDiff > agg) {
                return pctDiff
            }
        }
        return agg
    }, 0)).toFixed(0)

    // const onChangeSubscriptionTimeframe = (e: RadioChangeEvent) => {
    //     // setLocalDateFilter(e.target.value)
    //     console.log("onChangeSubscriptionTimeframe", e)
    //     setSubscriptionTimeframe(e.target.value)
    // }

    return (
        <div className="App">
        <div className="App-content">
            <div className="App-page-content">
                <Row>
                    <Col span={24} style={centeredTextStyling} >
                        <Typography.Title level={1}>FreqSignals Store</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={centeredTextStyling} >
                        <Typography.Paragraph>Monthly and Annual Subscription Packages for the level of analysis and automation you need.</Typography.Paragraph>
                    </Col>
                </Row>

                <Row
                    style={{marginTop: '30px'}}
                    gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                    // justify="space-around"
                    justify="start"
                    align="top"
                >
                    {isLoadingStore && (
                        <Loading />
                    )}
                    {storeItems?.map((storeItem, idx) => (
                        <Col span={8} sm={24} md={12} lg={12} xl={8} xs={24} key={storeItem.id}>
                            <StoreItemElement storeItem={storeItem} existingCartSubscriptionTimeframe={existingCartSubscriptionTimeframe} existingAccountItemPurchases={(accountItemPurchases || []).filter((aip) => aip.item_id == storeItem.id)}/>
                        </Col>
                    ))}
                
                </Row>

                <Row>
                    <Col span={24} style={{marginTop: '30px', ...centeredTextStyling}} >
                        <Typography.Title level={1}>Frequently Asked Questions</Typography.Title>
                    </Col>
                </Row>

                <Row
                    style={{marginTop: '30px'}}
                    gutter={[{ xs: 8, sm: 24, md: 24, lg: 64, xl: 80 }, { xs: 8, sm: 24, md: 24, lg: 64, xl: 80 }]}
                    // justify="space-around"
                    justify="start"
                    align="top"
                >
                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can I have Monthly and Annual Subscriptions?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes! And they don't need to be in any type of alignment. But, in a single order and what is loaded in your cart, all items must have the same subscription duration currently. So, if you want an annual subscription for a new feature, but monthly for more dashboards, complete the annual purchase first and then you can buy the monthly after.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can I purchase subscriptions with Cryptocurrency?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes! To reduce on fees, this option is encouraged for annual subscriptions only. Put what you'd like in a cart and then contact us for instructions on submitting a Cryptocurrency payment. We accept Bitcoin, Bitcoin Cash, Ethereum, Dogecoin, Litecoin and some others (ask us!).</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>When I buy a subscription, is my price locked in?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes! Your price will always be locked in for what you purchase. If we increase prices in the future, you will be grandfathered in at your current price (sweet!). If your subscription lapses or you cancel and you wish to renew it, you will be able to purchase at the current prices.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can you tell me more about a specific subscription or feature?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes, contact us and we'd be happy to answer any questions you have.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>What happens if I cancel a subscription?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>If you cancel a subscription for a feature, you won't have access to that feature anymore. Any workflows using that feature will be paused. Any dashboards using that feature will be limited. If your subscription granted additional workflows, they will be paused. If your subscription granted additional Dashboards, they will still be visible, but not editable, and you won't be able to create anymore until you delete to get under your cap. If your subscription granted additional Data Sets, they will still exist, but you will be unable to create any additional ones until you delete to get under your cap.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can I stack duplicative packages?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes! This is a great way to get more for less money. If you get multiple of a package that grants 10 workflows and a feature, you will get 10 workflows for every one you purchase. The feature will only be granted once. Some packages can only be purchased once, like ones that only grant access to a feature, you will see there is no option to select multiple for those packages.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Where can I see what I already have bought?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Go to your Account and Billing page to see all your purchases and when they expire and your current applied caps and features.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>I previously saw an offer that I no longer see here, what gives?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>FreqSignals changes offerings frequently, some offers are only available for a limited time. If you want something you don't see, contact us and we'll see what we can do.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>How do I cancel my subscription?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>If you paid with Stripe, go to your Account and Billing page and click on "Manage Your Subscription" to prevent future billings.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can you build X feature?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>We love hearing what our customers want to see more of. Please contact us so we can get your ask on our backlog.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can I try X feature before buying?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Probably! Contact us with some information about yourself and what you want to do and how long you want to try it for and we'll see what we can do.</Typography.Paragraph>
                    </Col>

                    <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24} >
                        <Typography.Paragraph strong style={centeredTextStyling}>Can I buy something for someone else?</Typography.Paragraph>
                        <Typography.Paragraph style={centeredTextStyling}>Yes! You can gift a purchase to someone else. Please contact us before purchasing so we can make sure you're not buying them something they already have.</Typography.Paragraph>
                    </Col>
                
                </Row>
                
            </div>
        </div>
        </div>
    );
}
