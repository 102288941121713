import React, { useState } from 'react';
import { Button } from 'antd';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { HomeOutlined, ReloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import { Link } from "react-router-dom";

// import {
//   loadDataSetsAsync,
//   clear,
//   selectDataSets,
//   selectStatus,
//   subscribeDataSetAsync,
//   unsubscribeDataSetAsync,
// } from './dataProviderListSlice';
import {
    loadDataProvidersAsync,
    selectDataProviders,
    selectStatus,
} from './dataProviderListSlice'
import { selectAccount } from '../login/loginSlice'
import { Col, Divider, Row, Space, Table, Form, Input, Modal, Checkbox } from 'antd';
import {DataProviderLink} from '../../components/DataProviderLink'
import { DataProvider } from '../../types/types';

// TODO - this shouldn't be a feature? should just manage state locally?
export interface DataProviderListProps {
    include_buttons?: boolean
}


export function DataProviderList({
    include_buttons = true,
}: DataProviderListProps) {
    const data_providers = useAppSelector(selectDataProviders);
    const status = useAppSelector(selectStatus);
    const account = useAppSelector(selectAccount);
    // const dataProviders = useAppSelector(selectDataProviders);
    // const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false);
    // const [subscribingDataSet, setSubscribingDataSet] = useState<DataProvider | null>(null);
    // const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    console.log(data_providers)
    console.log(status)

    const fetch_click = () => {
        dispatch(loadDataProvidersAsync())
    }

    if (status === "idle" && data_providers === null){
        dispatch(loadDataProvidersAsync())
    }


    const head = (
        <Typography.Title level={1}>Providers</Typography.Title>
    )

    const content = () => {

        if (data_providers === null || data_providers.length === 0){
            return (
                <div>
                    <Typography.Paragraph>Nothing Loaded.</Typography.Paragraph>
                </div>
            )
        }

        if (Array.isArray(data_providers) === false){
            return (
                <div>
                    <Typography.Paragraph>Nothing Loaded.</Typography.Paragraph>
                </div>
            )
        }


        const keyed_data_providers = data_providers.map(v => ({key: v.id, ...v})) 

        const columns = [
            {
              title: 'Data Provider',
              dataIndex: 'data_provider_id',
              key: 'data_provider_id',
              render: (text: string, row: DataProvider) => {
                return <DataProviderLink data_provider={row} />
                // <Link to={`/data_providers/${row.id}/`}>{row.name}</Link>
              }
            //   render: (value: string, row: DataProvider) => <a>{row.data_provider.name}</a>

            },
            {
                title: 'Created Date',
                dataIndex: 'created_date',
                key: 'created_date',
            },
          ];
    
        return (            
            <Table
                dataSource={keyed_data_providers}
                columns={columns}
                loading={status==="loading"}
                pagination={{ hideOnSinglePage: true }}
                size="middle"
                bordered={true}
            />
        )
    }



    const buttons = (
        <Space>
            {/* <Button type="primary" className={styles.button} onClick={fetch_click}>Reload</Button> */}
            <Button type="default" onClick={fetch_click}><ReloadOutlined /></Button>
            {/* <Button type="default">Show Subscribed</Button>
            <Button type="default">Show Provided</Button> */}
            {/* <Button type="default">Filter</Button> */}
            {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
        </Space>
    )


    return (
        <>
            {/* {head} */}
            {include_buttons && buttons}
            {/* {buttons} */}
            {content()}
        </>
    )

    
}
