import type { MenuProps } from 'antd';
import { Avatar, Col, Descriptions, List, Row, Space, Tag, Tooltip, Typography, Button } from 'antd';
import { createElement, ReactElement, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { TwitterOutlined, CommentOutlined } from '@ant-design/icons';

import type { RadioChangeEvent } from 'antd';

import { DateTime, Duration } from 'luxon';

import { Signal, DateFilter } from '../types/types';
// import styles from './SignalList.module.css';

import { CopyToClipboard } from './CopyToClipboard'
import { TweetThis } from './TweetThis'

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getSignals, getSignalsLoggedOut } from '../services/signalApi';
import {selectLight} from '../features/lightDarkSwitch/lightDarkSwitchSlice'
import {populatePrompt} from '../features/aiChat/aiChatSlice'

  
interface SignalQueryParams {
    include_public?: boolean
    context_filter?: string
}


interface SignalTextSummaryParams {
    name?: string
    data_set_id?: string
    symbol?: string
    addContextColumns?: boolean
    queryParams?: SignalQueryParams
    addButtons?: ReactElement
    defaultDateFilter?: DateFilter
    ordering?: string
    limit?: number
    template: string
}

interface Dictionary<T> {
    [Key: string]: T;
}


const getDateFilterValue = (dateFilter: DateFilter) => {
    const dateFilterValueMapping = {
        '30_days': 30,
        '7_days': 7,
        'today': 1,
    }
    const days = (dateFilterValueMapping[dateFilter] || 7)
    const dt = DateTime.now();
    return dt.minus({days}).plus({hours: 1}).toISODate()
}


export function SignalTextSummary({
    name,
    data_set_id,
    symbol,
    addContextColumns=false,
    queryParams,
    addButtons,
    defaultDateFilter,
    ordering="-updated_date",
    limit,
    template,
}: SignalTextSummaryParams) {
    const account = useAppSelector(selectAccount)
    const dispatch = useAppDispatch();
    const [reloadedLast, setReloadedLast] = useState('never');
    const [isLoading, setIsLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState<DateFilter>(defaultDateFilter ? defaultDateFilter : '7_days');
    const [symbolFilter, setSymbolFilter] = useState<string | null>(null);
    const [detailSignal, setDetailSignal] = useState<Signal | null>(null);
    const [signals, setSignals] = useState<Signal[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [signalsCount, setSignalsCount] = useState<number>(1);
    const light = useAppSelector(selectLight)

    // const pageSize = limit === undefined ? 20 : limit
    // const effectiveDateFilter = dateFilter ? dateFilter : defaultDateFilter;


    const showSignalDetails = (signal: Signal) => {
        setDetailSignal(signal)
    }

    const handleDateFilterRadioChange = (e: RadioChangeEvent) => {
        setDateFilter(e.target.value)
        
    }

    const handleDateFilterSelectChange = (value: DateFilter) => {
        setDateFilter(value)
    }


    console.log("SignalStream", {data_set_id, name, symbol, queryParams, defaultDateFilter, ordering})

    // const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (defaultDateFilter) {
            setDateFilter(defaultDateFilter)
        }
    }, [defaultDateFilter])

    useEffect(() => {
        setIsLoading(true)

        const getSignalsFn = account ? getSignals : getSignalsLoggedOut;
        setTimeout(() => {
            getSignalsFn({
                ...(data_set_id ? {data_set: data_set_id} : {}),
                ...(symbol ? {symbol: symbol} : {}),
                ...(!symbol && symbolFilter ? {symbol__icontains: symbolFilter} : {}),
                ...(dateFilter ? {updated_date__gt: getDateFilterValue(dateFilter)} : {}),
                ...(limit ? {limit}: {}),
                include_nested_data_set: true,
                // limit: pageSize,
                // ...(page > 1 ? {offset: (page - 1) * pageSize}: {}),
                ...queryParams,
                ordering,
            }).then((res) => {
                console.log("signals", {res})
                const response_data = res.data.results
                setIsLoading(false)
                setSignals(response_data || [])
                if (res.data.count) {
                    setSignalsCount(res.data.count)
                }
            }
            ).catch(() => {
                setSignals([])
            }).finally(() => {
                setIsLoading(false)
            })
        }, Math.floor(Math.random() * 1000))
    // }, 5000)
    }, [JSON.stringify({account}), reloadedLast, dateFilter, page, symbolFilter, ordering, data_set_id, JSON.stringify({queryParams}), limit])

    const reloadData = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const head = (
        name ? <Typography.Title level={1}>{name}</Typography.Title> : <></>
    )

    const formatTemplate = (keyedSignals: Signal[]): string => {
        const symbols = keyedSignals.map((signal) => signal.symbol)
        const symbol_str = symbols.join(', ')
        const symbol_cashtags = symbols.map((s) => `$${s}`).join(', ')
        return template.replace("{SYMBOLS}", symbol_str).replace("{SYMBOL_CASHTAGS}", symbol_cashtags)
    }

    const signalSummary = (keyedSignals: Signal[]) => {
        const text = formatTemplate(keyedSignals)

        return <>
            <Typography.Paragraph style={{}}>
                <ReactMarkdown>{text}</ReactMarkdown>
            </Typography.Paragraph>
            <Space>
                <CopyToClipboard text={text} content={<Button>Copy</Button>} />
                <TweetThis text={text} url='https://freqsignals.com' related='freqsignals' />
                <Button icon={<CommentOutlined />} onClick={() => {dispatch(populatePrompt({prompt: text}))}}>Prompt ChatGPT</Button>
            </Space>
        </>
    }

    const content = () => {

        if (signals === null || signals.length === 0){
            if (isLoading) {
                return <Typography.Paragraph style={{}}>
                    Loading...
                </Typography.Paragraph>
            }

            return <Typography.Paragraph style={{}}>
                No matching Signals
            </Typography.Paragraph>
            
        }
        const keyedSignals = signals.map(v => ({key: v.id, ...v}))
        return signalSummary(keyedSignals)
    }

    return (
        <>
            <Row>
                <Col xs={{span: 24, offset: 0}}>
                    {content()}
                </Col>
            </Row>
        </>
    )
    
    
}
