import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { DateTime } from 'luxon';

import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip, Typography, Row, Col, Radio, Button, Descriptions, Card, Space, Select, List, Alert, Avatar } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { AccountBillingMethod, SubscriptionRecurring, AccountItemPurchase, FeatureFlag } from '../../types/types';
import { effectiveMonthlyUnitPrice, effectiveYearUnitPrice, cartItemUnitPrice, cartItemTotalPrice, cartTotalPrice, toTitleCase } from '../../utils'
import { selectAccount, selectFeatureFlags } from '../../features/login/loginSlice';
import {selectLight} from '../../features/lightDarkSwitch/lightDarkSwitchSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getStripePortal, getStripeCheckout, getAccountBillingMethod, getAccountItemPurchases, getFeatureFlags } from '../../services/signalApi';

import { SmileOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import { DiscordLink } from '../../components/DiscordLink'


// drop-down to select new plan, once selected, gets price and shows link

// interface PlanInfo {
//     amount: number
//     id: string
//     name: string
//     recurring: null | 'year' | 'month'
// }

// interface PlanName {
//     subscriptionId: SubscriptionId
//     displayName: string
// }

// const planNames: PlanName[] = [
//     {subscriptionId: 'basic', displayName: 'Basic'},
//     {subscriptionId: 'pro_one_time', displayName: 'Pro One Month'},
//     {subscriptionId: 'pro_month', displayName: 'Pro Monthly'},
//     {subscriptionId: 'pro_year', displayName: 'Pro Yearly'},
//     {subscriptionId: 'elite_one_time', displayName: 'Elite One Month'},
//     {subscriptionId: 'elite_month', displayName: 'Elite Monthly'},
//     {subscriptionId: 'elite_year', displayName: 'Elite Yearly'}
// ]
// const planSubscriptionIds: SubscriptionId[] = planNames.map((plan) => plan.subscriptionId)


export function Account() {
    let location = useLocation();
    const account = useAppSelector(selectAccount);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const light = useAppSelector(selectLight)
    const [stripePortalUrl, setStripePortalUrl] = useState<string | null>(null);
    const [stripeSuccess, setStripeSuccess] = useState<boolean | null>(null);
    const [stripeUpgradeUrl, setStripeUpgradeUrl] = useState<string | null>(null);
    const [accountBillingMethod, setAccountBillingMethod] = useState<AccountBillingMethod | null>(null);
    const [accountItemPurchases, setAccountItemPurchases] = useState<AccountItemPurchase[] | null>(null);
    // const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | null>(null);
    // const [selectedPlanInfo, setSelectedPlanInfo] = useState<PlanInfo | null>(null);
    // const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<SubscriptionId | null>(null);

    useEffect(() => {
        if (account) {
            getAccountBillingMethod(account.id).then((res) => {
                console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        const accountBilling = response_data.results[0]
                        setAccountBillingMethod(accountBilling)
                    }
                }
            })
            getAccountItemPurchases().then((res) => {
                console.log("getAccountItemPurchases", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        setAccountItemPurchases(response_data.results)
                    }
                }
            })
        }
    }, [account?.id]);

    const loadStripePortal = () => {
        if (account) {
            return getStripePortal(account.id).then((res) => {
                console.log("getStripePortal", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    setStripePortalUrl(response_data.url)
                }
            })
        }
        return new Promise(res => {
            return res(true)
        })
    }

    const loadAccountBilling = () => {
        if (account) {
            getAccountBillingMethod(account.id).then((res) => {
                console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        const accountBilling = response_data.results[0]
                        setAccountBillingMethod(accountBilling)
                    }
                }
            })
        }
        return new Promise(res => {
            return res(true)
        })
    }

    const onAddStripe = () => {
        loadStripePortal().then(loadAccountBilling)
    }

    useEffect(() => {
        if (account && accountBillingMethod?.billing_method === 'stripe') {
            loadStripePortal()
        }
    }, [account?.id, accountBillingMethod?.billing_method])

    // useEffect(() => {
    //     if (account && selectedSubscriptionId) {
    //         const selectedPlan = getSubscriptionPlanFromSubscriptionId(selectedSubscriptionId)
    //         const selectedRecurring = getSubscriptionRecurringFromSubscriptionId(selectedSubscriptionId)
    //         getStripeCheckout(account.id, selectedPlan, selectedRecurring).then((res) => {
    //             console.log("getStripeCheckout", {res})
    //             if (res.status === 200) {
    //                 const response_data = res.data
    //                 setStripeUpgradeUrl(response_data.url)
    //                 setSelectedPlanInfo(response_data.price)
    //             }
    //         })
    //     }
    // }, [selectedSubscriptionId])

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        const urlSelectPlan = query.get('select_plan')
        const success = query.get('success')

        if (success === "1") {
            setStripeSuccess(true)
        }
    }, [window.location.search]);

    const upgradeStripePlanLink = () => {
        return <></>
        // if (
        //     selectedSubscriptionId !== null &&
        //     accountBillingMethod?.subscription_id &&
        //     accountBillingMethod.subscription_id !== selectedSubscriptionId
        //  ) {
        //     return <a {...(stripeUpgradeUrl === null ? {} : {target: 'blank', href: stripeUpgradeUrl})} style={{width: '100%'}} >Change Subscription on Stripe</a>
        // } else {
        //     return <></>
        // }
    }

    const stripeLink = () => {
        return <a {...(stripePortalUrl === null ? {} : {target: 'blank', href: stripePortalUrl})}>Manage Subscriptions on Stripe</a>

        // return <></>
        // if (selectedSubscriptionId === 'basic' || selectedSubscriptionId === null || selectedSubscriptionId === accountBillingMethod?.subscription_id) {
        //     return <a {...(stripePortalUrl === null ? {} : {target: 'blank', href: stripePortalUrl})}>Manage Subscriptions on Stripe</a>
        // }
        // const selectedRecurring = getSubscriptionRecurringFromSubscriptionId(selectedSubscriptionId)
        // const selectedPlan = getSubscriptionPlanFromSubscriptionId(selectedSubscriptionId)
        // const subscribedRecurring = getSubscriptionRecurringFromSubscriptionId(accountBillingMethod?.subscription_id || 'basic')
        // const subscribedPlan = getSubscriptionPlanFromSubscriptionId(accountBillingMethod?.subscription_id || 'basic')

        // if (selectedRecurring === 'one_time' && subscribedRecurring !== 'one_time' && selectedPlan !== 'elite') {
        //     return <Typography.Text>You already have a subscription.</Typography.Text>
        // }

        // return <a {...(stripeUpgradeUrl === null ? {} : {target: 'blank', href: stripeUpgradeUrl})} style={{width: '100%'}} >Confirm Subscription on Stripe</a>

    }

    const itemList = (accountItems: AccountItemPurchase[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-item-purchase-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={accountItems === null ? [] : accountItems}
            renderItem={(accountItem: AccountItemPurchase) => (
                <List.Item
                    key={accountItem.id}
                    // onClick={() => {showSignalDetails(signal)}}
                    // actions={[
                    //     ...(accountItem.item.multiple_disallowed === 1 ? [] : [
                    //         <Space>
                    //             <Input key={accountItem.quantity} type='number' defaultValue={accountItem.quantity} style={{width: '80px'}} onChange={(e) => {handleQuantitySelectionChange(accountItem, parseInt(e.target.value, 10))}} />
                    //             <Tooltip placement='right' title="Update Quantity">
                    //                 <Button disabled={!quantitySelectionChanged(accountItem)} icon={<CheckOutlined />} onClick={() => {updateCartItemQuantity(accountItem)}}/>

                    //             </Tooltip>
                    //         </Space>
                    //     ])
                        
                    // ]}
                    // extra={
                    //     <Tooltip placement='bottom' title="Remove from Cart">
                    //         <Button icon={<DeleteOutlined />} onClick={() => {deleteCartItem(accountItem)}}/>

                    //     </Tooltip>
                    //     // <span>${(accountItem.quantity * (accountItem.item.month_price_cents || 0) / 100).toFixed(2)}</span>
                    // }
                >
                    <List.Item.Meta
                        key={accountItem.id}
                        title={<span>{accountItem.quantity} x {accountItem.item.name}</span>}
                        description={
                            <>
                                <Typography.Paragraph>{accountItem.item.description}</Typography.Paragraph>
                                <Typography.Paragraph>Purchased On: {DateTime.fromISO(accountItem.created_date).toLocaleString(DateTime.DATETIME_MED)} | Expires: {accountItem.expiration_date ? DateTime.fromISO(accountItem.expiration_date).toLocaleString(DateTime.DATETIME_MED) : 'Never'}</Typography.Paragraph>
                                
                            </>
                        }
                    />

                </List.Item>
            )}
        />
    }

    const featureFlagList = (featureFlags: FeatureFlag[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-feature-flag-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            // itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={featureFlags === null ? [] : featureFlags}
            renderItem={(featureFlag: FeatureFlag) => (
                <List.Item
                    key={featureFlag.id}
                >
                    <List.Item.Meta
                        key={featureFlag.id}
                        avatar={<Avatar size='large' icon={<CheckOutlined style={{  }} />} />}
                        title={<span>{toTitleCase(featureFlag.code_name)}</span>}
                        description={featureFlag.description}
                    />

                </List.Item>
            )}
        />
    }


    return (
        <div className="App">
        <div className="App-content">
            <Typography.Title level={1}>Account</Typography.Title>
            <div className="App-page-content">



                <Space direction='vertical' style={{width: '100%'}}>

                        <Card
                            title={"Account Information"}
                        >
                            <Space direction='vertical' style={{width: '100%'}}>
                            <Descriptions column={24}>
                                <Descriptions.Item span={24} label="Account ID">{account?.id}</Descriptions.Item>
                                <Descriptions.Item span={24} label="Email">{account?.name}</Descriptions.Item>

                                <Descriptions.Item span={24} label="Data Provider Cap">{account?.data_provider_cap}</Descriptions.Item>
                                <Descriptions.Item span={24} label="Data Set Cap">{account?.data_set_cap}</Descriptions.Item>
                                <Descriptions.Item span={24} label="Dashboard Cap">{account?.dashboard_cap}</Descriptions.Item>
                                <Descriptions.Item span={24} label="Workflow Cap">{account?.workflow_cap}</Descriptions.Item>
                                <Descriptions.Item span={24} label="Daily AI Chat Cap">{account?.daily_ai_chat_cap}</Descriptions.Item>
                            </Descriptions>

                            </Space>
                            

                        </Card>


                    <Card
                        title={"Features"}
                    >
                        {featureFlags && featureFlags?.length > 0 && (
                            <Space direction='vertical' style={{width: '100%'}}>
                                <Typography.Paragraph>Learn more about the features available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>

                                {featureFlagList(featureFlags || [])}
                            </Space>
                        
                    )}

                    {(featureFlags === null || featureFlags?.length == 0) && (
                        <Typography.Paragraph>No features enabled. Learn more about the features available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>
                    )}
                    </Card>


                    <Card
                            title={"Billing"}
                            // extra={<a href="#">Editas</a>}

                            // bordered={light ? true : false}
                        >
                            <Typography.Paragraph>Learn more about the subscriptions available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>

                            <>
                                <Typography.Paragraph>Payments can be made via:</Typography.Paragraph>
                                <ul>
                                    <li><Typography.Text strong>Stripe: </Typography.Text>{accountBillingMethod?.billing_method === "stripe" ? <>You are configured to use Stripe. {stripeLink()}</> : <>Not Configured. <a onClick={() => {onAddStripe()}}>Click to Setup Billing with Stripe</a>. Your email address will be shared with Stripe.</>}</li>
                                    <li><Typography.Text strong>Popular Cryptocurrencies: </Typography.Text>Contact us if you would like to coordinate Cryptocurrency payment.</li>
                                    <li><Typography.Text strong>Invoice: </Typography.Text>Contact us if you would like to coordinate an invoice payment.</li>
                                </ul>
                                <Typography.Paragraph>Please contact us with any questions you have.</Typography.Paragraph>
                            </>

                            {accountBillingMethod?.billing_method === "stripe" && (
                                <>
                                    <Space direction='vertical' style={{width: '100%'}}>
                                        {stripeSuccess && (
                                            <Alert type='success' message={"Your plan has been updated. Thank you for subscribing to FreqSignals! Your change may take a moment to reflect here. Once the change is reflected here, your new access should be granted. If you encounter any issues or have any questions, please contact us."} />
                                        )}
                                        {accountItemPurchases && accountItemPurchases.length > 0 && (
                                            <>
                                                {itemList(accountItemPurchases || [])}
                                            </>
                                        )}
                                        {(!accountItemPurchases || accountItemPurchases.length == 0) && (
                                            <Typography.Paragraph>Your purchased items will show here.</Typography.Paragraph>
                                        )}
                                    </Space>
                                </>
                            )}
                        </Card>
                
                </Space> 
                
            
                
                
            </div>
        </div>
        </div>
    );
}
