import { Col, Row, Typography, Space } from 'antd';
import Icon, { TwitterOutlined, RedditOutlined, GithubOutlined, YoutubeOutlined, InstagramOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useIsBigScreen } from '../app/hooks'
import { LightDarkSwitch } from '../features/lightDarkSwitch/LightDarkSwitch';
import { CopyToClipboard } from '../components/CopyToClipboard'
import { default as DiscordIcon } from '../images/discord'
const { Paragraph: P } = Typography



function Section0() {
    const isBigScreen = useIsBigScreen()

    const firstSocial = <>
        <a href='https://twitter.com/freqsignals' target='blank'><TwitterOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://www.reddit.com/r/FreqSignals/' target='blank'><RedditOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://discord.gg/NR7pGp3QWr' target='blank'><Icon component={DiscordIcon} style={{ fontSize: '22px' }} /></a>
    </>

    const secondSocial = <>
        <a href='https://github.com/freqsignals' target='blank'><GithubOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://www.instagram.com/freqsignals/' target='blank'><InstagramOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://www.youtube.com/channel/UCuHNw_QCpmYRXOFWiEWVu1w' target='blank'><YoutubeOutlined style={{ fontSize: '22px' }} /></a>
    </>

    return (
        <>
            <Typography.Title level={3}>
                FreqSignals
            </Typography.Title>
            <Space size='middle'>
                {firstSocial}
                {secondSocial}
            </Space>
            <P></P>
            <P><a><CopyToClipboard text={"info@freqsignals.com"} content={"info@freqsignals.com"} placement='right'/></a></P>
            {/* <P style={{color: '#888'}}>Quotes are delayed. Content is not to be considered financial advice.</P>
            <P style={{color: '#888'}}>Copyright © 2022-2023 FreqSignals.com. </P>
            <P style={{color: '#888'}}>All Rights Reserved. </P> */}
            <P><a><LightDarkSwitch /></a></P>
        </>
    )
}

function Section1() {
    return (
        <>
            <Typography.Title level={3}>
                Offerings
            </Typography.Title>

            {/* <h2>Offerings</h2> */}
            {/* <P><Link to={`/integration_offerings`}>Integrations</Link></P>
            <P><Link to={`/trading_view_webhook`}>Trading View Webhook</Link></P>
            <P><Link to={`/discord_bot`}>Discord Bot</Link></P>
            <P><Link to={`/telegram_bot`}>Telegram Bot</Link></P>
            <P><Link to={`/twitter_bot`}>Twitter Bot</Link></P>
            <P><Link to={`/email_bot`}>Email Bot</Link></P>
            <P><Link to={`/freqtrade_crypto_bot`}>Freqtrade Crypto Bot Integration</Link></P> */}

            <P><Link to={`/integration_offerings`}>Integrations</Link></P>
            <P><Link to={`/documentation`}>Documentation</Link></P>
            <P><Link to={`/trading_view_webhook`}>Trading View</Link></P>
            <P><Link to={`/discord_bot`}>Discord</Link></P>
            <P><Link to={`/telegram_bot`}>Telegram</Link></P>
            {/* <P><Link to={`/twitter_bot`}>Twitter</Link></P> */}
            <P><Link to={`/email_bot`}>Email</Link></P>
            <P><Link to={`/freqtrade_crypto_bot`}>Freqtrade</Link></P>

            {/* <P><a>Enterprise</a></P> */}
            {/* <P><a>Personal</a></P> */}
            {/* <P><a>Social</a></P> */}
            {/* <P><a>Trading Bot Hosting</a></P> */}
            {/* <P><a>Community Managers</a></P> */}
        </>
    )
}


function Section2() {
    const isBigScreen = useIsBigScreen()
    return (
        <>
            {isBigScreen && (<h2>&nbsp;</h2>)}
            {/* <P><Link to={`/affiliate`}>Affiliate</Link></P> */}
            <P><Link to={`/advertising`}>Advertising</Link></P>
            <P><Link to={`/data_providing_1p_3p`}>Data Providing 1p vs 3p</Link></P>
            <P><Link to={`/on_demand_analysis`}>On-Demand Analysis</Link></P>
            <P><Link to={`/analysis_tracking`}>Analysis Tracking (pay per symbol)</Link></P>
            <P><Link to={`/custom_strategy`}>Custom Strategy &amp; Feature Development</Link></P>
            <P><Link to={`/enterprise`}>Enterprise Solutions</Link></P>
        </>
    )
}

function Section3() {
    return (
        <>
            {/* <h2>About</h2> */}
            <Typography.Title level={3}>
                About
            </Typography.Title>
            {/* <P><Link to={`/team`}>Team</Link></P> */}
            <P><Link to={`/pricing`}>Pricing</Link></P>
            <P><Link to={`/careers`}>Careers</Link></P>
            <P><Link to={`/investors`}>Investors</Link></P>
            <P><Link to={`/terms_and_conditions`}>Terms &amp; Conditions</Link></P>
            <P><Link to={`/privacy_policy`}>Privacy Policy</Link></P>
            <P><Link to={`/community`}>Community</Link></P>
            <P><Link to={`/contact_us`}>Contact Us</Link></P>
            {/* <P><Link to={`/pricing`}>Pricing</Link></P> */}
        </>
    )
}


export function FooterContent() {
    // TODO - sort out stacking responsiveness
    // span={6} for 4 columns. 24 columns. 1 of 4 and 4 of 5 could work. Need content.
    return (
        <>
            <Row className="footer-section" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section0 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section1 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section2 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section3 />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <P style={{textAlign: 'center'}}>Quotes are delayed. Content is not to be considered financial advice.
                </P>
                <P style={{textAlign: 'center'}}>Copyright © 2022-2023 FreqSignals.com. All Rights Reserved. </P>
                </Col>
            </Row>
        </>
    )
}
