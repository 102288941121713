import { Carousel, Typography, theme } from 'antd';
import { Link } from "react-router-dom";
import { useIsBigScreen } from '../app/hooks'

const { useToken } = theme;

export function InfoCarousel() {
    const { token } = useToken();
    const isBigScreen = useIsBigScreen()

    // Move this somewhere?
    // Carousel uses https://react-slick.neostack.com/docs/api/
    const contentStyle: React.CSSProperties = {
      height: '250px',
      // color: '#fff',
      // lineHeight: '200px',
      textAlign: 'center',
      backgroundColor: token.colorBgElevated,
      padding: isBigScreen ? '50px 20px' : '40px 10px',
      margin: 0,
      // border: "1px solid black"
    };
    // <a href='/terms_and_conditions' target='blank'>Terms</a>
    return (
      <div
        style={{border: "1px solid black", marginTop: '6px'}}
      >
        <Carousel
          autoplay
          slidesToShow={isBigScreen ? 2 : 1}
          speed={1200} autoplaySpeed={6000}
          easing="ease-out"
        >
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Stock Insights
              </Typography.Title>
              <Typography.Paragraph>Insight notifications on price action, option analysis and stock news.<br/><Link to={`/signals`}>See Signals</Link></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Organize Trading Signals
              </Typography.Title>
              <Typography.Paragraph>Customizable Dashboards show Signals you care about.<br/><Link to={`/dashboards`}>See Dashboards</Link></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Premium Signals
              </Typography.Title>
              <Typography.Paragraph>Subscribe to Data Sets specific to your trading needs.<br/><Link to={`/store`}>See the Store</Link></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Trading View Integration
              </Typography.Title>
              <Typography.Paragraph>Load your Signals and indicators automatically from Trading View.<br/><a href={`/trading_view_webhook`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
            {/* <h3 style={contentStyle}>
              Load your signals directly from Trading View.
            </h3> */}
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Automated Trading
              </Typography.Title>
              <Typography.Paragraph>Use Signals in your automated trading bots, like FreqTrade.<br/><a href={`/freqtrade_crypto_bot`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
              Daily Digest Emails
              </Typography.Title>
              <Typography.Paragraph>Send signals directly to your email inbox.<br/><a href={`/email_bot`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Discord and Telegram Integration
              </Typography.Title>
              <Typography.Paragraph>Notify signals to your discord or telegram community with Workflows.<br/><a href={`/discord_bot`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                Sell Signals
              </Typography.Title>
              <Typography.Paragraph>Sell your signals. Data hosting and payments handled by us.<br/><a href={`/data_providing_1p_3p`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <Typography.Title level={3}>
                FreqSignals Community
              </Typography.Title>
              <Typography.Paragraph>Join the conversation. Catch trade setups and private signals.<br/><a href={`/community`} target='blank'>Learn More</a></Typography.Paragraph>
            </div>
          </div>
        </Carousel>

      </div>
      
    )
  }
